// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as LoadingIcon} from 'assets/svg/3.0/googleLoading.svg';
import {ReactComponent as GoogleIcon} from 'assets/svg/outline/Google.svg';
import {useAnalysis} from 'contexts/AnalysisContext';

import {Props} from './GoogleButton.types';

export function GoogleButton({
  googleLoading,
  googleLoginHandler,
  styles,
  buttonText,
}: Props) {
  const {recordEvent} = useAnalysis();

  return (
    <div
      className={styles.btnContainer}
      onClick={() => {
        googleLoginHandler();
        recordEvent('Google-Button-Click');
      }}
    >
      {googleLoading ? (
        <LoadingIcon className={styles.rotate} />
      ) : (
        <>
          <GoogleIcon className={styles.icon} />
          <p className={styles.text}>{buttonText}</p>
        </>
      )}
    </div>
  );
}
