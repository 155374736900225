// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useTranslation} from 'react-i18next';
import {Link, Navigate, useLocation} from 'react-router-dom';
import {LOGIN_PATH} from 'utils/path';

import styles from './AuthForm.module.scss';

export function CheckEmailForm() {
  const {state} = useLocation();
  const {t} = useTranslation('auth');

  if (!state || !state.email) {
    return <Navigate to={LOGIN_PATH} replace />;
  }

  return (
    <>
      <div className={styles.title}>{t('Please check your email')}</div>
      <div className={styles['content-container']}>
        <p className={styles['check-email-content']}>
          {t('A link to reset your password has been sent to email.', {
            email: state.email,
          })}
        </p>
        <p className={styles.to_login}>
          <span>{t('Already have your password?')}</span>
          <Link to={LOGIN_PATH} className={styles.link}>
            {t('Log in')}
          </Link>
        </p>
      </div>
    </>
  );
}
