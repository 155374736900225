// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import * as apiServer from 'api/server';
import {catchAsync} from 'lib/exception';
import {SessionStorageKey, useSessionStorage} from 'lib/hooks';
import {PlanType, ProductIdEnum} from 'modules/payment/types';
import {
  create as createPreference,
  Preference,
} from 'modules/preference/models/Preference';
import {usePreference} from 'modules/preference/services';
import {ProjectType} from 'modules/project/types';
import {ProjectCacheSessionStorageMap} from 'modules/project/utils';
import {
  createContext,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {useAPI} from './APIContext';
import {
  clearNotShowDialogAgain,
  clearPreProject,
  clearUserToken,
  getUserToken,
  setUserToken,
} from './localStorage';

export enum Badge {
  EDU = 'EDU',
}

export interface UserInfo {
  email: string;
  portrait: string;
  userName: string;
  userId: string;
  plan: PlanType; //逻辑plan
  credit: number;
  creditPackAmount: number;
  planCreditAmount: number;
  periodEnd: number | null;
  autoRenewal?: boolean;
  referralCode?: string;
  emailConfirmStatus?: boolean;
  profile?: {
    //纯展示
    plan: PlanType; //真实plan
    badges: Badge[];
  };
  lang: string;
}

interface UserContext {
  isLogin: boolean | undefined;
  userInfo: UserInfo;
  activeProductId: number;
  subscriptionProductId: number;
  isLoaded: boolean;
  isPaidUser: boolean;

  updateUserLogin: (
    email: UserInfo['email'],
    userName: UserInfo['userName'],
    userId: UserInfo['userId'],
    portrait: UserInfo['portrait'],
    _token: string,
    emailConfirmStatus: UserInfo['emailConfirmStatus']
  ) => void;
  updateUserInfo: (_newUserInfo: Partial<UserInfo>) => void;
  setSubscriptionProductId: (productId: ProductIdEnum) => void;
  setActiveProductId: (_productId: ProductIdEnum) => void;
  logout: () => Promise<void>;
  updateCredit: () => void;
  updateAccount: () => void;
  checkoutPreference: <T extends ProjectType>(type: T) => Preference<T>;
  updatePreference: <T extends ProjectType>(
    type: T,
    state: SetStateAction<Preference<T>>,
    delay?: number | boolean
  ) => void;
}

export const initialUserInfo: UserInfo = {
  userId: '',
  userName: '',
  portrait: '',
  email: '',
  plan: 'FREE',
  autoRenewal: false,
  periodEnd: null,
  credit: 0,
  planCreditAmount: 0,
  creditPackAmount: 0,
  lang: '',
};

const DEFAULT_PRODUCT_ID = Infinity;

/* eslint-disable @typescript-eslint/no-empty-function */
const userContext = createContext<UserContext>({
  isLogin: undefined,
  userInfo: initialUserInfo,
  activeProductId: DEFAULT_PRODUCT_ID,
  subscriptionProductId: DEFAULT_PRODUCT_ID,
  isLoaded: true,
  isPaidUser: false,

  updateUserLogin: async (
    _email: UserInfo['email'],
    _userName: UserInfo['userName'],
    _userId: UserInfo['userId'],
    _portrait: UserInfo['portrait'],
    _token: string,
    _emailConfirmStatus: UserInfo['emailConfirmStatus']
  ) => {},
  updateUserInfo: (_newUserInfo: Partial<UserInfo>) => {},
  setSubscriptionProductId: (_productId: ProductIdEnum) => {},
  setActiveProductId: (_productId: ProductIdEnum) => {},
  logout: async () => {},
  updateCredit: () => {},
  updateAccount: () => {},
  checkoutPreference: <T extends ProjectType>(type: T) =>
    createPreference(type),
  updatePreference: <T extends ProjectType>(
    _type: T,
    _state: SetStateAction<Preference<T>>,
    _delay?: number | boolean
  ) => {},
});
/* eslint-enable @typescript-eslint/no-empty-function */

export function useUserContext() {
  return useContext(userContext);
}

interface Props {
  children: ReactNode;
}

export function UserContextProvider({children}: Props) {
  const [userInfo, setUserInfo] = useSessionStorage(
    SessionStorageKey.UserInfo,
    initialUserInfo
  );
  const {backendClient} = useAPI();
  const [isLogin, setIsLogin] = useState<boolean | undefined>(undefined);
  const [subscriptionProductId, setSubscriptionProductId] =
    useState<ProductIdEnum>(DEFAULT_PRODUCT_ID);
  const [activeProductId, setActiveProductId] =
    useState<ProductIdEnum>(DEFAULT_PRODUCT_ID);

  const updateUserInfo = useCallback(
    (newUserInfo: Partial<UserInfo>) =>
      setUserInfo(userInfo => ({...userInfo, ...newUserInfo})),
    [setUserInfo]
  );

  const loadPreference = useMemo(
    () => backendClient.getUserPreference.bind(backendClient),
    [backendClient]
  );
  const savePreference = useMemo(
    () => backendClient.saveUserPreference.bind(backendClient),
    [backendClient]
  );

  const {checkoutPreference, updatePreference} = usePreference(
    userInfo.userId,
    loadPreference,
    savePreference
  );

  useEffect(() => {
    if (getUserToken()) {
      apiServer
        .getUserInfoByToken()
        .then(res => {
          updateUserInfo(res);
          setIsLogin(true);
        })
        .catch(() => {
          clearUserToken();
          setIsLogin(false);
        });
    } else {
      setIsLogin(false);
    }
  }, [updateUserInfo]);

  const updateCredit = useCallback(() => {
    apiServer
      .getAccountDetails()
      .then((userCreditData: apiServer.GetAccountDetailsResponseType) => {
        updateUserInfo({
          plan: userCreditData.planType,
          credit: userCreditData.creditWalletAmount,
          planCreditAmount: userCreditData.planCreditAmount,
          creditPackAmount: userCreditData.creditPackAmount,
        });
      });
  }, [updateUserInfo]);

  const updateAccount = useCallback(() => {
    updateCredit();
    //获取其他用户信息
    backendClient.getSubscriptionInfo().then(data => {
      updateUserInfo({
        profile: {
          plan: data.planType,
          badges: data.badges || [],
        },
      });
    });
  }, [backendClient, updateCredit, updateUserInfo]);

  useEffect(() => {
    if (isLogin) {
      updateAccount();
    }
  }, [isLogin, updateAccount]);

  const updateUserLogin = useCallback(
    (
      email: UserInfo['email'],
      userName: UserInfo['userName'],
      userId: UserInfo['userId'],
      portrait: UserInfo['portrait'],
      token: string,
      emailConfirmStatus: UserInfo['emailConfirmStatus']
    ) => {
      setUserInfo({
        ...initialUserInfo,
        email,
        userName,
        userId,
        portrait,
        emailConfirmStatus,
      });
      setUserToken(token);
      setIsLogin(true);
      apiServer.getUserInfoByToken().then(res => {
        updateUserInfo(res);
      });
    },
    [setUserInfo, updateUserInfo]
  );

  const logout = useCallback(async () => {
    await catchAsync(apiServer.logOut());
    setUserInfo({...initialUserInfo});
    clearUserToken();
    clearNotShowDialogAgain();
    Object.values(ProjectCacheSessionStorageMap).forEach(sessionStorageKey => {
      sessionStorage.removeItem(sessionStorageKey);
    });
    clearPreProject();
    setActiveProductId(DEFAULT_PRODUCT_ID);
    setIsLogin(false);
  }, [setUserInfo]);
  const contextValue = {
    isLogin,
    userInfo,
    subscriptionProductId,
    activeProductId,
    isLoaded: activeProductId !== DEFAULT_PRODUCT_ID,
    isPaidUser: userInfo.plan !== 'FREE',

    updateUserLogin,
    updateUserInfo,
    logout,
    setSubscriptionProductId,
    setActiveProductId,
    updateCredit,
    updateAccount,
    checkoutPreference,
    updatePreference,
  };

  return (
    <userContext.Provider value={contextValue}>{children}</userContext.Provider>
  );
}
