// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {$getRoot, ParagraphNode, TextNode} from 'lexical';
import {useEffect} from 'react';

export function AutoFixLinePlugin() {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    editor.update(() => {
      const root = $getRoot();
      const firstParagraph = root.getFirstChild() as ParagraphNode;
      const firstText = firstParagraph.getFirstChild();
      if (firstText instanceof TextNode && firstText.getTextContent() === '') {
        firstText.remove();
      }
    });
  }, [editor]);
  return null;
}
