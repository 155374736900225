// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useEffect} from 'react';

export function TikTokCallback() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    const error = params.get('error');
    window.postMessage({code, error, type: 'TikTok'}, window.location.origin);
    window.close();
  }, []);
  return null;
}
