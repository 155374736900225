// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as CropIcon} from 'assets/svg/outline/AssetstoolCrop.svg';
import {ReactComponent as DeleteIcon} from 'assets/svg/outline/AssetsToolDelete.svg';
import {ReactComponent as RemoveBgIcon} from 'assets/svg/outline/AssetsToolRemoveBg.svg';
import {ReactComponent as StyleTransferIcon} from 'assets/svg/outline/AssetsToolStyleTransfer.svg';
import {useEffect, useState} from 'react';
import {createPortal} from 'react-dom';

import styles from './ImageBar.module.scss';
import {ImageBarActionTypes, ImageBarProps} from './ImageBar.type';

const actions = [
  {action: ImageBarActionTypes.crop, icon: <CropIcon />},
  {action: ImageBarActionTypes.rmBg, icon: <RemoveBgIcon />},
  {action: ImageBarActionTypes.styleTransfer, icon: <StyleTransferIcon />},
  {action: ImageBarActionTypes.delete, icon: <DeleteIcon />},
];

export function ImageBar({onAction, triggerRef}: ImageBarProps) {
  const [position, setPosition] = useState({top: -9999, left: -9999});
  useEffect(() => {
    const updatePosition = () => {
      if (triggerRef.current) {
        const {top, left, width} = triggerRef.current.getBoundingClientRect();
        setPosition({top, left: left + width / 2});
      }
    };
    updatePosition();
    const resizeObserver = new ResizeObserver(updatePosition);
    const observer = new MutationObserver(updatePosition);
    if (triggerRef.current) {
      resizeObserver.observe(triggerRef.current);
      observer.observe(triggerRef.current, {
        attributes: true,
        attributeFilter: ['style'],
      });
    }
    return () => {
      resizeObserver.disconnect();
      observer.disconnect();
    };
  }, [triggerRef]);
  return createPortal(
    <div className={styles['image-bar']} style={position}>
      {actions.map(({action, icon}) => (
        <div
          key={action}
          className={styles['image-bar-item']}
          onPointerDown={e => {
            e.stopPropagation();
            onAction(action);
          }}
        >
          {icon}
        </div>
      ))}
    </div>,
    document.body
  );
}
