// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as AffiliateIcon} from 'assets/svg/3.0/Affiliate.svg';
import {ReactComponent as ChangePWIcon} from 'assets/svg/3.0/ChangePW.svg';
import {ReactComponent as CreditIcon} from 'assets/svg/3.0/CreditIcon.svg';
import {ReactComponent as EducationIcon} from 'assets/svg/3.0/Education.svg';
import {ReactComponent as FaqIcon} from 'assets/svg/3.0/Faq.svg';
import {ReactComponent as SignOutIcon} from 'assets/svg/3.0/SignOut.svg';
import {ReactComponent as SubscriptionIcon} from 'assets/svg/3.0/Subscription.svg';
import classNames from 'classnames';
import {MobileLanguageSelect} from 'components/LanguageSelect';
import {useUserContext} from 'contexts/UserContext';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {createFAQPath, PLAN_PAGE_PATH} from 'utils/path';

import styles from './Menu.module.scss';
import {Props} from './Menu.types';

export function Menu({onClose, onClickChangePW, className}: Props) {
  const navigate = useNavigate();
  const {logout, userInfo} = useUserContext();
  const {t, i18n} = useTranslation('workspace');

  const LIST = [
    {
      label: t('Subscription'),
      Icon: SubscriptionIcon,
      onClick: () => navigate(PLAN_PAGE_PATH),
    },
    {
      label: t('Buy credits'),
      Icon: CreditIcon,
      onClick: () => navigate(PLAN_PAGE_PATH),
    },
    {
      label: t('FAQ'),
      Icon: FaqIcon,
      onClick: () => window.open(createFAQPath(i18n.language)),
    },
    {
      label: t('Education program'),
      Icon: EducationIcon,
      onClick: () =>
        window.open(
          'https://docs.google.com/forms/d/1vK5EppKpxiTpkumoYmB_GuDtPrUgtgnuo2HB4ZUDyq8/edit'
        ),
    },
    {
      label: t('Affiliate program'),
      Icon: AffiliateIcon,
      onClick: () =>
        window.open(
          'https://docs.google.com/forms/d/e/1FAIpQLScMLAkooPnlK2BB0FSVPSWEkCQ2JYkXO8ITLdjDxOjULcCF0A/viewform?usp=sf_link'
        ),
    },
    ...(userInfo.emailConfirmStatus
      ? [
          {
            label: t('Change password'),
            Icon: ChangePWIcon,
            onClick: onClickChangePW,
          },
        ]
      : []),
    {
      label: t('Sign out'),
      Icon: SignOutIcon,
      onClick: logout,
    },
  ];

  return (
    <ul className={classNames(styles.container, className)}>
      <div className={styles.langs}>
        <MobileLanguageSelect />
      </div>
      {LIST.map(({label, Icon, onClick}) => (
        <li
          key={label}
          className={styles.item}
          onClick={e => {
            e.stopPropagation();
            onClick();
            onClose();
          }}
        >
          <Icon className={styles.icon} />
          <span className={styles.label}>{label}</span>
        </li>
      ))}
    </ul>
  );
}
