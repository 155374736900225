// Copyright 2025 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useGoogleLogin} from '@react-oauth/google';
import {ReactComponent as DropdownIcon} from 'assets/svg/3.0/Dropdown.svg';
import {ReactComponent as SignOutIcon} from 'assets/svg/3.0/SignOut.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {useAPI} from 'contexts/APIContext';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import styles from './YouTubeLogin.module.scss';
import {LoginProps} from './YouTubeLogin.type';

export function YouTubeLoginButton({
  userinfo,
  setUserinfo,
  disabled,
}: LoginProps) {
  const {t} = useTranslation('project');
  const {backendClient} = useAPI();

  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(false);

  const login = useGoogleLogin({
    async onSuccess(codeResponse) {
      setLoading(true);
      try {
        const {
          data: {data},
        } = await backendClient.signInYouTube(codeResponse.code);
        setUserinfo(data);
      } catch {
        console.error('Failed to sign in with YouTube');
      }
      setLoading(false);
    },
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/youtube.upload',
  });

  const getUserinfo = useCallback(async () => {
    setLoading(true);
    try {
      const {data} = await backendClient.getYouTubeUserinfo();
      setUserinfo(data);
    } catch {
      console.error('Failed to get YouTube userinfo');
    }
    setLoading(false);
  }, [backendClient, setUserinfo]);

  const signOut = useCallback(async () => {
    setLoading(true);
    try {
      await backendClient.signOutYouTube();
      setUserinfo(undefined);
    } catch {
      console.error('Failed to sign out from YouTube');
    }
    setLoading(false);
  }, [backendClient, setUserinfo]);

  useEffect(() => {
    if (!userinfo) getUserinfo();
  }, [getUserinfo, userinfo]);

  if (!userinfo && loading) {
    return (
      <div
        className={classNames(styles.select, {
          [styles.disabled]: disabled,
        })}
      ></div>
    );
  }

  if (!userinfo) {
    return (
      <div
        className={classNames(styles.signIn, disabled && styles.disabled)}
        onClick={() => !disabled && !loading && login()}
      >
        {t('Sign in')}
      </div>
    );
  }
  return (
    <Popover
      disabled={disabled || loading}
      visible={isActive}
      onVisibleChange={setIsActive}
      className={styles.popover}
      triggerElement={
        <div
          className={classNames(styles.select, {
            [styles.active]: isActive,
            [styles.disabled]: disabled || loading,
          })}
        >
          <div className={styles.user}>
            <img src={userinfo.avatar} alt="avatar" className={styles.avatar} />
            <div className={styles.name}>{userinfo.username}</div>
          </div>
          <DropdownIcon className={styles.icon} />
        </div>
      }
      popoverClassName={styles.popoverContent}
      popupMatchSelectWidth
      content={
        <>
          <div className={styles.user}>
            <img src={userinfo.avatar} alt="avatar" className={styles.avatar} />
            <div className={styles.name}>{userinfo.username}</div>
          </div>
          <div
            className={styles.signOut}
            onClick={() => {
              setIsActive(false);
              signOut();
            }}
          >
            <SignOutIcon className={styles.icon} />
            <div className={styles.text}>{t('Sign out')}</div>
          </div>
        </>
      }
      direction="bottom"
      distance={10}
    />
  );
}
