// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {PasswordErrorType} from 'modules/auth/types';
import {useTranslation} from 'react-i18next';

import {ErrorMessageProps} from './AuthForm.types';

export function PasswordErrorMessage({
  errorType,
  className,
}: ErrorMessageProps<PasswordErrorType>) {
  const {t} = useTranslation('auth');

  switch (errorType) {
    case 'PASSWORD_INVALID':
      return (
        <p key="PASSWORD_INVALID" className={className}>
          {t('Use password more than 8 characters.')}
        </p>
      );
    case 'PASSCODE_NOT_MATCH':
      return (
        <p key="PASSCODE_NOT_MATCH" className={className}>
          {t('Wrong username/password combination.')}
        </p>
      );
    case 'COMFIRM_PASSCODE_NOT_MATCH':
      return (
        <p key="COMFIRM_PASSCODE_NOT_MATCH" className={className}>
          {t('The password does not match.')}
        </p>
      );
    default:
      return null;
  }
}
