// Copyright 2025 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {GoogleOAuthProvider} from '@react-oauth/google';

import {LoginProps} from './YouTubeLogin.type';
import {YouTubeLoginButton} from './YouTubeLoginButton';

export function YouTubeLogin(props: LoginProps) {
  if (!process.env.REACT_APP_YOUTUBE_CLIENT_ID) return null;
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_YOUTUBE_CLIENT_ID}>
      <YouTubeLoginButton {...props} />
    </GoogleOAuthProvider>
  );
}
