// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as DeleteIcon} from 'assets/svg/3.0/Delete2.svg';
import {ReactComponent as Delete3Icon} from 'assets/svg/3.0/Delete3.svg';
import {ReactComponent as DuplicateIcon} from 'assets/svg/3.0/Duplicate.svg';
import {ReactComponent as HistoryIcon} from 'assets/svg/3.0/History.svg';
import {ReactComponent as MoreIcon} from 'assets/svg/3.0/More.svg';
import {ReactComponent as NoImageIcon} from 'assets/svg/3.0/NoImage.svg';
import {ReactComponent as RenameIcon} from 'assets/svg/3.0/Rename.svg';
import {ReactComponent as UseIcon} from 'assets/svg/3.0/Use.svg';
import classNames from 'classnames';
import {Popover as AriaPopover} from 'components/AriaPopover';
import {Tooltip} from 'components/Tooltip';
import {useResourceManager} from 'contexts/ResourceManager';
import {trimNewline} from 'modules/project/utils';
import {dateToUpdateTime} from 'pages/common/date';
import {Button} from 'pages/components/Button';
import {ClickOrDrag} from 'pages/components/ClickOrDrag';
import {List} from 'pages/components/List';
import {Toast} from 'pages/components/Toast';
import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {isRtl} from 'utils/is-rtl';

import {PageType} from '../ProjectOrGallery/ProjectOrGallery.types';
import {
  isGalleryItemType,
  isProjectItemType,
  ProjectOrGalleryItemType,
} from '../WorkspacePage.types';
import {UseHookReturnType} from './ProjectItem.container';
import styles from './ProjectItem.module.scss';
import {ProjectTitle} from './ProjectTitle';

type ProjectItemProps = {
  project: ProjectOrGalleryItemType;
  projectId?: number | string;
  pageType: PageType;
} & UseHookReturnType;
export function ProjectItem({
  project,
  projectId,
  deleteConfirmVisible,
  hideDeleteConfirmModal,
  handleClick,
  isRename,
  onConfirmDelete,
  onRename,
  morePopoverVisible,
  onMorePopoverVisibleChange,
}: ProjectItemProps) {
  const projectTitle = isProjectItemType(project)
    ? project.projectName
    : project.galleryName;
  const moreRef = useRef<HTMLDivElement>(null);
  const {getLanguages} = useResourceManager();
  const languages = getLanguages();

  const {t} = useTranslation('workspace');
  const {t: commonT} = useTranslation();

  const MoreJsx = (
    <AriaPopover
      placement="right"
      triggerClassName={styles.titleMorePopoverContainer}
      triggerElement={
        <div
          ref={moreRef}
          onClick={e => e.stopPropagation()}
          onMouseUp={e => e.stopPropagation()}
          onMouseDown={e => e.stopPropagation()}
        >
          <Button type="icon">
            <MoreIcon className={styles.more} />
          </Button>
        </div>
      }
      isOpen={morePopoverVisible}
      onOpenChange={onMorePopoverVisibleChange}
    >
      <List
        size="small"
        onMouseDown={(e: MouseEvent) => {
          e.stopPropagation();
        }}
        onMouseUp={(e: MouseEvent) => {
          e.stopPropagation();
        }}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {isProjectItemType(project) ? (
          <>
            <li data-action="duplicate" onClick={handleClick}>
              <DuplicateIcon />
              {t('Duplicate')}
            </li>
            {
              <li
                data-action="history"
                onClick={
                  !!project.historyNumber && project.historyNumber >= 1
                    ? handleClick
                    : undefined
                }
                className={
                  !(project.historyNumber && project.historyNumber >= 1)
                    ? 'disabled'
                    : ''
                }
              >
                <HistoryIcon />
                {t('History')}
              </li>
            }
            <li data-action="rename" onClick={handleClick}>
              <RenameIcon />
              {t('Rename')}
            </li>
            <li data-action="delete" onClick={handleClick}>
              <Delete3Icon /> {t('Delete')}
            </li>
          </>
        ) : (
          <li data-action="use" onClick={handleClick}>
            <UseIcon /> {t('Use')}
          </li>
        )}
      </List>
    </AriaPopover>
  );
  const projectTitleAndTime = (
    <div
      className={classNames(styles.title_wrap, {
        [styles.isRename]: isRename,
      })}
    >
      {projectTitle ? (
        <Tooltip
          disabled={isRename}
          tooltip={
            <div
              className={classNames(styles['project-title-popover'], {
                'rtl-element-global': isRtl(languages, project.lang),
              })}
            >
              {projectTitle}
            </div>
          }
          className={classNames(styles.title, {
            'rtl-element-global': isRtl(languages, project.lang),
          })}
        >
          <ProjectTitle
            isEdit={isRename}
            value={trimNewline(projectTitle, project.lang ?? 'en-US')}
            placeholderValue={trimNewline(
              projectTitle || commonT('Untitled'),
              project.lang ?? 'en-US'
            )}
            onSubmit={onRename}
          />
        </Tooltip>
      ) : (
        <span
          className={classNames(styles.title, {
            'rtl-element-global': isRtl(languages, project.lang),
          })}
        >
          <ProjectTitle
            isEdit={isRename}
            value={trimNewline(projectTitle, project.lang ?? 'en-US')}
            placeholderValue={trimNewline(
              projectTitle || commonT('Untitled'),
              project.lang ?? 'en-US'
            )}
            onSubmit={onRename}
          />
        </span>
      )}

      {isProjectItemType(project) ? MoreJsx : null}
    </div>
  );

  return (
    <div key={projectId} className={styles.projectContainer}>
      <ClickOrDrag
        className={classNames(styles.projectCard, {
          [styles.isGallery]: isGalleryItemType(project),
        })}
        data-action="click"
        onClick={ev => {
          handleClick && handleClick(ev);
        }}
      >
        <div>
          <div
            className={classNames(styles.cover, {
              [styles.noImage]: !project.thumbnailUrl,
            })}
            data-action="click"
          >
            {project.thumbnailUrl ? (
              <img
                src={project.thumbnailUrl}
                alt={projectTitle}
                className={styles.img}
              />
            ) : (
              <NoImageIcon className={styles.noImageSvg} />
            )}
          </div>
          <div className={styles.titleAndSubInfo}>
            {projectTitleAndTime}
            <div
              className={classNames(styles.prompt, {
                'rtl-element-global':
                  !isProjectItemType(project) && isRtl(languages, project.lang),
              })}
            >
              {isProjectItemType(project)
                ? project.updateTime &&
                  dateToUpdateTime(new Date(project.updateTime))
                : project.prompt &&
                  project.prompt.split('\\n').map((line, index, array) => (
                    <React.Fragment key={index}>
                      {line}
                      {index !== array.length - 1 && <br />}
                    </React.Fragment>
                  ))}
            </div>
          </div>
          {isGalleryItemType(project) ? MoreJsx : null}
          {isProjectItemType(project) &&
            (!project.historyNumber || project.historyNumber === 0) && (
              <div className={styles.badge}>{t('Draft')}</div>
            )}
        </div>
      </ClickOrDrag>
      <Toast
        title={
          <>
            <DeleteIcon />
            &nbsp; {t('Delete')}
          </>
        }
        visible={deleteConfirmVisible}
        onOk={onConfirmDelete}
        onCancel={hideDeleteConfirmModal}
        showCloseBtn={false}
        btnTheme="danger"
        cancelText={t('Cancel')}
        confirmText={t('Delete')}
      >
        {t('Confirm to delete?')}
      </Toast>
    </div>
  );
}
