import {useCallback, useMemo} from 'react';
import {useLocation} from 'react-router-dom';

export function useQueryString() {
  // 使用React Router的useLocation Hook获取当前location对象
  const {search} = useLocation();

  // 使用URLSearchParams API解析查询字符串
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  // 定义一个函数来获取指定查询参数的值
  const getQueryParam = useCallback(
    (param: string): string | null => {
      return queryParams.get(param);
    },
    [queryParams]
  );

  return getQueryParam;
}
