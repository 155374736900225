// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  Placement,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import classNames from 'classnames';
import {PropsWithChildren, ReactNode, useEffect, useState} from 'react';

import styles from './Tooltip.module.scss';

export function Tooltip({
  tooltip,
  placement = 'top',
  children,
  isControlByOverflow = false,
  className,
  tooltipClassName,
  disabled: isDisabled,
}: PropsWithChildren<{
  tooltip: ReactNode;
  placement?: Placement;
  isControlByOverflow?: boolean;
  className?: string;
  tooltipClassName?: string;
  disabled?: boolean;
}>) {
  const [isOpen, setIsOpen] = useState(false);
  const [disabled, setDisabled] = useState(isControlByOverflow || isDisabled);

  const {refs, floatingStyles, context} = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: 'start',
      }),
      shift(),
    ],
  });

  // Event listeners to change the open state
  const hover = useHover(context, {move: false});
  const focus = useFocus(context);
  const click = useClick(context);
  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, {role: 'tooltip'});

  // Merge all the interactions into prop getters
  const {getReferenceProps, getFloatingProps} = useInteractions([
    hover,
    focus,
    click,
    dismiss,
    role,
  ]);

  useEffect(() => {
    const isOverflowing = () => {
      const targetEl = (refs.reference.current as Element)?.firstElementChild;
      if (targetEl) {
        return (
          targetEl.scrollWidth - targetEl.clientWidth &&
          Math.abs(targetEl.scrollWidth - targetEl.clientWidth) > 1
        );
      }
      return false;
    };
    isOpen &&
      setDisabled((isControlByOverflow || isDisabled) && !isOverflowing());
  }, [isControlByOverflow, isDisabled, isOpen, refs.reference]);

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className={classNames(styles.trigger, className)}
      >
        {children}
      </div>
      <FloatingPortal>
        {!disabled && isOpen && (
          <div
            ref={refs.setFloating}
            style={{...floatingStyles, zIndex: 100000}}
            {...getFloatingProps()}
            className={classNames(styles.content, tooltipClassName)}
          >
            {tooltip}
          </div>
        )}
      </FloatingPortal>
    </>
  );
}
