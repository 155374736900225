// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useTranslation} from 'react-i18next';
import {Link, Navigate, useLocation} from 'react-router-dom';
import {LOGIN_PATH} from 'utils/path';

import styles from './AuthForm.module.scss';

export function ConfirmEmailForm() {
  const {state} = useLocation();
  const {t} = useTranslation('auth');

  if (!state || !state.email) {
    return <Navigate to={LOGIN_PATH} replace />;
  }

  return (
    <>
      <div className={styles.title}>{t('Confirm your email')}</div>
      <div className={styles['content-container']}>
        <div className={styles['confirm-email']}>
          {t(
            'To continue signing up, click the link that we emailed to email.',
            {email: state.email}
          )}
        </div>
      </div>
      <span className={styles['content-container']}>
        <span className={styles['confirm-info']}>
          <span>{t('Already confirmed?')}</span>
          <Link
            to={LOGIN_PATH + '?email=' + state.email}
            className={styles.link}
          >
            {t('Log in')}
          </Link>
        </span>
      </span>
    </>
  );
}
