// Copyright 2025 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {staticCombiner} from 'api/frontend';
import {uploadVideo} from 'api/googleApi';
import {ReactComponent as CopyIcon} from 'assets/svg/3.0/Copy.svg';
import {ReactComponent as DropdownIcon} from 'assets/svg/3.0/Dropdown.svg';
import {ReactComponent as PreviewIcon} from 'assets/svg/3.0/Preview.svg';
import {ReactComponent as PublishIcon} from 'assets/svg/3.0/Publish.svg';
// import {ReactComponent as UploadIcon} from 'assets/svg/3.0/upload.svg';
import {ReactComponent as WarningIcon} from 'assets/svg/3.0/Warning.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {PreviewBox} from 'components/PreviewBox';
import {useNotificationContext} from 'contexts/NotificationContext';
import {copy} from 'lib/copy';
import {Button} from 'pages/components/Button';
import {useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Userinfo, YouTubeLogin} from './YouTubeLogin';
import styles from './YouTubeShare.module.scss';
import {YouTubeShareProps, YouTubeShareStatus} from './YouTubeShare.type';

export function YouTubeShare({
  title: _title,
  description: _description,
  tags = [],
  video,
  ratio,
  thumbnail: _thumbnail,
  isRTL,
  closeDialog,
}: YouTubeShareProps) {
  const {t} = useTranslation('project');
  const VISIBILITY_OPTIONS = [
    {
      value: YouTubeShareStatus.public,
      label: t('Public'),
      content: t('Everyone can watch your video.'),
    },
    {
      value: YouTubeShareStatus.private,
      label: t('Private'),
      content: t('Only you and people you choose can watch your video.'),
    },
    {
      value: YouTubeShareStatus.unlisted,
      label: t('Unlisted'),
      content: t('Everyone with the video link can watch your video.'),
    },
  ];

  const [userinfo, setUserinfo] = useState<Userinfo>();
  const [title, setTitle] = useState(_title);
  const [description, setDescription] = useState(_description);
  const [thumbnail, setThumbnail] = useState(staticCombiner(_thumbnail));
  const [file, setFile] = useState<File>();
  const [tagStr, setTagStr] = useState(tags.map(tag => `#${tag}`).join(' '));
  const [visibility, setVisibility] = useState(YouTubeShareStatus.public);
  const [visibilityFocused, setVisibilityFocused] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [videoId, setVideoId] = useState<string>();

  const inputRef = useRef<HTMLInputElement>(null);

  const selectedVisibility =
    VISIBILITY_OPTIONS.find(({value}) => value === visibility) ??
    VISIBILITY_OPTIONS[0];
  const youTubeLink =
    ratio === '9:16'
      ? `https://www.youtube.com/shorts/${videoId}`
      : `https://www.youtube.com/watch?v=${videoId}`;

  const {showNotification} = useNotificationContext();

  const publish = async () => {
    if (!title || !userinfo) return;
    setPublishing(true);

    try {
      const videoBlob = await fetch(staticCombiner(video)).then(res =>
        res.blob()
      );
      const {
        data: {id},
      } = await uploadVideo(userinfo.accessToken, videoBlob, {
        snippet: {
          title,
          description,
          tags: tagStr
            .split('#')
            .map(tag => tag.trim())
            .filter(Boolean),
        },
        status: {
          privacyStatus: visibility,
        },
      });

      // try {
      //   const thumbnailBlob = await fetch(thumbnail).then(res => res.blob());
      //   await uploadThumbnail(userinfo.accessToken, id, thumbnailBlob);
      // } catch {
      //   console.error('Failed to upload thumbnail');
      // }

      setVideoId(id);

      showNotification({
        type: 'SUCCESS',
        message: 'Share to Youtube successful',
      });
    } catch {
      showNotification({type: 'ERROR', message: 'Share to Youtube failed'});
    }

    setPublishing(false);
  };

  const redirectToYouTube = () => {
    window.open(youTubeLink, '_blank');
  };

  const copyYouTubeLink = () => {
    copy(youTubeLink);
    showNotification({type: 'SUCCESS', message: 'Copied'});
  };

  const renderLeft = () => {
    if (!videoId) {
      return (
        <div className={styles.left}>
          <div className={styles.box}>
            <div className={styles.label}>{t('YouTube channel')}</div>
            <YouTubeLogin
              disabled={publishing}
              userinfo={userinfo}
              setUserinfo={setUserinfo}
            />
          </div>
          <div className={styles.box}>
            <div className={classNames(styles.label, styles.required)}>
              {t('Title')}
            </div>
            <textarea
              disabled={publishing}
              className={classNames(styles.textarea, {
                'rtl-element-global': isRTL,
              })}
              placeholder={t('Add title')}
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
            {!title && (
              <div className={styles.warning}>
                <WarningIcon className={styles.icon} />
                <div className={styles.text}>
                  {t('Title is required to publish')}
                </div>
              </div>
            )}
          </div>
          <div className={styles.box}>
            <div className={styles.label}>{t('Description')}</div>
            <textarea
              disabled={publishing}
              className={classNames(styles.textarea, {
                'rtl-element-global': isRTL,
              })}
              placeholder={t('Add description')}
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </div>
          {/* <div className={styles.coverBox}>
            <div className={styles.box}>
              <div className={styles.label}>{t('Video cover')}</div>
              <div className={styles.secondary}>
                {t('Select an image to use as the cover')}
              </div>
              <Button
                disabled={publishing}
                className={styles.uploadButton}
                theme="primary"
                type="text"
                size="small"
                icon={<UploadIcon className={styles.uploadIcon} />}
                onClick={() => inputRef.current?.click()}
              >
                {t('Upload')}
              </Button>
              <input
                disabled={publishing}
                className={styles.hidden}
                ref={inputRef}
                type="file"
                onChange={e => setFile(e.target.files?.[0])}
              />
            </div>
            <div
              className={classNames(styles.image, styles.disabled)}
              onClick={() => inputRef.current?.click()}
            >
              <img src={thumbnail} />
              {!publishing && (
                <div className={styles.icon}>
                  <UploadIcon />
                </div>
              )}
            </div>
          </div> */}
          <div className={styles.box}>
            <div className={styles.label}>{t('Visibility')}</div>
            <Popover
              disabled={publishing}
              direction="bottom"
              distance={10}
              className={styles.popoverContainer}
              visible={visibilityFocused}
              onVisibleChange={setVisibilityFocused}
              popupMatchSelectWidth
              triggerElement={
                <div
                  className={classNames(styles.select, {
                    [styles.focused]: visibilityFocused,
                    [styles.disabled]: publishing,
                  })}
                >
                  <div className={styles.text}>
                    {selectedVisibility.label} : {selectedVisibility.content}
                  </div>
                  <DropdownIcon className={styles.icon} />
                </div>
              }
              popoverClassName={styles.selectPopover}
              content={
                <>
                  {VISIBILITY_OPTIONS.map(({value, label, content}) => (
                    <div
                      key={value}
                      className={styles.option}
                      onClick={() => {
                        setVisibilityFocused(false);
                        setVisibility(value);
                      }}
                    >
                      <div className={styles.label}>{label}</div>
                      <div className={styles.content}>{content}</div>
                    </div>
                  ))}
                </>
              }
            />
          </div>
          <div className={styles.box}>
            <div className={styles.label}>{t('Tags')}</div>
            <textarea
              disabled={publishing}
              className={classNames(styles.textarea, {
                'rtl-element-global': isRTL,
              })}
              placeholder={t('Add Tags')}
              value={tagStr}
              onChange={e => setTagStr(e.target.value)}
            />
          </div>
          <div className={styles.secondary}>
            {t(
              "By continuing, you confirm that the video you share to YouTube complies with YouTube's Terms of Service and does not infringe upon other people's intellectual property rights and privacy. Check our Google API Services Disclosure."
            )}
          </div>
        </div>
      );
    }
    return (
      <div className={classNames(styles.left, styles.shared)}>
        <div className={styles.box}>
          <div className={styles.label}>{t('Video uploaded')}</div>
        </div>
        <Button
          className={styles.button}
          theme="primary"
          type="ghost"
          onClick={redirectToYouTube}
          icon={<PreviewIcon className={styles.icon} />}
        >
          {t('Watch on Youtube')}
        </Button>
        <Button
          className={styles.button}
          theme="primary"
          type="ghost"
          onClick={copyYouTubeLink}
          icon={<CopyIcon className={styles.icon} />}
        >
          {t('Copy link')}
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (file) setThumbnail(URL.createObjectURL(file));
  }, [file]);
  return (
    <div className={styles.container}>
      <div className={styles.header}>{t('Share video to YouTube')}</div>
      <div className={styles.content}>
        {renderLeft()}
        <div className={styles.right}>
          <PreviewBox
            preview={video}
            ratio={ratio}
            type="Video"
            loading={false}
          />
        </div>
      </div>
      <div className={styles.footer}>
        {!videoId ? (
          <Button
            className={styles.button}
            icon={<PublishIcon className={styles.icon} />}
            onClick={publish}
            isLoading={publishing}
            disabled={!title || !userinfo || publishing}
          >
            {t('Publish')}
          </Button>
        ) : (
          <Button onClick={closeDialog} className={styles.button}>
            {t('Done')}
          </Button>
        )}
      </div>
    </div>
  );
}
