// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as LanguageIcon} from 'assets/svg/3.0/Language.svg';
import classNames from 'classnames';
import {useAPI} from 'contexts/APIContext';
import {setLanguage} from 'contexts/localStorage';
import {SUPPORTED_LANGUAGES} from 'i18n';
import {useCallback, useState} from 'react';
import {usePress} from 'react-aria';
import {useTranslation} from 'react-i18next';

import styles from './LanguageSelect.module.scss';

export function MobileLanguageSelect() {
  const {t, i18n} = useTranslation();
  const [visible, setVisible] = useState(false);

  const {backendClient} = useAPI();

  const selectLanguage = useCallback(
    (languageCode: string) => {
      console.log('languageCode', languageCode);
      backendClient.updateLanguage(languageCode);
      setLanguage(languageCode);
      i18n.changeLanguage(languageCode);
      setVisible(false);
    },
    [backendClient, i18n]
  );

  const {pressProps} = usePress({
    onPress: e => selectLanguage(e.target.id),
  });

  const content = (
    <div className={styles.select}>
      {SUPPORTED_LANGUAGES.map(({code, name}) => (
        <div
          {...pressProps}
          key={code}
          id={code}
          className={styles['select-item']}
        >
          {name}
        </div>
      ))}
    </div>
  );

  return (
    <div className={styles.container}>
      <div
        className={classNames(styles.language, {
          [styles.active]: visible,
        })}
        onClick={() => setVisible(!visible)}
      >
        <LanguageIcon className={styles.icon} />
        <span className={styles.label}>{t('Language')}</span>
      </div>
      {visible && content}
    </div>
  );
}
