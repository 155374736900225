// Copyright 2025 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {EditorConfig, LexicalNode, NodeKey, ParagraphNode} from 'lexical';

export class ErrorParagraphNode extends ParagraphNode {
  static getType() {
    return 'error-paragraph';
  }

  static clone(_node: ErrorParagraphNode) {
    return new ErrorParagraphNode();
  }

  constructor(key?: NodeKey) {
    super(key);
  }

  createDOM(config: EditorConfig) {
    const dom = document.createElement('p');
    const theme = config.theme;
    dom.classList.add(theme.errorParagraphNode.base);
    return dom;
  }

  updateDOM() {
    return false;
  }

  static importJSON(): ErrorParagraphNode {
    const node = $createErrorParagraph();
    return node;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      type: ErrorParagraphNode.getType(),
      version: 1,
    };
  }
}

export function $createErrorParagraph(): ErrorParagraphNode {
  return new ErrorParagraphNode();
}

export function $isErrorParagraphNode(
  node: LexicalNode | null | undefined
): node is ErrorParagraphNode {
  return node instanceof ErrorParagraphNode;
}
