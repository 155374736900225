// Copyright 2025 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {CloseButtonType, withDialog} from 'pages/components/Dialog';

import {YouTubeShare} from './YouTubeShare';
export type {YouTubeShareMetadata} from './YouTubeShare.type';

export const YouTubeShareDialog = withDialog({
  closeButtonType: CloseButtonType.Inside,
})(YouTubeShare);
