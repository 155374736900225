// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {useImage} from 'modules/user-asset/services';
import {
  MouseEvent as RectMouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {useKeyDown} from 'utils/use-key-down';

import {ImageLoading} from '../../ImageLoading';
import {ImageBar} from '../ImageBar';
import {ImageBarActionTypes} from '../ImageBar/ImageBar.type';
import styles from './ObjectEditor.module.scss';
import {ObjectEditorProps} from './ObjectEditor.type';

const MIN_SIZE = 40;
const DOCKING_SIZE = 8;

export function ObjectEditor({
  object,
  active,
  onClick,
  onClickOutSide,
  onAction: _onAction,
  updateObject,
  getImageWithObjectKey,
  refreshImage,
  outerWidth,
  outerHeight,
  disabled,
  forceLoading,
  outerEl,
  setIsXLineShowing,
  setIsYLineShowing,
}: ObjectEditorProps) {
  const root = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLDivElement>(null);
  const [hoverIng, setHoverIng] = useState(false);

  const {src, loading, onError} = useImage({
    objectKey: object.asset,
    getImageWithObjectKey,
    refreshImage,
  });
  const [position, setPosition] = useState({x: 0, y: 0});

  const onAction = (type: ImageBarActionTypes) => {
    if (type === ImageBarActionTypes.delete) return updateObject(null, false);
    _onAction(type, object);
  };

  const keys = useMemo(() => ['Backspace', 'Delete'], []);
  const keyDownHandler = useCallback(() => {
    updateObject(null, false);
  }, [updateObject]);
  useKeyDown(keys, active && !disabled ? keyDownHandler : undefined);

  const isMouseEvent = (e: MouseEvent | TouchEvent): e is MouseEvent =>
    e.type === 'mousemove' || e.type === 'mouseup';

  const [dragging, setDragging] = useState(false);

  const startDrag = (e: RectMouseEvent) => {
    if (e.button !== 0) return;
    onClick();
    const startX = e.clientX;
    const startY = e.clientY;
    const availableTop = (-object.y - object.height) * outerHeight + MIN_SIZE;
    const availableLeft = (-object.x - object.width) * outerWidth + MIN_SIZE;
    const availableRight = outerWidth - object.x * outerWidth - MIN_SIZE;
    const availableBottom = outerHeight - object.y * outerHeight - MIN_SIZE;

    const getAvailablePosition = (x: number, y: number) => {
      if (x < availableLeft) {
        x = availableLeft;
      }
      if (
        x >= -object.x * outerWidth - DOCKING_SIZE &&
        x <= -object.x * outerWidth + DOCKING_SIZE
      ) {
        x = -object.x * outerWidth;
      }
      if (y < availableTop) {
        y = availableTop;
      }
      if (
        y >= -object.y * outerHeight - DOCKING_SIZE &&
        y <= -object.y * outerHeight + DOCKING_SIZE
      ) {
        y = -object.y * outerHeight;
      }
      if (x > availableRight) {
        x = availableRight;
      }
      if (
        x >=
          outerWidth - (object.x + object.width) * outerWidth - DOCKING_SIZE &&
        x <= outerWidth - (object.x + object.width) * outerWidth + DOCKING_SIZE
      ) {
        x = outerWidth - (object.x + object.width) * outerWidth;
      }
      if (y > availableBottom) {
        y = availableBottom;
      }
      if (
        y >=
          outerHeight -
            (object.y + object.height) * outerHeight -
            DOCKING_SIZE &&
        y <=
          outerHeight - (object.y + object.height) * outerHeight + DOCKING_SIZE
      ) {
        y = outerHeight - (object.y + object.height) * outerHeight;
      }
      if (
        x + object.x * outerWidth + (object.width / 2) * outerWidth >=
          outerWidth / 2 - DOCKING_SIZE &&
        x + object.x * outerWidth + (object.width / 2) * outerWidth <=
          outerWidth / 2 + DOCKING_SIZE
      ) {
        x =
          outerWidth / 2 -
          object.x * outerWidth -
          (object.width / 2) * outerWidth;
        setIsXLineShowing(true);
      } else {
        setIsXLineShowing(false);
      }
      if (
        y + object.y * outerHeight + (object.height / 2) * outerHeight >=
          outerHeight / 2 - DOCKING_SIZE &&
        y + object.y * outerHeight + (object.height / 2) * outerHeight <=
          outerHeight / 2 + DOCKING_SIZE
      ) {
        y =
          outerHeight / 2 -
          object.y * outerHeight -
          (object.height / 2) * outerHeight;
        setIsYLineShowing(true);
      } else {
        setIsYLineShowing(false);
      }
      return {x, y};
    };

    const onDrag = (e: MouseEvent | TouchEvent) => {
      let changedSize = {x: 0, y: 0};
      if (isMouseEvent(e)) {
        changedSize = getAvailablePosition(
          e.clientX - startX,
          e.clientY - startY
        );
      } else {
        e.preventDefault();
        const touch = e.touches[0];
        changedSize = getAvailablePosition(
          touch.clientX - startX,
          touch.clientY - startY
        );
      }
      if (Math.abs(changedSize.x) > 1 || Math.abs(changedSize.y) > 1) {
        setDragging(true);
        setPosition(changedSize);
      } else {
        setPosition({x: 0, y: 0});
      }
    };
    const endDrag = (e: MouseEvent | TouchEvent) => {
      document.removeEventListener('mousemove', onDrag);
      document.removeEventListener('touchmove', onDrag);
      document.removeEventListener('mouseup', endDrag);
      document.removeEventListener('touchend', endDrag);

      setDragging(false);

      const target = isMouseEvent(e) ? e : e.changedTouches[0];

      const obj = getAvailablePosition(
        target.clientX - startX,
        target.clientY - startY
      );
      setPosition({x: 0, y: 0});

      if (Math.abs(obj.x) > 1 || Math.abs(obj.y) > 1) {
        updateObject(
          object.patch({
            x: object.x + obj.x / outerWidth,
            y: object.y + obj.y / outerHeight,
          })
        );
      }

      setIsXLineShowing(false);
      setIsYLineShowing(false);
    };
    document.addEventListener('mousemove', onDrag);
    document.addEventListener('touchmove', onDrag, {passive: false});
    document.addEventListener('mouseup', endDrag);
    document.addEventListener('touchend', endDrag);
  };

  const [resizing, setResizing] = useState<'tl' | 'tr' | 'bl' | 'br' | false>(
    false
  );

  const startResize = (
    e: RectMouseEvent,
    handlerName: 'tl' | 'tr' | 'bl' | 'br'
  ) => {
    onClick();
    setResizing(handlerName);
    e.stopPropagation();

    let startX = e.clientX;
    let startY = e.clientY;
    let width = object.width * outerWidth;
    let height = object.height * outerHeight;
    let top = object.y * outerHeight;
    let left = object.x * outerWidth;
    const ratio = width / height;

    const onResize = (e: MouseEvent | TouchEvent) => {
      e.preventDefault();
      const deltaX =
        (isMouseEvent(e) ? e.clientX : e.touches[0].clientX) - startX;
      const deltaY =
        (isMouseEvent(e) ? e.clientY : e.touches[0].clientY) - startY;
      let newHeight = height;
      let newWidth = width;
      let newLeft = left;
      let newTop = top;

      const isXChanged = Math.abs(deltaX) > Math.abs(deltaY) * ratio;

      switch (handlerName) {
        case 'tl':
          if (isXChanged) {
            newWidth = width - deltaX;
            newHeight = newWidth / ratio;
          } else {
            newHeight = height - deltaY;
            newWidth = newHeight * ratio;
          }
          newTop = top + height - newHeight;
          newLeft = left + width - newWidth;
          break;
        case 'tr':
          if (isXChanged) {
            newWidth = width + deltaX;
            newHeight = newWidth / ratio;
          } else {
            newHeight = height - deltaY;
            newWidth = newHeight * ratio;
          }
          newTop = top + height - newHeight;
          break;
        case 'bl':
          if (isXChanged) {
            newWidth = width - deltaX;
            newHeight = newWidth / ratio;
          } else {
            newHeight = height + deltaY;
            newWidth = newHeight * ratio;
          }
          newLeft = left + width - newWidth;
          break;
        case 'br':
          if (isXChanged) {
            newWidth = width + deltaX;
            newHeight = newWidth / ratio;
          } else {
            newHeight = height + deltaY;
            newWidth = newHeight * ratio;
          }
          break;
        default:
          break;
      }

      if (newWidth < MIN_SIZE || newHeight < MIN_SIZE) return;

      updateObject(
        object.patch({
          x: newLeft / outerWidth,
          y: newTop / outerHeight,
          width: newWidth / outerWidth,
          height: newHeight / outerHeight,
        }),
        true
      );
      startX = isMouseEvent(e) ? e.clientX : e.touches[0].clientX;
      startY = isMouseEvent(e) ? e.clientY : e.touches[0].clientY;
      left = newLeft;
      top = newTop;
      width = newWidth;
      height = newHeight;
    };
    const endResize = () => {
      setResizing(false);
      document.removeEventListener('mousemove', onResize);
      document.removeEventListener('touchmove', onResize);
      document.removeEventListener('mouseup', endResize);
      document.removeEventListener('touchend', endResize);
    };
    document.addEventListener('mousemove', onResize);
    document.addEventListener('touchmove', onResize);
    document.addEventListener('mouseup', endResize);
    document.addEventListener('touchend', endResize);
  };

  useEffect(() => {
    if (!active || disabled) return;

    const handleClickOutside = (e: PointerEvent) => {
      if (
        root.current?.contains(e.target as Node) ||
        imgRef.current?.contains(e.target as Node)
      )
        return;
      onClickOutSide();
    };

    document.addEventListener('pointerdown', handleClickOutside);
    return () => {
      document.removeEventListener('pointerdown', handleClickOutside);
    };
  }, [active, disabled, onClickOutSide]);

  const number2Percent = (num: number) => `${num * 100}%`;
  return (
    <>
      {(resizing || dragging) && (
        <div
          className={classNames(
            styles['object-editor-mask'],
            resizing ? styles[resizing] : undefined,
            styles.dragging
          )}
        ></div>
      )}

      <div
        className={classNames(styles['object-editor'])}
        ref={root}
        style={{
          position: 'absolute',
          top: number2Percent(object.y),
          left: number2Percent(object.x),
          width: number2Percent(object.width),
          height: number2Percent(object.height),
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
      >
        <div
          className={classNames(styles.imageResizer, {
            [styles.active]: active || hoverIng,
            [styles.disabled]: disabled,
          })}
          style={{
            width: object.width * outerWidth,
            height: object.height * outerHeight,
            cursor: disabled ? 'default' : undefined,
          }}
          onPointerEnter={() => !disabled && setHoverIng(true)}
          onPointerLeave={() => !disabled && setHoverIng(false)}
        >
          {(loading || forceLoading) && <ImageLoading />}
          <span
            className={classNames(styles.handler, styles.tl, {
              [styles.hide]: dragging || resizing,
            })}
            onPointerDown={e => !disabled && startResize(e, 'tl')}
          ></span>
          <span
            className={classNames(styles.handler, styles.tr, {
              [styles.hide]: dragging || resizing,
            })}
            onPointerDown={e => !disabled && startResize(e, 'tr')}
          ></span>
          <span
            className={classNames(styles.handler, styles.bl, {
              [styles.hide]: dragging || resizing,
            })}
            onPointerDown={e => !disabled && startResize(e, 'bl')}
          ></span>
          <span
            className={classNames(styles.handler, styles.br, {
              [styles.hide]: dragging || resizing,
            })}
            onPointerDown={e => !disabled && startResize(e, 'br')}
          ></span>
        </div>
      </div>

      {!disabled &&
        active &&
        !loading &&
        !forceLoading &&
        !dragging &&
        !resizing && <ImageBar triggerRef={root} onAction={onAction} />}

      {src && (
        <div
          ref={imgRef}
          style={{
            position: 'absolute',
            top: number2Percent(object.y),
            left: number2Percent(object.x),
            width: number2Percent(object.width),
            height: number2Percent(object.height),
            transform: `translate(${position.x}px, ${position.y}px)`,
            userSelect: 'none',
            touchAction: 'none',
            cursor: disabled ? undefined : 'move',
          }}
          onPointerDown={!disabled ? startDrag : undefined}
          onContextMenu={e => e.preventDefault()}
          onPointerEnter={() => !disabled && setHoverIng(true)}
          onPointerLeave={() => !disabled && setHoverIng(false)}
        >
          <img
            crossOrigin="anonymous"
            src={src}
            onError={onError}
            style={{width: '100%', height: '100%'}}
          />
        </div>
      )}
    </>
  );
}
