// Copyright 2025 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $getSelection,
  $isParagraphNode,
  $isRangeSelection,
  COMMAND_PRIORITY_LOW,
  EditorState,
  INSERT_LINE_BREAK_COMMAND,
  INSERT_PARAGRAPH_COMMAND,
  LexicalEditor,
  PASTE_COMMAND,
  PasteCommandType,
} from 'lexical';
import {useCallback, useEffect, useRef} from 'react';

function getEditorValueAndParagraphsInfo(editorState: EditorState): {
  value: string;
  paragraphNumber: number;
  paragraphsCharacterNumber: number[];
} {
  let value = '';
  let paragraphNumber = 0,
    paragraphsCharacterNumber: number[] = [];
  editorState.read(() => {
    const root = $getRoot();
    const paragraphs = root.getChildren().filter($isParagraphNode);
    value = paragraphs.map(p => p.getTextContent()).join('\n');
    const newParagraphs = paragraphs.filter(p => p.getTextContentSize() > 0);
    paragraphNumber = newParagraphs.length;
    paragraphsCharacterNumber = newParagraphs.map(paragraph =>
      paragraph.getTextContentSize()
    );
  });
  return {
    value,
    paragraphNumber,
    paragraphsCharacterNumber,
  };
}

function setEditorValue(
  editor: LexicalEditor,
  value: string,
  isAppend = false
): void {
  editor.update(() => {
    const root = $getRoot();
    !isAppend && root.clear();
    value.split('\n').forEach(text => {
      const p = $createParagraphNode();
      if (text) {
        p.append($createTextNode(text));
      }
      root.append(p);
    });
    root.selectEnd();
  });
}

export function PromptPlugin({
  value,
  onPromptChange,
}: {
  value: string;
  onPromptChange: (promptInfo: {
    value: string;
    paragraphNumber: number;
    paragraphsCharacterNumber: number[];
  }) => void;
}) {
  const currentRef = useRef<string>();
  const [editor] = useLexicalComposerContext();

  const onChange = useCallback(
    (editorState: EditorState) => {
      const {value, paragraphNumber, paragraphsCharacterNumber} =
        getEditorValueAndParagraphsInfo(editorState);
      currentRef.current = value;

      onPromptChange({
        value,
        paragraphNumber,
        paragraphsCharacterNumber,
      });
    },
    [onPromptChange]
  );

  const switchLineBreakToParagraph = useCallback(() => {
    editor.dispatchCommand(INSERT_PARAGRAPH_COMMAND, undefined);
    return true;
  }, [editor]);

  const renderPasteText = useCallback(
    (event: PasteCommandType) => {
      const clipboardData = (
        event as PasteCommandType & {
          clipboardData?: {getData: (type: string) => string};
        }
      ).clipboardData;
      const text = clipboardData?.getData('text/plain');

      if (text) {
        event.preventDefault();
        event.stopPropagation();
        const lines = text.split(/\r?\n/);
        editor.update(() => {
          const selection = $getSelection();
          if (!$isRangeSelection(selection)) return;
          lines.forEach((line, index) => {
            if (index > 0) {
              const newParagraph = $createParagraphNode();
              selection.insertNodes([newParagraph]);
              selection.focus.set(newParagraph.getKey(), 0, 'element');
            }
            selection.insertText(line);
          });
        });
        return true;
      }

      return false;
    },
    [editor]
  );

  useEffect(() => {
    // 将用户输入的软回车变成强制换行
    editor.registerCommand(
      INSERT_LINE_BREAK_COMMAND,
      switchLineBreakToParagraph,
      COMMAND_PRIORITY_LOW
    );
    editor.registerCommand(
      PASTE_COMMAND,
      renderPasteText,
      COMMAND_PRIORITY_LOW
    );

    if (currentRef.current === undefined || currentRef.current !== value) {
      setEditorValue(editor, value);
    }
  }, [editor, renderPasteText, switchLineBreakToParagraph, value]);

  return (
    <OnChangePlugin
      ignoreHistoryMergeTagChange
      ignoreSelectionChange
      onChange={onChange}
    />
  );
}
