// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

/* eslint-disable @typescript-eslint/no-non-null-assertion */

import {HolidayGreetingProjectConfig} from 'modules/project-config/models/HolidayGreetingProjectConfig';

import {HolidayGreetingComposition} from '../../composition/models/HolidayGreetingComposition';
import {HolidayGreetingConstraint} from '../../constraint/models/HolidayGreetingConstraint';
import {HolidayGreetingStoryboard} from '../../storyboard/models/HolidayGreetingStoryboard';
import {Holiday, HolidayGreetingProjectJSON, PromptType, Size} from '../types';
import {checkoutPromptType, mergePrompt, parsePrompt} from '../utils';
import {BaseProject} from './BaseProject';
import {fromJSON as fromJSONPromptPolicy, PromptPolicy} from './PromptPolicy';

export class HolidayGreetingProject extends BaseProject<'holiday_greeting'> {
  static fromJSON({
    id,
    version,
    author_id,
    constraint,
    size,
    language,
    prompt_policy,
    prompt,
    holiday,
    images,
    figure_style,
    storyboard,
    composition,
    config,
    histories,
  }: HolidayGreetingProjectJSON): HolidayGreetingProject {
    const promptPolicy = fromJSONPromptPolicy(
      'holiday_greeting',
      prompt_policy
    );
    const promptType = checkoutPromptType(promptPolicy);
    const _prompt = parsePrompt(promptType, prompt);
    const _storyboard = storyboard
      ? HolidayGreetingStoryboard.fromJSON(
          size,
          language,
          promptPolicy,
          _prompt[promptType],
          holiday,
          images,
          figure_style,
          storyboard
        )
      : undefined;
    if (composition && !_storyboard) {
      throw new Error('Composition is not allowed without storyboard');
    }
    const _composition =
      composition && _storyboard
        ? HolidayGreetingComposition.fromJSON(
            _storyboard.size,
            _storyboard.language,
            _storyboard.promptPolicy,
            _storyboard.prompt,
            _storyboard.title!,
            _storyboard.description!,
            _storyboard.hashtags!,
            _storyboard.holiday,
            _storyboard.figureStyle,
            composition
          )
        : undefined;
    return new HolidayGreetingProject(
      id,
      version,
      author_id,
      HolidayGreetingConstraint.fromJSON(constraint),
      size,
      language,
      promptPolicy,
      HolidayGreetingProjectConfig.fromJSON(config),
      _prompt,
      holiday,
      images,
      figure_style,
      _storyboard,
      _composition,
      histories
    );
  }

  constructor(
    id: string,
    version: number,
    authorId: string,
    constraint: HolidayGreetingConstraint,
    size: Size,
    language: string,
    promptPolicy: PromptPolicy<'holiday_greeting'>,
    config: HolidayGreetingProjectConfig,
    prompt: Record<PromptType, string>,
    readonly holiday: Holiday,
    readonly images: string[],
    readonly figureStyle: string,
    storyboard?: HolidayGreetingStoryboard,
    composition?: HolidayGreetingComposition,
    histories?: string[]
  ) {
    super(
      id,
      version,
      authorId,
      constraint,
      size,
      language,
      promptPolicy,
      config,
      prompt,
      storyboard,
      composition,
      histories
    );
  }

  get type() {
    return 'holiday_greeting' as const;
  }

  get thumbnail() {
    const firstValidScene = this.storyboard?.scenes?.find(scene =>
      scene.isValid()
    );
    return firstValidScene ? firstValidScene.currentImage : undefined;
  }

  patch(data: Partial<HolidayGreetingProject>) {
    const {
      id,
      version,
      authorId,
      constraint,
      size,
      language,
      promptPolicy,
      config,
      storyboard,
      composition,
      histories,
      holiday,
      images,
      figureStyle,
    } = Object.assign({}, this, data);
    const _prompt = mergePrompt(
      this._prompt,
      checkoutPromptType(promptPolicy),
      data
    );
    return new HolidayGreetingProject(
      id,
      version,
      authorId,
      constraint,
      size,
      language,
      promptPolicy,
      config,
      _prompt,
      holiday,
      images,
      figureStyle,
      storyboard,
      composition,
      histories
    );
  }

  toJSON(): HolidayGreetingProjectJSON {
    return {
      ...super.toJSON(),
      holiday: this.holiday,
      images: this.images,
      figure_style: this.figureStyle,
    };
  }
}
