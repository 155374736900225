import {ProjectType} from 'modules/project/types';

import {BilingualDialoguePreference} from './models/BilingualDialoguePreference';
import {BilingualStoryPreference} from './models/BilingualStoryPreference';
import {GeneralStoryPreference} from './models/GeneralStoryPreference';
import {HolidayGreetingPreference} from './models/HolidayGreetingPreference';
import {Preference} from './models/Preference';
import {ShortVideoPreference} from './models/ShortVideoPreference';

//preference是GeneralStoryPreference、ShortVideoPreference、BilingualStoryPreference、BilingualStoryPreference的有Voiceover
export function preferenceHasVoiceover(
  preference: Preference<ProjectType>
): preference is
  | GeneralStoryPreference
  | ShortVideoPreference
  | BilingualStoryPreference
  | HolidayGreetingPreference {
  return (
    preference instanceof GeneralStoryPreference ||
    preference instanceof HolidayGreetingPreference ||
    preference instanceof ShortVideoPreference ||
    preference instanceof BilingualStoryPreference
  );
}
//preference是GeneralStoryPreference、ShortVideoPreference、BilingualStoryPreference、BilingualDialoguePreference的有Effect
export function preferenceHasEffect(
  preference: Preference<ProjectType>
): preference is
  | GeneralStoryPreference
  | ShortVideoPreference
  | BilingualStoryPreference
  | BilingualDialoguePreference {
  return (
    preference instanceof GeneralStoryPreference ||
    preference instanceof ShortVideoPreference ||
    preference instanceof BilingualStoryPreference ||
    preference instanceof BilingualDialoguePreference
  );
}
//preference是GeneralStoryPreference、ShortVideoPreference、BilingualStoryPreference、BilingualDialoguePreference的有TitleStyle
export function preferenceHasTitleStyle(
  preference: Preference<ProjectType>
): preference is
  | GeneralStoryPreference
  | ShortVideoPreference
  | BilingualStoryPreference
  | BilingualDialoguePreference {
  return (
    preference instanceof GeneralStoryPreference ||
    preference instanceof ShortVideoPreference ||
    preference instanceof BilingualStoryPreference ||
    preference instanceof BilingualDialoguePreference
  );
}
//preference是GeneralStoryPreference、ShortVideoPreference、BilingualStoryPreference、BilingualDialoguePreference的有SubtitleStyle
export function preferenceHasSubtitleStyle(
  preference: Preference<ProjectType>
): preference is
  | GeneralStoryPreference
  | ShortVideoPreference
  | BilingualStoryPreference
  | BilingualDialoguePreference {
  return (
    preference instanceof GeneralStoryPreference ||
    preference instanceof ShortVideoPreference ||
    preference instanceof BilingualStoryPreference ||
    preference instanceof BilingualDialoguePreference
  );
}
//preference是GeneralStoryPreference、ShortVideoPreference、BilingualStoryPreference、BilingualDialoguePreference的有Transition
export function preferenceHasTransition(
  preference: Preference<ProjectType>
): preference is
  | GeneralStoryPreference
  | ShortVideoPreference
  | BilingualStoryPreference
  | BilingualDialoguePreference {
  return (
    preference instanceof GeneralStoryPreference ||
    preference instanceof ShortVideoPreference ||
    preference instanceof BilingualStoryPreference ||
    preference instanceof BilingualDialoguePreference
  );
}
//preference是GeneralStoryPreference、BilingualStoryPreference、BilingualDialoguePreference、HolidayGreetingPreference的有Thumbnail
export function preferenceHasThumbnail(
  preference: Preference<ProjectType>
): preference is
  | GeneralStoryPreference
  | BilingualStoryPreference
  | BilingualDialoguePreference {
  return (
    preference instanceof GeneralStoryPreference ||
    preference instanceof BilingualStoryPreference ||
    preference instanceof BilingualDialoguePreference
  );
}
//preference是GeneralStoryPreference、ShortVideoPreference、HolidayGreetingPreference的有VoiceoverSpeed
export function preferenceHasVoiceoverSpeed(
  preference: Preference<ProjectType>
): preference is
  | GeneralStoryPreference
  | ShortVideoPreference
  | HolidayGreetingPreference {
  return (
    preference instanceof GeneralStoryPreference ||
    preference instanceof ShortVideoPreference ||
    preference instanceof HolidayGreetingPreference
  );
}
//preference是GeneralStoryPreference、ShortVideoPreference的有Tone
export function preferenceHasTone(
  preference: Preference<ProjectType>
): preference is GeneralStoryPreference | ShortVideoPreference {
  return (
    preference instanceof GeneralStoryPreference ||
    preference instanceof ShortVideoPreference
  );
}
//preference是GeneralStoryPreference的有quick_pace
export function preferenceHasQuickPace(
  preference: Preference<ProjectType>
): preference is GeneralStoryPreference {
  return preference instanceof GeneralStoryPreference;
}
//preference是BilingualStoryPreference、BilingualDialoguePreference的有proficiencyLevel
export function preferenceHasProficiencyLevel(
  preference: Preference<ProjectType>
): preference is BilingualStoryPreference | BilingualDialoguePreference {
  return (
    preference instanceof BilingualStoryPreference ||
    preference instanceof BilingualDialoguePreference
  );
}
//preference是GeneralStoryPreference、ShortVideoPreference、BilingualStoryPreference、HolidayGreetingPreference的有paragraphAsShots
export function preferenceHasParagraphAsShots(
  preference: Preference<ProjectType>
): preference is
  | GeneralStoryPreference
  | ShortVideoPreference
  | BilingualStoryPreference {
  return (
    preference instanceof GeneralStoryPreference ||
    preference instanceof ShortVideoPreference ||
    preference instanceof BilingualStoryPreference
  );
}
//preference是GeneralStoryPreference、ShortVideoPreference、BilingualStoryPreference、BilingualDialoguePreference的有style
export function preferenceHasStyle(
  preference: Preference<ProjectType>
): preference is
  | GeneralStoryPreference
  | ShortVideoPreference
  | BilingualStoryPreference
  | BilingualDialoguePreference {
  return (
    preference instanceof GeneralStoryPreference ||
    preference instanceof ShortVideoPreference ||
    preference instanceof BilingualStoryPreference ||
    preference instanceof BilingualDialoguePreference
  );
}
//preference是HolidayGreetingPreference的有figureStyle
export function preferenceHasFigureStyle(
  preference: Preference<ProjectType>
): preference is HolidayGreetingPreference {
  return preference instanceof HolidayGreetingPreference;
}
//preference是HolidayGreetingPreference的有holiday
export function preferenceHasHoliday(
  preference: Preference<ProjectType>
): preference is HolidayGreetingPreference {
  return preference instanceof HolidayGreetingPreference;
}
//preference是BilingualStoryPreference、BilingualDialoguePreference的有nativeLanguage
export function preferenceHasNativeLanguage(
  preference: Preference<ProjectType>
): preference is BilingualStoryPreference | BilingualDialoguePreference {
  return (
    preference instanceof BilingualStoryPreference ||
    preference instanceof BilingualDialoguePreference
  );
}
//preference是BilingualStoryPreference、GeneralStoryPreference、ShortVideoPreference的有avatar
export function preferenceHasAvatar(
  preference: Preference<ProjectType>
): preference is
  | BilingualStoryPreference
  | GeneralStoryPreference
  | ShortVideoPreference {
  return (
    preference instanceof BilingualStoryPreference ||
    preference instanceof GeneralStoryPreference ||
    preference instanceof ShortVideoPreference
  );
}
//preference是HolidayGreetingPreference的有CardDesign
export function preferenceHasCardDesign(
  preference: Preference<ProjectType>
): preference is HolidayGreetingPreference {
  return preference instanceof HolidayGreetingPreference;
}

//preference是HolidayGreetingPreference的有OverlayEffect
export function preferenceHasOverlayEffect(
  preference: Preference<ProjectType>
): preference is HolidayGreetingPreference {
  return preference instanceof HolidayGreetingPreference;
}
