// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {staticCombiner} from 'api/frontend';
import {ReactComponent as Delete3Icon} from 'assets/svg/3.0/Delete3.svg';
import {ReactComponent as DownloadIcon} from 'assets/svg/3.0/Download.svg';
import {ReactComponent as LeftArrowIcon} from 'assets/svg/3.0/LeftArrow.svg';
import {ReactComponent as ShareIcon} from 'assets/svg/3.0/share.svg';
import {ReactComponent as VideoIcon} from 'assets/svg/3.0/Video.svg';
import {ReactComponent as YouTubeIcon} from 'assets/svg/fill/YouTube.svg';
import classNames from 'classnames';
import {LoadingDialog} from 'components/Loading';
import {Popover} from 'components/PopoverNew';
import {VideoDownloadToast} from 'components/VideoDownloadToast';
import {YouTubeShareDialog} from 'components/YouTubeShare';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useResourceManager} from 'contexts/ResourceManager';
import {useSize} from 'contexts/SizeContext';
import {download} from 'lib/download';
import {useVisible} from 'lib/hooks';
import {formatAspectRatio} from 'lib/ratio';
import {noop} from 'lodash';
import {useRawVideoPayment} from 'modules/payment/services/raw-video';
import {downloadStoryboard, trimNewline} from 'modules/project/utils';
import {BilingualDialogueHistory} from 'modules/project-history/models/BilingualDialogueHistory';
import {BilingualStoryHistory} from 'modules/project-history/models/BilingualStoryHistory';
import {dateToUpdateTime} from 'pages/common/date';
import {IconButton} from 'pages/components/Buttons';
import {Toast} from 'pages/components/Toast';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {isRtl} from 'utils/is-rtl';
import {PaymentStatusEnum} from 'utils/stripe';

import {Button} from '../Button';
import {LoadingPrompt} from '../LoadingPrompt';
import {ResultPreviewer} from '../ResultPreviewer';
import styles from './HistoryPreviewer.module.scss';
import {Props} from './HistoryPreviewer.types';

export function HistoryPreviewer({
  resultHistories,
  loading,
  currentHistory,
  projectId,
  outTradeNo,
  paymentStatus,
  type,
  selectedHistoryIndex,
  assetUrl,
  refreshAssetUrl,
  setSelectedHistoryIndex,
  onClearOutTradeNo,
  onCurrentVideoPaymentSuccess,
  deleteHistoryById,
  canDeleteHistory,
}: Props) {
  const [storyboardDownloading, setStoryboardDownloading] = useState(false);
  const [isPaymentChecking, setIsPaymentChecking] = useState(false);
  const [visible, hide, show, deleteId] = useVisible<string>();

  const [downloadToastVisible, setDownloadToastVisible] = useState(false);

  const [showDownloadPopover, setShowDownloadPopover] = useState(false);
  const [showDownloadButtons, setShowDownloadButtons] = useState(false);
  const [showSharePopover, setShowSharePopover] = useState(false);
  const [showShareButtons, setShowShareButtons] = useState(false);
  const [showYouTubeShare, setShowYouTubeShare] = useState(false);

  const buttonBoxRef = useRef<HTMLDivElement>(null);

  const {getLanguages} = useResourceManager();
  const {checkPaymentStatus} = useRawVideoPayment();
  const {showNotification} = useNotificationContext();
  const languages = getLanguages();
  const assetLoading = assetUrl === undefined;
  const showDownloadToast = !!(
    currentHistory?.assetProduct && assetUrl === null
  );

  const downloadVideo = useCallback(() => {
    download(assetUrl ?? currentHistory.asset, currentHistory.title ?? '');
  }, [assetUrl, currentHistory?.asset, currentHistory?.title]);

  const onCloseDownloadToast = useCallback(() => {
    setDownloadToastVisible(false);
    onClearOutTradeNo && onClearOutTradeNo();
  }, [onClearOutTradeNo]);

  const {t} = useTranslation('workspace');
  const {t: commonT} = useTranslation();
  const {t: projectT} = useTranslation('project');

  const onVideoPaymentCallback = useCallback(async () => {
    if (outTradeNo && paymentStatus) {
      setIsPaymentChecking(true);
      try {
        if (paymentStatus === PaymentStatusEnum.Success) {
          await checkPaymentStatus(outTradeNo);
          showNotification({message: 'Pay success', type: 'SUCCESS'});
          await refreshAssetUrl();
          selectedHistoryIndex === 0 && onCurrentVideoPaymentSuccess?.();
        } else {
          throw Error('Pay failed');
        }
      } catch (e) {
        showNotification({message: 'Pay failed', type: 'ERROR'});
        setDownloadToastVisible(true);
      }
      setIsPaymentChecking(false);
    }
  }, [
    outTradeNo,
    paymentStatus,
    checkPaymentStatus,
    showNotification,
    refreshAssetUrl,
    selectedHistoryIndex,
    onCurrentVideoPaymentSuccess,
  ]);

  const sharePopoverContent = (
    <div className={styles.sharePopoverContent}>
      <div
        className={styles.item}
        onClick={() => {
          setShowSharePopover(false);
          setShowShareButtons(false);
          setShowYouTubeShare(true);
        }}
      >
        <div className={styles.icon}>
          <YouTubeIcon />
        </div>
        <div className={styles.text}>YouTube /Shorts</div>
      </div>
      <div className={classNames(styles.item, styles.disabled)}>
        <div className={styles.icon}></div>
        <div className={styles.text}>{projectT('Coming soon')}</div>
      </div>
      <div className={classNames(styles.item, styles.disabled)}>
        <div className={styles.icon}></div>
        <div className={styles.text}>{projectT('Coming soon')}</div>
      </div>
    </div>
  );

  const renderMobileButtons = () => {
    if (showShareButtons) {
      return (
        <div className={styles.mobileShareButtons}>
          <div className={classNames(styles.buttonBoxHeader, styles.xsShow)}>
            {t('Share')}
          </div>
          {sharePopoverContent}
        </div>
      );
    }
    if (showDownloadButtons) {
      return (
        <>
          <div className={classNames(styles.buttonBoxHeader, styles.xsShow)}>
            {t('Download')}
          </div>
          <Button
            className={classNames(styles.button, styles.xsShow)}
            theme="primary"
            size="middle"
            type="ghost"
            onClick={() => {
              setShowDownloadButtons(false);
              if (!showDownloadToast) {
                downloadVideo();
                return;
              }
              setDownloadToastVisible(true);
            }}
            icon={<DownloadIcon className={styles.icon} />}
          >
            {t('Video(MP4)')}
          </Button>
          <Button
            className={classNames(styles.button, styles.xsShow)}
            size="middle"
            type="ghost"
            onClick={async () => {
              setShowDownloadButtons(false);
              setStoryboardDownloading(true);

              try {
                await downloadStoryboard(
                  {
                    title: currentHistory.title || '',
                    description: currentHistory.description || '',
                    hashtags: currentHistory.hashtags || [],
                    scenes: currentHistory.scenes || [],
                    prompt: currentHistory.promptPolicy?.isIdeaPrompt
                      ? currentHistory.prompt
                      : '',
                  },
                  {
                    summary: t('Summary'),
                    hashtags: t('Hashtags'),
                    script: t('Script'),
                    prompt: t('Prompt'),
                  }
                );
              } catch (error) {
                console.error(error);
              }

              setStoryboardDownloading(false);
            }}
            icon={<DownloadIcon className={styles.icon} />}
            isLoading={storyboardDownloading}
          >
            {t('Storyboard')}
          </Button>
          <Button
            className={classNames(styles.button, styles.xsShow)}
            size="middle"
            type="ghost"
            onClick={() => {
              setShowDownloadButtons(false);
              download(currentHistory.thumbnail, currentHistory.title || '');
            }}
            icon={<DownloadIcon className={styles.icon} />}
          >
            {t('Thumbnail(JPG)')}
          </Button>
        </>
      );
    }
    return (
      <>
        <Button
          className={classNames(styles.button, styles.xsShow)}
          size="large"
          icon={<DownloadIcon className={styles.icon} />}
          onClick={() => setShowDownloadButtons(true)}
        >
          {t('Download')}
        </Button>
        <Button
          className={classNames(styles.button, styles.xsShow)}
          size="large"
          icon={<ShareIcon className={styles.icon} />}
          onClick={() => setShowShareButtons(true)}
        >
          {t('Share')}
        </Button>
      </>
    );
  };

  useEffect(() => {
    if (!currentHistory) return;
    onVideoPaymentCallback();
  }, [currentHistory, onVideoPaymentCallback]);

  useEffect(() => {
    if (outTradeNo && paymentStatus && typeof assetUrl === 'string') {
      downloadVideo();
      onCloseDownloadToast();
    }
  }, [
    assetUrl,
    downloadVideo,
    onCloseDownloadToast,
    outTradeNo,
    paymentStatus,
  ]);

  useEffect(() => {
    const handler = (e: PointerEvent) => {
      if (
        e.target instanceof HTMLElement &&
        buttonBoxRef.current &&
        !buttonBoxRef.current.contains(e.target)
      ) {
        setShowDownloadButtons(false);
        setShowShareButtons(false);
      }
    };
    document.addEventListener('pointerdown', handler);
    return () => {
      document.removeEventListener('pointerdown', handler);
    };
  }, []);

  const {size} = useSize();

  const [previewerActive, setPreviewerActive] = useState(false);

  return (
    <div className={styles.container}>
      <LoadingDialog
        dialogShowing={isPaymentChecking}
        onCloseDialog={noop}
        hasText={false}
        size={32}
      />
      <div
        className={classNames(styles.left, {
          [styles.loading]: loading,
          [styles.hide]: previewerActive,
        })}
      >
        {!loading && currentHistory && (
          <>
            <p className={styles.title}>
              <VideoIcon className={styles.icon} />
              <span>{t('History')}</span>
            </p>
            <div className={styles['history-list']}>
              <ul className={styles.list}>
                {resultHistories.map((item, index) => {
                  return (
                    <li
                      className={classNames(
                        styles['history-item'],
                        index === selectedHistoryIndex && styles.selected
                      )}
                      key={item.id}
                      onClick={() => {
                        selectedHistoryIndex !== index &&
                          setSelectedHistoryIndex(index);
                        setPreviewerActive(true);
                      }}
                    >
                      <div
                        className={styles.thumbnail}
                        style={{
                          background: `url(${staticCombiner(
                            item.thumbnail
                          )}) no-repeat center center`,
                          backgroundSize: 'cover',
                        }}
                      ></div>
                      <div className={styles.info}>
                        <p
                          className={classNames(styles['project-title'], {
                            'rtl-element-global': isRtl(
                              languages,
                              item.language
                            ),
                          })}
                        >
                          {trimNewline(
                            item.title || commonT('Untitled'),
                            item.language ?? 'en-US'
                          )}
                        </p>
                        <p className={styles.time}>
                          {dateToUpdateTime(new Date(`${item.createTime}Z`))}
                          {canDeleteHistory && index !== 0 && (
                            <IconButton
                              Icon={Delete3Icon}
                              hotspotSize={32}
                              iconSize={16}
                              onClick={e => {
                                e.stopPropagation();
                                show(item.id);
                              }}
                            />
                          )}
                        </p>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        )}
        {loading && (size === 'sm' || size === 'xs') && (
          <LoadingPrompt type="processing" />
        )}
      </div>
      <div
        className={classNames(styles.previewer, loading && styles.loading, {
          [styles.active]: previewerActive,
        })}
      >
        <LeftArrowIcon
          className={styles['left-arrow']}
          onClick={() => setPreviewerActive(false)}
        />
        {!loading && currentHistory && (
          <div className={styles.result}>
            <ResultPreviewer
              language={currentHistory.language}
              nativeLanguage={
                currentHistory instanceof BilingualStoryHistory ||
                currentHistory instanceof BilingualDialogueHistory
                  ? currentHistory.nativeLanguage
                  : undefined
              }
              detailWidth={284}
              description={currentHistory.description || ''}
              scenes={currentHistory.scenes}
              size={currentHistory.size}
              title={currentHistory.title || ''}
              video={assetUrl ?? currentHistory.asset}
              hashtags={currentHistory.hashtags || []}
              prompt={
                currentHistory.promptPolicy &&
                currentHistory.promptPolicy.isIdeaPrompt
                  ? currentHistory.prompt
                  : ''
              }
              loading={assetLoading}
            />
          </div>
        )}
        {loading && <LoadingPrompt type="processing" />}
        {!loading && currentHistory && (
          <div className={styles['button-box']} ref={buttonBoxRef}>
            <Popover
              className={styles.xsHide}
              visible={showDownloadPopover}
              onVisibleChange={setShowDownloadPopover}
              direction="bottom"
              triggerElement={
                <Button
                  className={styles.button}
                  size="large"
                  icon={<DownloadIcon className={styles.icon} />}
                >
                  {projectT('Download')}
                </Button>
              }
              popoverClassName={styles.downloadPopover}
              content={
                <>
                  <Button
                    type="ghost"
                    className={styles.textButton}
                    disabled={assetLoading}
                    onClick={() => {
                      setShowDownloadPopover(false);
                      if (!showDownloadToast) {
                        downloadVideo();
                        return;
                      }
                      setDownloadToastVisible(true);
                    }}
                    icon={<DownloadIcon className={styles.icon} />}
                  >
                    {t('Video(MP4)')}
                  </Button>
                  <Button
                    type="ghost"
                    className={styles.textButton}
                    onClick={() => {
                      setShowDownloadPopover(false);
                      download(
                        currentHistory.thumbnail,
                        currentHistory.title || ''
                      );
                    }}
                    icon={<DownloadIcon className={styles.icon} />}
                  >
                    {t('Thumbnail(JPG)')}
                  </Button>
                  <Button
                    type="ghost"
                    className={styles.textButton}
                    disabled={!currentHistory.scenes}
                    onClick={async () => {
                      setShowDownloadPopover(false);
                      setStoryboardDownloading(true);

                      try {
                        await downloadStoryboard(
                          {
                            title: currentHistory.title || '',
                            description: currentHistory.description || '',
                            hashtags: currentHistory.hashtags || [],
                            scenes: currentHistory.scenes || [],
                            prompt: currentHistory.promptPolicy?.isIdeaPrompt
                              ? currentHistory.prompt
                              : '',
                          },
                          {
                            summary: t('Summary'),
                            hashtags: t('Hashtags'),
                            script: t('Script'),
                            prompt: t('Prompt'),
                          }
                        );
                      } catch (error) {
                        console.error(error);
                      }

                      setStoryboardDownloading(false);
                    }}
                    icon={<DownloadIcon className={styles.icon} />}
                    isLoading={storyboardDownloading}
                  >
                    {t('Storyboard')}
                  </Button>
                </>
              }
              distance={17}
            />
            <Popover
              className={styles.xsHide}
              triggerElement={
                <Button
                  className={styles.button}
                  size="large"
                  icon={<ShareIcon className={styles.icon} />}
                >
                  {projectT('Share')}
                </Button>
              }
              popoverClassName={styles.sharePopover}
              content={sharePopoverContent}
              visible={showSharePopover}
              onVisibleChange={setShowSharePopover}
              direction="bottom"
              align="right"
              distance={12}
            />
            {renderMobileButtons()}
          </div>
        )}
      </div>
      {currentHistory?.assetProduct && showDownloadToast && (
        <VideoDownloadToast
          isVisible={downloadToastVisible}
          onCancel={onCloseDownloadToast}
          refreshAssetUrl={refreshAssetUrl}
          outTradeNo={outTradeNo}
          historyId={currentHistory.id}
          videoId={currentHistory.assetProduct}
          projectId={projectId}
          type={type}
          onDownload={downloadVideo}
        />
      )}
      <Toast
        btnTheme="danger"
        title={
          <span className={styles['delete-title']}>
            <Delete3Icon></Delete3Icon> <span>{projectT('Delete')}</span>
          </span>
        }
        visible={visible}
        confirmText={projectT('Yes')}
        cancelText={projectT('Cancel')}
        onOk={() => {
          deleteId && deleteHistoryById(deleteId);
          hide();
        }}
        onCancel={hide}
      >
        {projectT('Confirm to delete this history?')}
      </Toast>
      {showYouTubeShare && (
        <YouTubeShareDialog
          isRTL={isRtl(languages, currentHistory.language)}
          closeDialog={() => setShowYouTubeShare(false)}
          dialogShowing
          onCloseDialog={() => setShowYouTubeShare(false)}
          video={assetUrl ?? currentHistory.asset}
          ratio={formatAspectRatio(currentHistory.size)}
          thumbnail={currentHistory.thumbnail}
          title={currentHistory.title}
          description={currentHistory.description}
          tags={currentHistory.hashtags}
        />
      )}
    </div>
  );
}
