// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {mergeRegister} from '@lexical/utils';
import {
  $addUpdateTag,
  $createTextNode,
  $getRoot,
  $isParagraphNode,
  $isTextNode,
  ParagraphNode,
} from 'lexical';
import {useCallback, useEffect} from 'react';

import {
  $createErrorParagraph,
  $createErrorText,
  $isErrorTextNode,
} from '../nodes';
import {LimitPluginProps} from './types';

function replaceErrorParagraphNodeToParagraphNode(node: ParagraphNode) {
  if (Object.getPrototypeOf(node) === ParagraphNode.prototype) return node;
  const newNode = new ParagraphNode();
  node.getChildren().forEach(child => {
    newNode.append(child);
  });
  node.replace(newNode);
  return newNode;
}

export function LimitPlugin({
  maxParagraphCount = Infinity,
  maxParagraphCharacterCount = Infinity,
  maxCharacterCount = Infinity,
}: LimitPluginProps) {
  const [editor] = useLexicalComposerContext();

  const checkParagraphError = useCallback(() => {
    editor.update(() => {
      const root = $getRoot();
      const paragraphs = root.getChildren().filter($isParagraphNode);

      let validParagraphCount = 0;
      paragraphs.forEach(paragraph => {
        const isValidParagraph = paragraph.getTextContentSize() > 0;
        validParagraphCount += isValidParagraph ? 1 : 0;
        if (validParagraphCount > maxParagraphCount && isValidParagraph) {
          const errorParagraph = $createErrorParagraph();
          paragraph.getChildren().forEach(child => {
            errorParagraph.append(child);
          });
          paragraph.replace(errorParagraph);
        } else {
          replaceErrorParagraphNodeToParagraphNode(paragraph);
        }
      });
      $addUpdateTag('history-merge');
    });
  }, [editor, maxParagraphCount]);

  const checkTextNodeError = useCallback(() => {
    editor.update(() => {
      const paragraphs = $getRoot().getChildren().filter($isParagraphNode);
      paragraphs.forEach((paragraph, paragraphIndex) => {
        const frontParagraphs = paragraphs.slice(0, paragraphIndex);
        // 当前边所有段落的字数总和，加paragraphIndex是添加段落的回车符号的数量
        const frontParagraphsSize =
          frontParagraphs.reduce((acc, p) => acc + p.getTextContentSize(), 0) +
          paragraphIndex;

        // 当前边所有段落的字数总和超过prompt最大字数限制，直接将后续的段落的内容转换为ErrorText
        if (frontParagraphsSize > maxCharacterCount) {
          const text = paragraph.getTextContent();
          paragraph.clear();
          // 当有text才去添加ErrorText，避免在超出后，新增段落不聚焦的问题
          text && paragraph.append($createErrorText(text));
          // 当剩余的有效字数小于最大段落限制时，从剩余有效字数开始分割，之后的转换为ErrorText
        } else {
          const maxCount = Math.min(
            maxCharacterCount - frontParagraphsSize,
            maxParagraphCharacterCount
          );
          let cumulative = 0;
          const textNodes = paragraph.getChildren().filter($isTextNode);
          for (let i = 0; i < textNodes.length; i++) {
            const textNode = textNodes[i];

            const size = textNode.getTextContentSize();
            cumulative += size;
            if (cumulative > maxCount) {
              const splitIndex = maxCount - (cumulative - size);
              const [left, right] = textNode.splitText(splitIndex);

              // 将左侧设置为标准TextNode,如果右侧不存在则直接将左侧转换为ErrorText
              if (left && $isErrorTextNode(left) && right) {
                left.replace($createTextNode(left.getTextContent()));
              }

              const node = right ?? left;
              const rightPart = textNodes.slice(i + 1);
              const text =
                node.getTextContent() +
                rightPart
                  .map(n => {
                    n.remove();
                    return n.getTextContent();
                  })
                  .join('');
              const errorNode = $createErrorText(text);
              node.replace(errorNode);
              break;
            } else {
              if ($isErrorTextNode(textNode)) {
                textNode.replace($createTextNode(textNode.getTextContent()));
              }
            }
          }
        }
      });
      $addUpdateTag('history-merge');
    });
  }, [editor, maxCharacterCount, maxParagraphCharacterCount]);

  // 当用户切换 Per paragraph as scene时，需要重新检查
  useEffect(() => {
    checkParagraphError();
    checkTextNodeError();
  }, [
    checkParagraphError,
    checkTextNodeError,
    maxCharacterCount,
    maxParagraphCharacterCount,
    maxParagraphCount,
  ]);

  useEffect(() => {
    return mergeRegister(
      editor.registerTextContentListener(checkParagraphError),
      editor.registerTextContentListener(checkTextNodeError)
    );
  }, [editor, checkParagraphError, checkTextNodeError]);

  return null;
}
