// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Response, ResponseCode} from 'api/BackendClient';
import {AxiosResponse} from 'axios';
import classNames from 'classnames';
import {useAPI} from 'contexts/APIContext';
import {clearUserToken} from 'contexts/localStorage';
import {EMAIL_REG} from 'lib/auth';
import {EmailErrorType, UseErrorToastReturnType} from 'modules/auth/types';
import {Button} from 'pages/components/Button';
import {GlassInputBox} from 'pages/components/GlassInputBox/GlassInputBox';
import {useCallback, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate, useOutletContext} from 'react-router-dom';
import {CHECK_EMAIL_PATH, LOGIN_PATH, SIGNUP_PATH} from 'utils/path';

import styles from './AuthForm.module.scss';
import {EmailErrorMessage} from './EmailErrorMessage';

export function ResetPasswordForm() {
  const {t} = useTranslation('auth');

  const {setErrorToastType, showErrorToast} =
    useOutletContext<UseErrorToastReturnType>();

  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const [emailErrorType, setEmailErrorType] = useState<EmailErrorType | null>(
    null
  );
  const validEmail = useCallback(
    (callbackFun?: () => void) => {
      const isValid = EMAIL_REG.test(email);

      if (isValid && emailErrorType === 'EMAIL_INVALID') {
        setEmailErrorType(null);
      } else if (!isValid) {
        setEmailErrorType('EMAIL_INVALID');
      }
      if (isValid && callbackFun) {
        callbackFun();
      }
      return isValid;
    },
    [email, emailErrorType]
  );

  const {backendClient} = useAPI();

  const onConfirm = useCallback(() => {
    validEmail(() => {
      backendClient
        .sendEmail(email)
        .then(() => {
          //点击后退出登录，避免验证邮箱跳转回来时，用户处于已经登录状态
          clearUserToken();
          navigate(CHECK_EMAIL_PATH, {state: {email}, replace: true});
        })
        .catch((err: AxiosResponse<Response>) => {
          const {error, message} = err.data;
          if (!error && Array.isArray(message)) {
            setEmailErrorType('EMAIL_INVALID');
          } else if (error === ResponseCode.USER_NOT_FOUND) {
            setEmailErrorType('USER_NOT_FOUND');
          } else if (error === ResponseCode.USER_EMAIL_NO_PASSWORD) {
            //Toast提示后，点击OK，回到登录页
            setErrorToastType('cannotResetPassword');
            showErrorToast(true, '', () => {
              navigate(LOGIN_PATH);
            });
          }
          return;
        });
    });
  }, [
    backendClient,
    email,
    navigate,
    setErrorToastType,
    showErrorToast,
    validEmail,
  ]);

  const changeEmail = useCallback(
    (value: string) => {
      if (emailErrorType && EMAIL_REG.test(value)) {
        setEmailErrorType(null);
      }
      setEmail(value);
    },
    [emailErrorType]
  );

  return (
    <>
      <div className={styles.title}>{t('Reset password')}</div>
      <div className={styles['content-container']}>
        <div className={styles.formContainer}>
          <div className={styles.auth_form_container}>
            <GlassInputBox
              className={classNames(styles.password_input, styles.input)}
              placeholder={t('Enter your email')}
              value={email}
              onChange={e => changeEmail(e.target.value)}
              onBlur={() => {
                validEmail();
              }}
            />
            {emailErrorType !== null && (
              <EmailErrorMessage
                errorType={emailErrorType}
                className={styles.error_message}
              />
            )}
            <div className={classNames(styles.resetPasswordBox)}>
              <Button
                type="button"
                size="large"
                onClick={onConfirm}
                disabled={emailErrorType !== null}
              >
                {t('Reset password')}
              </Button>
            </div>
          </div>
        </div>
        <p className={styles.to_signup}>
          <span>{t("Don't have an account? ")}</span>
          <Link to={SIGNUP_PATH} className={styles.link}>
            {t('Sign up for free')}
          </Link>
        </p>
      </div>
    </>
  );
}
