// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import classNames from 'classnames';
import {ReactNode, useCallback, useEffect, useRef, useState} from 'react';
import {usePress} from 'react-aria';
import {Popover, PopoverProps} from 'react-aria-components';

import styles from './AriaPopover.module.scss';

export function AriaPopover({
  triggerElement,
  children,
  isOpen,
  onOpenChange,
  popupMatchSelectWidth,
  triggerClassName,
  ...props
}: Omit<PopoverProps, 'triggerRef'> & {
  triggerElement: ReactNode;
  triggerClassName?: string;
  popupMatchSelectWidth?: boolean;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [innerOpen, setInnerOpen] = useState(!!isOpen);

  const onPress = useCallback(() => {
    if (onOpenChange) {
      onOpenChange(!innerOpen);
    } else {
      setInnerOpen(!innerOpen);
    }
  }, [innerOpen, onOpenChange]);

  const {pressProps} = usePress({
    ref,
    onPress,
  });

  useEffect(() => {
    if (isOpen !== undefined) {
      setInnerOpen(isOpen);
    }
  }, [isOpen]);

  const style = popupMatchSelectWidth ? {width: ref.current?.clientWidth} : {};

  return (
    <>
      <div
        {...pressProps}
        className={classNames(styles.trigger, triggerClassName)}
        ref={ref}
        role="button"
      >
        {triggerElement}
      </div>
      <Popover
        {...props}
        triggerRef={ref}
        isOpen={isOpen ?? innerOpen}
        onOpenChange={onPress}
        style={style}
      >
        {children}
      </Popover>
    </>
  );
}
