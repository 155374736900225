// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import EmptyAvatar from 'assets/images/Avatar2.png';
import {ReactComponent as RightArrowIcon} from 'assets/svg/3.0/ArrowRight.svg';
import {ReactComponent as EduTagIcon} from 'assets/svg/fill/EduTag.svg';
import classNames from 'classnames';
import {Popover} from 'components/AriaPopover';
import {Tooltip} from 'components/Tooltip';
import {Badge, useUserContext} from 'contexts/UserContext';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Menu} from '../Menu';
import styles from './UserCard.module.scss';

interface Props {
  onClickChangePW: () => void;
  onClose?: () => void;
  active: boolean;
}

export function UserCard({onClickChangePW, onClose, active}: Props) {
  const {
    userInfo: {userName, portrait, profile},
  } = useUserContext();

  const {t} = useTranslation('workspace');

  const [isSelected, setIsSelected] = useState(false);
  useEffect(() => {
    if (!active) setIsSelected(false);
  }, [active]);

  return (
    <Popover
      placement="right"
      isOpen={isSelected}
      onOpenChange={setIsSelected}
      triggerElement={
        <div
          className={classNames(
            styles.container,
            isSelected && styles.selected
          )}
        >
          <img className={styles.avatar} src={portrait || EmptyAvatar} />
          <p className={styles.userInfo}>
            <span className={styles.username}>{userName}</span>
            <span className={styles.tags}>
              {profile && profile.badges.includes(Badge.EDU) && (
                <Tooltip
                  tooltip={
                    <div className={styles['tag-popover-content']}>
                      {profile.plan === 'FREE'
                        ? t(
                            'EDU users enjoy all standard plan features for a month.'
                          )
                        : t(
                            'EDU users with paid plans enjoy 1,000 more credits for a month.'
                          )}
                    </div>
                  }
                  className={styles['tag-popover']}
                  tooltipClassName={styles['tag-popover-wrapper']}
                >
                  <EduTagIcon />
                </Tooltip>
              )}
            </span>
          </p>
          <RightArrowIcon className={styles.icon} />
        </div>
      }
    >
      <Menu
        onClose={() => setIsSelected(false)}
        onClickChangePW={onClickChangePW}
      />
    </Popover>
  );
}
