// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as DiscordIcon} from 'assets/svg/3.0/Discord.svg';
import classNames from 'classnames';
import {useAnalysis} from 'contexts/AnalysisContext';

const DISCORD_AUTH_PATH = `${process.env.REACT_APP_API_URL}/auth/discord-login?state=storyteller`;

export function DiscordButton({
  disabled = false,
  text,
  styles,
}: {
  disabled?: boolean;
  text: string;
  styles: Record<string, string>;
}) {
  const {recordEvent} = useAnalysis();
  return (
    <div
      className={classNames(styles.btnContainer, {
        [styles.disabled]: disabled,
      })}
      onClick={() => {
        if (disabled) return;
        recordEvent('Discord-Button-Click');
        location.href = DISCORD_AUTH_PATH;
      }}
    >
      <DiscordIcon className={styles.iconDiscord} />
      <span className={styles.text}>{text}</span>
    </div>
  );
}
