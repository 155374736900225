// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Volume} from 'modules/project/utils';

import {
  Avatar,
  BilingualStoryProjectConfigJSON,
  Effect,
  ThumbnailType,
} from '../types';
import {BaseProjectConfig} from './BaseProjectConfig';

export class BilingualStoryProjectConfig extends BaseProjectConfig {
  static fromJSON({
    voiceover,
    effect,
    title_style,
    subtitle_style,
    transition,
    thumbnail_type,
    thumbnail_include_video,
    bgm,
    voiceover_order,
    avatar,
    bgm_volume,
    voiceover_volume,
  }: BilingualStoryProjectConfigJSON) {
    return new BilingualStoryProjectConfig(
      voiceover,
      effect,
      title_style,
      subtitle_style,
      transition,
      thumbnail_type,
      thumbnail_include_video,
      bgm,
      voiceover_order,
      avatar,
      bgm_volume,
      voiceover_volume
    );
  }

  constructor(
    readonly voiceover: string | null,
    readonly effect: Effect,
    readonly titleStyle: string | null,
    readonly subtitleStyle: string | null,
    readonly transition: string | null,
    readonly thumbnailType: ThumbnailType,
    readonly thumbnailIncludeVideo: boolean,
    bgm: string | null,
    readonly voiceoverOrder: number,
    readonly avatar?: Avatar,
    readonly bgmVolume?: Volume,
    readonly voiceoverVolume?: Volume
  ) {
    super(bgm);
  }

  patch(data: Partial<BilingualStoryProjectConfig>) {
    const {
      voiceover,
      effect,
      titleStyle,
      subtitleStyle,
      transition,
      thumbnailType,
      thumbnailIncludeVideo,
      bgm,
      voiceoverOrder,
      avatar,
      bgmVolume,
      voiceoverVolume,
    } = Object.assign({}, this, data);
    return new BilingualStoryProjectConfig(
      voiceover,
      effect,
      titleStyle,
      subtitleStyle,
      transition,
      thumbnailType,
      thumbnailIncludeVideo,
      bgm,
      voiceoverOrder,
      avatar,
      bgmVolume,
      voiceoverVolume
    );
  }

  toJSON(): BilingualStoryProjectConfigJSON {
    return {
      ...super.toJSON(),
      voiceover: this.voiceover,
      effect: this.effect,
      title_style: this.titleStyle,
      subtitle_style: this.subtitleStyle,
      transition: this.transition,
      thumbnail_type: this.thumbnailType,
      thumbnail_include_video: this.thumbnailIncludeVideo,
      voiceover_order: this.voiceoverOrder,
      avatar: this.avatar,
      bgm_volume: this.bgmVolume,
      voiceover_volume: this.voiceoverVolume,
    };
  }
}
