// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {generatePath} from 'react-router-dom';

export const PROFILE_SETTING_PAGE = '/profile-setting';

export const ROOT_PATH = '/';
export const AUTH_PATH = '/auth';
export const PROMPT_PROJECT_PATH = '/pre-project/new';
export const GENERATING_PATH = '/pre-project/generating';
export const LOGIN_PATH = `${AUTH_PATH}/login`;
export const SIGNUP_PATH = `${AUTH_PATH}/signup`;
export const CONFIRM_EMAIL_PATH = `${AUTH_PATH}/confirm-email`;
export const RESET_PASSWORD_PATH = `${AUTH_PATH}/reset-password`;
export const RESET_PATH = `${AUTH_PATH}/reset`;
export const CHECK_EMAIL_PATH = `${AUTH_PATH}/check-email`;

export const DISCORD_CALLBACK_PATH = '/discord/callback';

export const WORKSPACE_PAGE_PATH = '/workspace';
export const WORKSPACE_PAGE_GENERATE_PATH = `${WORKSPACE_PAGE_PATH}/generate`;
export const WORKSPACE_PAGE_ASSETS_PATH = `${WORKSPACE_PAGE_PATH}/assets`;
export const WORKSPACE_PAGE_GALLERY_PATH = `${WORKSPACE_PAGE_PATH}/gallery`;
export const WORKSPACE_PAGE_MYPROJECT_PATH = `${WORKSPACE_PAGE_PATH}/project`;
export const PLAN_PAGE_PATH = `${WORKSPACE_PAGE_PATH}/plan`;
export const PAYMENT_SUCCESS_PATH = `${PLAN_PAGE_PATH}/payment-success`;
export const PAYMENT_CANCEL_PATH = `${PLAN_PAGE_PATH}/payment-cancel`;

export const PROJECT_PATH = '/project/:id';
export const OLD_PROJECT_PATH = '/project';
export const COMPOSITE_CONFIG_PATH = `${OLD_PROJECT_PATH}/config/:id`;
export const RESULT_PATH = `${OLD_PROJECT_PATH}/result/:id`;
export const RESULT_GENERATING_PAGE_PATH = `${OLD_PROJECT_PATH}/result/:id`;

export const REFERRAL_PATH = '/referral';

export const HOME_PAGE = process.env.REACT_APP_HOME_PAGE;

export const DOCS_PAGE_PATH = '/docs';
export const TERMS_OF_SERVICE_PATH =
  HOME_PAGE + `${DOCS_PAGE_PATH}/terms-of-service.html`;
export const PRIVACY_POLICY_PATH =
  HOME_PAGE + `${DOCS_PAGE_PATH}/privacy-policy.html`;
export const COPYRIGHTS_PATH = HOME_PAGE + `${DOCS_PAGE_PATH}/copyrights.html`;
export const TIKTOKCALLBACK_PATH = '/tiktok/callback';

export function createFAQPath(lang: string) {
  return `${HOME_PAGE}${
    lang === 'en' ? '' : `/${lang}`
  }${DOCS_PAGE_PATH}/faq.html`;
}

export function generateIdPath(path: string, id: string) {
  return generatePath(path, {id});
}
