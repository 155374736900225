// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Response, ResponseCode} from 'api/BackendClient';
import {AxiosResponse} from 'axios';
import classNames from 'classnames';
import {useAPI} from 'contexts/APIContext';
import {useMessageContext} from 'contexts/MessageContext';
import {useUserContext} from 'contexts/UserContext';
import {usePasswordValidator} from 'modules/auth/services';
import {Button} from 'pages/components/Button';
import {GlassInputBox} from 'pages/components/GlassInputBox/GlassInputBox';
import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate} from 'react-router-dom';
import {CONFIRM_EMAIL_PATH, LOGIN_PATH, ROOT_PATH} from 'utils/path';
import {useQueryString} from 'utils/use-query-string';

import styles from './AuthForm.module.scss';
import {PasswordErrorMessage} from './PasswordErrorMessage';

export function DoResetPasswordForm() {
  const {t} = useTranslation('auth');
  const {
    password,
    confirmPassword,
    passwordErrorType,
    validPassword,
    validConfirmPassword,
    changePassword,
    changeConfirmPassword,
  } = usePasswordValidator();
  const getQueryParam = useQueryString();
  const {showMessage} = useMessageContext();
  const {updateUserLogin} = useUserContext();
  const navigate = useNavigate();
  const {backendClient} = useAPI();

  const onConfirm = () => {
    const email = getQueryParam('email');
    const token = getQueryParam('token');
    if (!email || !token) {
      showMessage({type: 'ERROR', content: 'Invalid URL'});
      return;
    }
    if (validPassword() && validConfirmPassword() && email && token) {
      backendClient
        .doResetPasswordByEmail({email, token, password, confirmPassword})
        .then(_ => {
          backendClient
            .login({
              email,
              password,
            })
            .then(res => {
              updateUserLogin(
                email,
                res.data.userName,
                res.data.userId,
                res.data.portrait,
                res.data.token,
                res.data.emailConfirmStatus
              );
              navigate(ROOT_PATH);
              return true;
            })
            .catch((err: AxiosResponse<Response>) => {
              const {error} = err.data || {};
              switch (error) {
                case ResponseCode.USER_EMAIL_NOT_CONFIRM_YET:
                  navigate(CONFIRM_EMAIL_PATH, {state: {email}, replace: true});
                  break;
              }
            });
        })
        .catch((err: AxiosResponse<Response>) => {
          const {error} = err.data;
          switch (error) {
            case ResponseCode.TOKEN_INVALID:
              showMessage({type: 'ERROR', content: 'Token invalid'});
              break;
          }
          return err;
        });
    }
  };

  const confirmPasswordInputRef = useRef<HTMLInputElement>(null);

  const focusConfirmPasswordInput = () => {
    const confirmPasswordInput = confirmPasswordInputRef.current;
    if (!confirmPasswordInput) return;
    confirmPasswordInput.focus();
  };

  return (
    <>
      <div className={styles.title}>{t('Please reset your password')}</div>
      <div className={styles['content-container']}>
        <div className={styles.formContainer}>
          <div className={styles.auth_form_container}>
            <GlassInputBox
              className={classNames(
                styles.password_input,
                styles.input,
                'body-m'
              )}
              placeholder={t('New password(at least 8 characters)')}
              onKeyUp={e =>
                e.key === 'Enter' &&
                validPassword() &&
                focusConfirmPasswordInput()
              }
              value={password}
              onChange={e => changePassword(e.target.value)}
              onBlur={validPassword}
              type="password"
            />
            <GlassInputBox
              className={classNames(
                styles.password_input,
                styles.input,
                'body-m'
              )}
              placeholder={t('confirm password')}
              ref={confirmPasswordInputRef}
              onKeyUp={e => e.key === 'Enter' && validPassword() && onConfirm()}
              value={confirmPassword}
              onChange={e => changeConfirmPassword(e.target.value)}
              onBlur={() => validConfirmPassword()}
              type="password"
            />

            {passwordErrorType !== null && (
              <PasswordErrorMessage
                errorType={passwordErrorType}
                className={styles.error_message}
              />
            )}
            <div className={classNames(styles.doResetPasswordBox)}>
              <Button
                type="button"
                size="large"
                onClick={onConfirm}
                disabled={passwordErrorType !== null}
              >
                {t('Change password')}
              </Button>
            </div>
          </div>
        </div>
        <p className={styles.to_login}>
          <span>{t('Already have your password?')}</span>
          <Link to={LOGIN_PATH} className={styles.link}>
            {t('Log in')}
          </Link>
        </p>
      </div>
    </>
  );
}
