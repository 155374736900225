// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import axios from 'axios';
import {YouTubeShareMetadata} from 'components/YouTubeShare';

const GET_GOOGLE_USER_INFO_URL =
  'https://www.googleapis.com/oauth2/v2/userinfo';

export async function getGoogleUserInfo(accessToken: string) {
  return (
    await axios.get(GET_GOOGLE_USER_INFO_URL + '?access_token=' + accessToken)
  ).data;
}

export function uploadVideo(
  accessToken: string,
  video: Blob,
  metadata: YouTubeShareMetadata
) {
  const formData = new FormData();
  formData.append(
    'metadata',
    new Blob([JSON.stringify(metadata)], {type: 'application/json'})
  );
  formData.append('video', video);

  return axios.post(
    'https://www.googleapis.com/upload/youtube/v3/videos?uploadType=multipart&part=snippet,status',
    formData,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
}

export function uploadThumbnail(
  accessToken: string,
  videoId: string,
  thumbnail: Blob
) {
  return axios.post(
    `https://www.googleapis.com/upload/youtube/v3/thumbnails/set?videoId=${videoId}`,
    thumbnail,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': thumbnail.type,
      },
    }
  );
}
