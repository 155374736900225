// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

/* eslint-disable @typescript-eslint/no-non-null-assertion */

import {GeneralStoryProjectConfig} from 'modules/project-config/models/GeneralStoryProjectConfig';

import {GeneralStoryComposition} from '../../composition/models/GeneralStoryComposition';
import {GeneralStoryConstraint} from '../../constraint/models/GeneralStoryConstraint';
import {GeneralStoryStoryboard} from '../../storyboard/models/GeneralStoryStoryboard';
import {GeneralStoryProjectJSON, PromptType, Size} from '../types';
import {checkoutPromptType, mergePrompt, parsePrompt} from '../utils';
import {BaseProject} from './BaseProject';
import {fromJSON as fromJSONPromptPolicy, PromptPolicy} from './PromptPolicy';

export class GeneralStoryProject extends BaseProject<'general_story'> {
  static fromJSON({
    id,
    version,
    author_id,
    constraint,
    size,
    language,
    prompt_policy,
    prompt,
    storyboard,
    composition,
    config,
    style,
    histories,
  }: GeneralStoryProjectJSON): GeneralStoryProject {
    const promptPolicy = fromJSONPromptPolicy('general_story', prompt_policy);
    const promptType = checkoutPromptType(promptPolicy);
    const _prompt = parsePrompt(promptType, prompt);
    const _storyboard = storyboard
      ? GeneralStoryStoryboard.fromJSON(
          size,
          language,
          style,
          promptPolicy,
          _prompt[promptType],
          storyboard
        )
      : undefined;
    if (composition && !_storyboard) {
      throw new Error('Composition is not allowed without storyboard');
    }
    const _composition =
      composition && _storyboard
        ? GeneralStoryComposition.fromJSON(
            _storyboard.size,
            _storyboard.language,
            _storyboard.style,
            _storyboard.promptPolicy,
            _storyboard.prompt,
            _storyboard.title!,
            _storyboard.description!,
            _storyboard.hashtags!,
            composition
          )
        : undefined;
    return new GeneralStoryProject(
      id,
      version,
      author_id,
      GeneralStoryConstraint.fromJSON(constraint),
      size,
      language,
      style,
      promptPolicy,
      GeneralStoryProjectConfig.fromJSON(config),
      _prompt,
      _storyboard,
      _composition,
      histories
    );
  }

  constructor(
    id: string,
    version: number,
    authorId: string,
    constraint: GeneralStoryConstraint,
    size: Size,
    language: string,
    readonly style: string,
    promptPolicy: PromptPolicy<'general_story'>,
    config: GeneralStoryProjectConfig,
    prompt: Record<PromptType, string>,
    storyboard?: GeneralStoryStoryboard,
    composition?: GeneralStoryComposition,
    histories?: string[]
  ) {
    super(
      id,
      version,
      authorId,
      constraint,
      size,
      language,
      promptPolicy,
      config,
      prompt,
      storyboard,
      composition,
      histories
    );
  }

  get type() {
    return 'general_story' as const;
  }

  get thumbnail() {
    const firstValidScene = this.storyboard?.scenes?.find(scene =>
      scene.isValid()
    );
    return firstValidScene ? firstValidScene.currentImage : undefined;
  }

  patch(data: Partial<GeneralStoryProject>) {
    const {
      id,
      version,
      authorId,
      constraint,
      size,
      language,
      style,
      promptPolicy,
      config,
      storyboard,
      composition,
      histories,
    } = Object.assign({}, this, data);
    const _prompt = mergePrompt(
      this._prompt,
      checkoutPromptType(promptPolicy),
      data
    );
    return new GeneralStoryProject(
      id,
      version,
      authorId,
      constraint,
      size,
      language,
      style,
      promptPolicy,
      config,
      _prompt,
      storyboard,
      composition,
      histories
    );
  }

  toJSON(): GeneralStoryProjectJSON {
    return {
      ...super.toJSON(),
      style: this.style,
    };
  }
}
