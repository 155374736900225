// Copyright 2025 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Ratio} from 'lib/ratio';

export type YouTubeShareProps = {
  title?: string;
  description?: string;
  tags?: string[];
  video: string;
  ratio: Ratio;
  thumbnail: string;
  isRTL: boolean;
  closeDialog: () => void;
};

export enum YouTubeShareStatus {
  public = 'public',
  private = 'private',
  unlisted = 'unlisted',
}

export type YouTubeShareMetadata = {
  snippet: {
    title: string;
    description?: string;
    tags?: string[];
  };
  status: {
    privacyStatus: YouTubeShareStatus;
  };
};
