// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {TokenResponse, useGoogleLogin} from '@react-oauth/google';
import {SUCCESS_STATUS_CODE} from 'api/BackendClient';
import {getGoogleUserInfo} from 'api/googleApi';
import {combine} from 'components/Combine';
import {useAnalysis} from 'contexts/AnalysisContext';
import {useAPI} from 'contexts/APIContext';
import {clearReferralCode, getReferralCode} from 'contexts/localStorage';
import {useNotificationContext} from 'contexts/NotificationContext';
import {useUserContext} from 'contexts/UserContext';
import {useCallback} from 'react';
import {bindInviterAndInvitee} from 'utils/rewardful';

import {GoogleButton} from './GoogleButton';
import {HookReturn, useHooksParameters} from './GoogleButton.types';

function useHook({
  setErrorToastType,
  showErrorToast,
  googleLoading,
  setGoogleLoading,
}: useHooksParameters): HookReturn {
  const {updateUserLogin} = useUserContext();
  const {showNotification} = useNotificationContext();
  const {recordEvent} = useAnalysis();

  const googleLoginHandler = useGoogleLogin({
    onSuccess: onSuccessGoogleLogin,
    onError: onErrorGoogleLogin,
    onNonOAuthError: () => setGoogleLoading(false),
  });

  const _googleLoginHandler = useCallback(() => {
    if (googleLoading) return;
    setGoogleLoading(true);
    googleLoginHandler();
  }, [googleLoading, googleLoginHandler, setGoogleLoading]);

  const {backendClient} = useAPI();

  async function onSuccessGoogleLogin(
    tokenResponse: Omit<
      TokenResponse,
      'error' | 'error_description' | 'error_uri'
    >
  ) {
    setGoogleLoading(true);
    const googleUserInfo = await getGoogleUserInfo(tokenResponse.access_token);
    const {data, code} = await backendClient.googleAccount(
      tokenResponse.access_token,
      googleUserInfo.email,
      googleUserInfo.name
    );

    if (data.newRegisteredUser === true) {
      recordEvent('Google-Signup', {userId: data.userId});
    }
    const loginWithGoogle = async function () {
      updateUserLogin(
        data.email,
        data.userName,
        data.userId,
        data.portrait,
        data.token,
        data.emailConfirmStatus
      );

      if (data.newRegisteredUser === true) {
        bindInviterAndInvitee();
        const referralCode = getReferralCode();
        if (referralCode) {
          await backendClient.recordReferralCode(referralCode);
          clearReferralCode();
        }
      }
    };
    if (data.accountMergeStatus === true) {
      setErrorToastType('mergeAccountError');
      showErrorToast(true, googleUserInfo.email, loginWithGoogle);
      return;
    }
    if (code === SUCCESS_STATUS_CODE) {
      loginWithGoogle();
    }
  }

  function onErrorGoogleLogin() {
    showNotification({
      type: 'ERROR',
      message: 'Failed to connect google, try again',
    });
    setGoogleLoading(false);
  }

  return {googleLoading, googleLoginHandler: _googleLoginHandler};
}

export const GoogleButtonContainer = combine(useHook, [
  'setErrorToastType',
  'showErrorToast',
  'googleLoading',
  'setGoogleLoading',
])(GoogleButton);
