// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Volume} from 'modules/project/utils';

import {
  HolidayGreetingProjectConfigJSON,
  ThumbnailType,
  VoiceoverSpeed,
} from '../types';
import {BaseProjectConfig} from './BaseProjectConfig';

export class HolidayGreetingProjectConfig extends BaseProjectConfig {
  readonly thumbnailIncludeVideo: boolean = false;
  readonly thumbnailType: ThumbnailType = 'Image';

  static fromJSON({
    voiceover,
    voiceover_speed,
    card_design,
    subtitle_style,
    bgm,
    overlay_effect,
    bgm_volume,
    voiceover_volume,
  }: HolidayGreetingProjectConfigJSON) {
    return new HolidayGreetingProjectConfig(
      voiceover,
      voiceover_speed ?? 'normal',
      card_design,
      overlay_effect,
      subtitle_style,
      bgm,
      bgm_volume,
      voiceover_volume
    );
  }

  constructor(
    readonly voiceover: string | null,
    readonly voiceoverSpeed: VoiceoverSpeed,
    readonly cardDesign: string | null,
    readonly overlayEffect: string | null,
    readonly subtitleStyle: string,
    bgm: string | null,
    readonly bgmVolume?: Volume,
    readonly voiceoverVolume?: Volume
  ) {
    super(bgm);
  }

  patch(data: Partial<HolidayGreetingProjectConfig>) {
    const {
      voiceover,
      voiceoverSpeed,
      cardDesign,
      overlayEffect,
      subtitleStyle,
      bgm,
      bgmVolume,
      voiceoverVolume,
    } = Object.assign({}, this, data);
    return new HolidayGreetingProjectConfig(
      voiceover,
      voiceoverSpeed,
      cardDesign,
      overlayEffect,
      subtitleStyle,
      bgm,
      bgmVolume,
      voiceoverVolume
    );
  }

  toJSON(): HolidayGreetingProjectConfigJSON {
    return {
      ...super.toJSON(),
      voiceover: this.voiceover,
      voiceover_speed: this.voiceoverSpeed,
      card_design: this.cardDesign,
      overlay_effect: this.overlayEffect,
      subtitle_style: this.subtitleStyle,
      bgm_volume: this.bgmVolume,
      voiceover_volume: this.voiceoverVolume,
    };
  }
}
