// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.
import {ReactComponent as MootionIcon} from 'assets/svg/3.0/Mootion.svg';
import {useUserContext} from 'contexts/UserContext';
import {TFunction} from 'i18next';
import {useErrorToast} from 'modules/auth/services';
import {Button} from 'pages/components/Button';
import {Toast} from 'pages/components/Toast';
import {useTranslation} from 'react-i18next';
import {Navigate, Outlet} from 'react-router-dom';
import {HOME_PAGE, WORKSPACE_PAGE_PATH} from 'utils/path';

import styles from './AuthPage.module.scss';

const ErrorToastMap = {
  mergeAccountError: 'Notice',
  cannotResetPassword: 'Oops',
};
const ErrorToastContentMap = {
  mergeAccountError: (t: TFunction<'auth', undefined>, email?: string) =>
    t(
      "We've noticed the existing account under email, confirm your email or log in with Google/Discord.",
      {email}
    ),
  cannotResetPassword: (t: TFunction<'auth', undefined>) =>
    t(
      'This email is attached with Google/Discord. Please log in with Google/Discord or set up an email account.'
    ),
};
const ErrorToastBtnTextMap = {
  mergeAccountError: 'OK',
  cannotResetPassword: 'OK',
};

export function AuthPage() {
  const {isLogin} = useUserContext();

  const toastOptions = useErrorToast();
  const {
    errorToastVisible,
    errorToastType,
    onConfirmInErrorToast,
    hideErrorToast,
    emailRef,
  } = toastOptions;
  const {t} = useTranslation('auth');

  if (isLogin) {
    return <Navigate to={WORKSPACE_PAGE_PATH} replace />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <div className={styles.header}>
          <MootionIcon
            onClick={() => {
              window.location.href = HOME_PAGE + '';
            }}
          />
        </div>
        <video
          controls
          className={styles.video}
          poster={`${process.env.REACT_APP_HOME_PAGE_MEDIA_URL}/public/video-v2/promptVideo.jpeg`}
          src={`${process.env.REACT_APP_HOME_PAGE_MEDIA_URL}/public/video-v2/prompt-video.v3.mp4`}
          autoPlay
          loop
          muted
          playsInline
          controlsList="nodownload"
        >
          <source type="video/mp4" src="" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className={styles.right}>
        <Outlet context={toastOptions} />
      </div>

      {errorToastVisible && (
        <Toast
          title={errorToastType && t(ErrorToastMap[errorToastType])}
          visible
          onOk={onConfirmInErrorToast}
          onCancel={hideErrorToast}
          showCloseBtn={false}
          btnTheme="danger"
          footer={<></>}
        >
          {errorToastType &&
            ErrorToastContentMap[errorToastType] &&
            ErrorToastContentMap[errorToastType](t, emailRef.current)}
          {errorToastType && (
            <div className={styles.errorToastFooter}>
              <Button
                type="button"
                size="large"
                onClick={onConfirmInErrorToast}
              >
                {t(ErrorToastBtnTextMap[errorToastType])}
              </Button>
            </div>
          )}
        </Toast>
      )}
    </div>
  );
}
