import {ReactComponent as CloseBtn} from 'assets/svg/3.0/CloseBtn.svg';
import {ReactComponent as UploaderIcon} from 'assets/svg/outline/UploaderIcon.svg';
import {Uploader} from 'components/Uploader';
import React, {useEffect, useRef} from 'react';
import Sortable from 'sortablejs';

import styles from './ImageUploader.module.scss';
import {ImageUploaderProps} from './ImageUploader.types';
import {Uploading} from './Uploading';

export const ImageUploader: React.FC<ImageUploaderProps> = ({
  maxQuantity,
  images,
  uploadImage,
  onDelete,
  onSort,
  onUploadOk,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const sortableRef = useRef<Sortable | null>(null);
  useEffect(() => {
    if (containerRef.current) {
      sortableRef.current = new Sortable(containerRef.current, {
        animation: 150,
        delay: 0,
        delayOnTouchOnly: false,
        touchStartThreshold: 1,
        forceFallback: true,
        removeCloneOnHide: true,
        fallbackClass: 'sortable-fallback',
        filter: '.disabled-drag',
        onMove: evt => {
          return !evt.related.classList.contains('disabled-drag');
        },
        onEnd: (evt: any) => {
          const newImages = [...images];
          const [movedItem] = newImages.splice(evt.oldIndex!, 1);
          newImages.splice(evt.newIndex!, 0, movedItem);
          onSort && onSort(newImages);
          document
            .querySelectorAll('.sortable-fallback')
            .forEach(el => el.remove());
        },
      });
    }

    return () => {
      if (sortableRef.current) {
        sortableRef.current.destroy();
      }
    };
  }, [images, onSort]);

  return (
    <div ref={containerRef} className={styles.container}>
      {images.map((imageData, idx) => (
        <div key={imageData.id} className={styles.imageItem}>
          {imageData.assetUrl ? (
            <>
              <img
                src={thumbnailCombiner(imageData.assetUrl)}
                alt=""
                className={styles.image}
                draggable={false}
                onError={e => {
                  if (imageData.blobUrl) {
                    (e.target as HTMLImageElement).src = imageData.blobUrl;
                    if (imageData.assetUrl) {
                      setTimeout(() => {
                        (e.target as HTMLImageElement).src = thumbnailCombiner(
                          imageData.assetUrl!
                        );
                      }, 3000);
                    }
                  }
                }}
              />
              <button
                className={styles.deleteButton}
                onClick={() => onDelete && onDelete(idx)}
                type="button"
              >
                <CloseBtn />
              </button>
            </>
          ) : (
            <Uploading
              key={imageData.id}
              data={imageData}
              handleOk={objectId => {
                onUploadOk({...imageData, assetUrl: objectId});
              }}
            />
          )}
        </div>
      ))}
      {new Array(maxQuantity - images.length).fill(true).map((_, index) => (
        <Uploader
          key={index}
          className={styles.imageItem + ' disabled-drag'}
          accept={['image/jpeg', 'image/png', 'image/webp']}
          uploadFileAndEditAsset={uploadImage}
          icon={<UploaderIcon />}
          maxFileLength={maxQuantity - images.length}
          isMultiple
        />
      ))}
    </div>
  );
};
export function thumbnailCombiner(path: string) {
  return `${process.env.REACT_APP_THUMBNAIL_URL?.replace(
    '/story/projects',
    ''
  )}/${path}`;
}
export function getAssetIdFromUrl(url: string) {
  return url.replace(/^https?:\/\/[^\\/]+\//, '');
}
