// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {PASSWORD_REG} from 'lib/auth';
import {useCallback, useRef, useState} from 'react';

import {ErrorToastEnumType, PasswordErrorType} from '../types';

export function usePasswordValidator() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordErrorType, setPasswordErrorType] =
    useState<PasswordErrorType | null>(null);
  const validPassword = useCallback(() => {
    const isValid = PASSWORD_REG.test(password);
    if (isValid && passwordErrorType === 'PASSWORD_INVALID') {
      setPasswordErrorType(null);
    } else if (!isValid) {
      setPasswordErrorType('PASSWORD_INVALID');
    }
    return isValid;
  }, [password, passwordErrorType]);

  const validConfirmPassword = useCallback(
    (_confirmPassword?: string) => {
      if (passwordErrorType === 'PASSWORD_INVALID') {
        return true;
      }
      const isValid = password === (_confirmPassword || confirmPassword);
      if (isValid) {
        setPasswordErrorType(null);
      } else {
        setPasswordErrorType('COMFIRM_PASSCODE_NOT_MATCH');
      }
      return isValid;
    },
    [confirmPassword, password, passwordErrorType]
  );

  const changePassword = useCallback((value: string) => {
    setPasswordErrorType(null);
    setPassword(value);
    setConfirmPassword('');
  }, []);
  const changeConfirmPassword = useCallback((value: string) => {
    setConfirmPassword(value);
    setPasswordErrorType(null);
  }, []);

  const handleSetPasswordErrorType = useCallback(
    (errorType: PasswordErrorType | null) => {
      setPasswordErrorType(errorType);
    },
    []
  );
  return {
    password,
    confirmPassword,
    passwordErrorType,
    validPassword,
    validConfirmPassword,
    changePassword,
    changeConfirmPassword,
    handleSetPasswordErrorType,
  };
}

export function useErrorToast() {
  const [errorToastVisible, setErrorToastVisible] = useState(false);
  const [errorToastType, setErrorToastType] =
    useState<ErrorToastEnumType | null>(null);
  const emailRef = useRef<string>('');
  const showErrorToastCallbackRef = useRef<unknown>(null);
  const hideErrorToast = useCallback(() => {
    setErrorToastVisible(false);
  }, []);
  const onConfirmInErrorToast = useCallback(() => {
    if (errorToastType === 'mergeAccountError') {
      //谷歌账号出现合并，点击OK，登录并跳转
      if (
        showErrorToastCallbackRef.current &&
        typeof showErrorToastCallbackRef.current === 'function'
      ) {
        showErrorToastCallbackRef.current &&
          showErrorToastCallbackRef.current();
      }
    } else if (errorToastType === 'cannotResetPassword') {
      if (
        showErrorToastCallbackRef.current &&
        typeof showErrorToastCallbackRef.current === 'function'
      ) {
        showErrorToastCallbackRef.current &&
          showErrorToastCallbackRef.current();
      }
    }
    setErrorToastVisible(false);
  }, [errorToastType]);
  const showErrorToast = useCallback(
    (visible: boolean, email: string, showErrorToastCallback?: () => void) => {
      emailRef.current = email;
      setErrorToastVisible(visible);
      if (showErrorToastCallback) {
        showErrorToastCallbackRef.current = showErrorToastCallback;
      }
    },
    []
  );
  return {
    errorToastVisible,
    errorToastType,
    setErrorToastType,
    setErrorToastVisible,
    hideErrorToast,
    onConfirmInErrorToast,
    emailRef,
    showErrorToast,
  };
}
