// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import axios from 'axios';
import {Badge} from 'contexts/UserContext';
import {LoginUserInfo} from 'modules/auth/types';
import {Doodle, DoodleType} from 'modules/doodle/types';
import {ProjectType} from 'modules/project/types';
import {fromJSON, History} from 'modules/project-history/models/History';
import {HistoryJSON} from 'modules/project-history/types';

import {APIClient} from './Client';

export interface Response {
  code: number;
  message: string | string[];
  error?: number;
}
export interface ResponseData<T> extends Response {
  data: T;
}

export const SUCCESS_STATUS_CODE = 200;

export enum ResponseCode {
  USER_NOT_FOUND = 10001,
  TOKEN_INVALID = 10004,
  EMAIL_CONFIRM_TOKEN_INVALID = 10005,
  PASSCODE_NOT_MATCH = 20001,
  REQUEST_PARAM_NOT_MATCH = 20002,
  FILE_NOT_BELONG_TO_USER = 30001,
  FILE_NOT_FOUND = 30002,
  JWT_SIGN_ERROR = 30003,
  USER_RESOURCE_INFO_CREATE_ERROR = 30004,
  USER_RESOURCE_SIZE_NOT_MATCH = 30005,
  USER_RESOURCE_STATUS_UPDATE_ERROR = 30006,
  EMAIL_HAD_REGISTER_YET = 30007,
  USER_REGISTER_ERROR = 30008,
  USER_FILE_CAN_NOT_BE_MODIFIED = 30009,
  GET_UPLOAD_FILE_SIGNED_URL_ERROR = 30010,
  USER_PROJECT_CREATE_ERROR = 30011,
  USER_PROJECT_NOT_EXIST = 30012,
  USER_ID_NOT_MATCH = 30013,
  INVITE_CODE_NOT_EXISTS = 30014,
  ASSET_NOT_FOUND = 30015,
  GETWAILLIST_EMAIL_NOT_SIGNUP = 70010,
  USER_ASSET_CAPACITY_FULL = 30033,
  USER_EMAIL_NOT_CONFIRM_YET = 30035,
  USER_EMAIL_NO_PASSWORD = 30036,
}

type ProjectObject = {
  userId: string;
  projectId: string;
  projectName: string;
  thumbnailUrl: string;
  frameRatio: string;
  projectJsonContent: string; // json string
  createTime: string;
  updateTime: string;
  history: number;
  lang: string;
};
export class BackendClient extends APIClient {
  async getProject(projectId: string, parseJson = false) {
    const {data} = await this.a.get<ResponseData<ProjectObject>>(
      `/story/projects/${projectId}`
    );
    if (parseJson) {
      return JSON.parse(data.data.projectJsonContent);
    } else {
      return data;
    }
  }

  async createProject(params: {
    frameRatio: '16:9' | '1:1' | '9:16';
    projectJsonContent: string;
  }) {
    const {data} = await this.a.post<ResponseData<string>>(
      '/story/projects',
      params
    );
    return data;
  }

  async updateProject({
    projectId,
    ...params
  }: {
    projectId: string;
    projectName?: string;
    thumbnailUrl?: string;
    frameRatio?: string;
    projectJsonContent?: string; // json string
    history?: number;
    lang?: string;
  }) {
    const {data} = await this.a.put<ResponseData<boolean>>(
      `/story/projects/${projectId}`,
      {projectId, ...params}
    );
    return data;
  }

  async getUserPreference() {
    const {data} = await this.a.get('/story/projects/preferences/mine');
    const {data: preference} = data;
    if (preference === '') return {};
    else return JSON.parse(preference);
  }

  async saveUserPreference(userPreference: Record<string, unknown>) {
    this.a.post('/story/projects/preferences', {
      preference: JSON.stringify(userPreference),
    });
  }

  async signUrlByHistoryIds(data: {projectId: string; historyIds: string[]}) {
    const {data: res} = await this.a.post<
      ResponseData<
        {
          historyId: string;
          url: {url: string; fields: Record<string, string>};
        }[]
      >
    >('/story/projects/histories', data);
    return res.data;
  }

  async getSignedUrlOfHistories(data: {
    projectId: string;
    historyIds: string[];
  }) {
    const {data: res} = await this.a.post<
      ResponseData<
        {
          historyId: string;
          url: string;
        }[]
      >
    >('/story/projects/histories/signed-urls', data);
    return res.data;
  }
  async getGalleryList(page: number, pageSize: number) {
    const {data: res} = await this.a.get<
      ResponseData<
        {
          galleryId: string;
          galleryName: string;
          thumbnailUrl: string;
          frameRatio: string;
          prompt?: string;
          lang?: string;
        }[]
      >
    >('/story/galleries', {
      params: {page, pageSize},
    });
    return res.data;
  }

  async getGalleryByCategory(category: string, page: number, pageSize: number) {
    const {data: res} = await this.a.get<
      ResponseData<
        {
          galleryId: string;
          galleryName: string;
          thumbnailUrl: string;
          frameRatio: string;
          prompt?: string;
          lang?: string;
        }[]
      >
    >('/story/galleries/categories/' + String(category).toUpperCase(), {
      params: {page, pageSize},
    });
    return res.data;
  }
  async getGalleryTags() {
    const {data: res} = await this.a.get<ResponseData<Record<string, string>>>(
      '/story/galleries/categories/tags/list'
    );
    return [
      {value: 'all', label: 'All'},
      ...Object.entries(res.data).map(([label, value]) => ({value, label})),
    ];
  }
  async uploadHistories(
    projectId: string,
    historyJSONs: Array<HistoryJSON<ProjectType>>
  ) {
    const uploadList = await this.signUrlByHistoryIds({
      projectId,
      historyIds: historyJSONs.map(item => item.id),
    });
    const uploadPromises = uploadList.map(async (uploadInfo, idx) => {
      const fileName = uploadInfo.historyId;

      const fileBlob = new Blob([JSON.stringify(historyJSONs[idx])], {
        type: 'application/json',
      });
      const formData = new FormData();

      Object.entries(uploadInfo.url.fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append('Content-Type', 'application/json');
      formData.append('file', fileBlob, fileName);

      // Upload file
      await axios.post(uploadInfo.url.url, formData, {});
    });
    await Promise.all(uploadPromises);
  }

  async uploadHistory(projectId: string, history: History<ProjectType>) {
    await this.uploadHistories(projectId, [history.toJSON()]);
  }

  async getHistoriesContent(
    projectId: string,
    historyIds: string[],
    type: ProjectType
  ) {
    if (historyIds.length === 0) {
      return [];
    }

    const signedUrlsRes = await this.getSignedUrlOfHistories({
      projectId,
      historyIds,
    });
    const fetchPromises = signedUrlsRes.map(async ({url: signedUrl}) => {
      // Fetch file content
      const response = await axios.get(signedUrl, {
        responseType: 'json', // Assuming the file content is in JSON format
      });

      return response.data;
    });

    // Fetch all file contents in parallel
    const contents = await Promise.all(fetchPromises);
    return contents.map(json => {
      return fromJSON(type, json);
    });
  }

  reportClickBanner(bannerId: number) {
    return this.a.post(`/stat/clicks/banners/${bannerId}`);
  }

  async getDoodle(): Promise<Doodle<DoodleType> | null> {
    const axiosResult = await this.a.get('/banners/doodle');
    const data = axiosResult.data.data;
    if (!data?.length) return null;
    const result = data.map((item: Doodle<DoodleType> & {content: string}) => {
      const content = item.content ? JSON.parse(item.content) : undefined;
      if (typeof content !== undefined && typeof content !== 'object') {
        throw new Error('Invalid doodle content');
      }
      const url = new URL(item.imageUrl);

      return {
        ...item,
        content,
        imageUrl: url.origin + url.pathname,
        imageStyle:
          url.searchParams.size > 0
            ? {
                width: url.searchParams.get('w') ?? undefined,
                height: url.searchParams.get('h') ?? undefined,
              }
            : undefined,
      };
    });

    return result[0] ?? null;
  }

  async getSubscriptionInfo(): Promise<{
    badges: Badge[];
    planType: 'STANDARD' | 'PRO' | 'FREE';
  }> {
    const {data} = await this.a.get('/users/dashboards/subscription-info');
    return data.data;
  }

  async updateLanguage(lang: string) {
    return this.a.patch('/users/me/lang', {lang});
  }

  login(loginData: {
    email: string;
    password: string;
  }): Promise<ResponseData<LoginUserInfo>> {
    return this.a.post('/auth/login', loginData).then(res => res.data);
  }

  doResetPasswordByEmail(data: {
    email: string;
    password: string;
    confirmPassword: string;
    token: string;
  }): Promise<ResponseData<undefined>> {
    return this.a.patch('/auth/reset-password', data).then(res => res.data);
  }

  signUp(
    signUpData: {
      email: string;
      password: string;
      confirmPassword: string;
    },
    captchaToken: string
  ): Promise<
    ResponseData<{
      email?: string;
      accountMergeStatus?: boolean;
    }>
  > {
    return this.a
      .post('/users/v2', signUpData, {
        headers: {'cf-captcha-token': captchaToken},
      })
      .then(res => res.data);
  }

  isUserCanSignup(email: string): Promise<
    ResponseData<{
      email: string;
      isAvailable: boolean;
    }>
  > {
    return this.a
      .post('/users/email-status', {email})
      .then(res => {
        return res.data.isAvailable;
      })
      .then(res => res.data);
  }

  sendEmail(email: string): Promise<ResponseData<undefined>> {
    return this.a.post('/auth/reset-password', {email});
  }

  googleAccount(
    accessToken: string,
    email: string,
    userName: string
  ): Promise<ResponseData<LoginUserInfo>> {
    return this.a
      .post('/auth/google-login', {
        accessToken,
        email,
        userName,
      })
      .then(res => res.data);
  }

  recordReferralCode(referralCode: string) {
    return this.a.post(`/invite-codes/${referralCode}/activations`);
  }

  signInYouTube(code: string) {
    return this.a.post(`/share/youtube/obtain-access-token?code=${code}`);
  }

  getYouTubeUserinfo() {
    return this.a.get('/share/youtube/access-token');
  }

  signOutYouTube() {
    return this.a.delete('/share/youtube/users');
  }
}
