// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Volume} from 'modules/project/utils';

import {
  Avatar,
  Effect,
  ShortVideoProjectConfigJSON,
  VoiceoverSpeed,
} from '../types';
import {BaseProjectConfig} from './BaseProjectConfig';

export class ShortVideoProjectConfig extends BaseProjectConfig {
  static fromJSON({
    voiceover,
    voiceover_speed,
    effect,
    title_style,
    subtitle_style,
    transition,
    bgm,
    avatar,
    bgm_volume,
    voiceover_volume,
  }: ShortVideoProjectConfigJSON) {
    return new ShortVideoProjectConfig(
      voiceover,
      voiceover_speed ?? 'fast',
      effect,
      title_style,
      subtitle_style,
      transition,
      bgm,
      avatar,
      bgm_volume,
      voiceover_volume
    );
  }

  constructor(
    readonly voiceover: string | null,
    readonly voiceoverSpeed: VoiceoverSpeed,
    readonly effect: Effect,
    readonly titleStyle: string | null,
    readonly subtitleStyle: string | null,
    readonly transition: string | null,
    bgm: string | null,
    readonly avatar?: Avatar,
    readonly bgmVolume?: Volume,
    readonly voiceoverVolume?: Volume
  ) {
    super(bgm);
  }

  patch(data: Partial<ShortVideoProjectConfig>) {
    const {
      voiceover,
      voiceoverSpeed,
      effect,
      titleStyle,
      subtitleStyle,
      transition,
      bgm,
      avatar,
      bgmVolume,
      voiceoverVolume,
    } = Object.assign({}, this, data);
    return new ShortVideoProjectConfig(
      voiceover,
      voiceoverSpeed,
      effect,
      titleStyle,
      subtitleStyle,
      transition,
      bgm,
      avatar,
      bgmVolume,
      voiceoverVolume
    );
  }

  toJSON(): ShortVideoProjectConfigJSON {
    return {
      ...super.toJSON(),
      voiceover: this.voiceover,
      voiceover_speed: this.voiceoverSpeed,
      effect: this.effect,
      title_style: this.titleStyle,
      subtitle_style: this.subtitleStyle,
      transition: this.transition,
      avatar: this.avatar,
      bgm_volume: this.bgmVolume,
      voiceover_volume: this.voiceoverVolume,
    };
  }
}
