import {AxiosInstance} from 'axios';

export function createConcurrencyManager(
  axios: AxiosInstance,
  MAX_CONCURRENT = 10
) {
  const get = axios.get.bind(axios);
  const post = axios.post.bind(axios);
  const put = axios.put.bind(axios);
  const patch = axios.patch.bind(axios);
  const del = axios.delete.bind(axios);

  const queue: (() => Promise<any>)[] = [];
  let running = 0;

  function push(fn: () => Promise<any>) {
    queue.push(fn);
    run();
  }

  function run() {
    if (running >= MAX_CONCURRENT) {
      return;
    }
    const next = queue.shift();
    if (!next) return;
    running++;
    next().finally(() => {
      running--;
      run();
    });
  }

  axios.get = (...args: Parameters<AxiosInstance['get']>) => {
    return new Promise<any>((resolve, reject) => {
      push(() => get(...args).then(resolve, reject));
    });
  };

  axios.post = (...args: Parameters<AxiosInstance['post']>) => {
    return new Promise<any>((resolve, reject) => {
      push(() => post(...args).then(resolve, reject));
    });
  };

  axios.put = (...args: Parameters<AxiosInstance['put']>) => {
    return new Promise<any>((resolve, reject) => {
      push(() => put(...args).then(resolve, reject));
    });
  };

  axios.patch = (...args: Parameters<AxiosInstance['patch']>) => {
    return new Promise<any>((resolve, reject) => {
      push(() => patch(...args).then(resolve, reject));
    });
  };

  axios.delete = (...args: Parameters<AxiosInstance['delete']>) => {
    return new Promise<any>((resolve, reject) => {
      push(() => del(...args).then(resolve, reject));
    });
  };

  return axios;
}
