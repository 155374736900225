// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useLexicalComposerContext} from '@lexical/react/LexicalComposerContext';
import {BLUR_COMMAND, COMMAND_PRIORITY_EDITOR} from 'lexical';
import {useEffect} from 'react';

export function BlurPlugin({onBlur}: {onBlur: () => void}) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    const removeFocusListener = editor.registerCommand(
      BLUR_COMMAND,
      () => {
        onBlur();
        return false;
      },
      COMMAND_PRIORITY_EDITOR
    );

    return removeFocusListener;
  }, [editor, onBlur]);

  return null;
}
