// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as DownloadIcon} from 'assets/svg/3.0/Download.svg';
import {ReactComponent as ShareIcon} from 'assets/svg/3.0/share.svg';
import {ReactComponent as YouTubeIcon} from 'assets/svg/fill/YouTube.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {PreviewBox} from 'components/PreviewBox';
import {VideoDownloadToast} from 'components/VideoDownloadToast';
import {YouTubeShareDialog} from 'components/YouTubeShare';
import {useAnalysis} from 'contexts/AnalysisContext';
import {useResourceManager} from 'contexts/ResourceManager';
import {download} from 'lib/download';
import {downloadStoryboard} from 'modules/project/utils';
import {Button} from 'pages/components/Button';
import {
  ReactEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {useTranslation} from 'react-i18next';
import {isRtl} from 'utils/is-rtl';
import {PaymentCallbackEnum} from 'utils/stripe';
import {useClickOutside} from 'utils/use-click-outside';

import {DetailBox} from '../DetailBox';
import styles from './ResultCard.module.scss';
import {Props} from './ResultCard.types';

export function ResultCard({
  thumbnail,
  isDisabled,
  outTradeNo,
  ratio,
  storyboard,
  historyDialogShowing,
  showDownloadToast,
  downloadToastVisible,
  assetProduct,
  projectId,
  refreshAssetUrl,
  onCloseDownloadToast,
  showDownloadDialog,
  ...props
}: Props) {
  const {t} = useTranslation('project');
  const {getLanguages} = useResourceManager();
  const [duration, setDuration] = useState<number | null>(null);
  const {recordEvent} = useAnalysis();
  const [storyboardDownloading, setStoryboardDownloading] = useState(false);
  const [showDownloadPopover, setShowDownloadPopover] = useState(false);
  const [showDownloadButtons, setShowDownloadButtons] = useState(false);
  const [showSharePopover, setShowSharePopover] = useState(false);
  const [showShareButtons, setShowShareButtons] = useState(false);
  const [showYouTubeShare, setShowYouTubeShare] = useState(false);

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const buttonBoxRef = useRef<HTMLDivElement | null>(null);
  const onVideoLoaded = useCallback<ReactEventHandler<HTMLVideoElement>>(e => {
    const element = e.currentTarget;
    videoRef.current = element;
    setDuration(element.duration);
  }, []);

  const onDownloadVideo = useCallback(() => {
    download(props.video as string, props.title);
    recordEvent('Download-Video');
  }, [props.title, props.video, recordEvent]);

  useEffect(() => {
    const video = videoRef.current;
    // 如果视频正在播放，打开历史对话框时暂停视频
    if (video && historyDialogShowing && video.paused === false) {
      video.pause();
    }
  }, [historyDialogShowing]);

  useClickOutside(buttonBoxRef, () => {
    setShowDownloadButtons(false);
    setShowShareButtons(false);
  });

  const sharePopoverContent = (
    <div className={styles.sharePopoverContent}>
      <div
        className={styles.item}
        onClick={() => {
          setShowSharePopover(false);
          setShowShareButtons(false);
          setShowYouTubeShare(true);
        }}
      >
        <div className={styles.icon}>
          <YouTubeIcon />
        </div>
        <div className={styles.text}>YouTube /Shorts</div>
      </div>
      <div className={classNames(styles.item, styles.disabled)}>
        <div className={styles.icon}></div>
        <div className={styles.text}>{t('Coming soon')}</div>
      </div>
      <div className={classNames(styles.item, styles.disabled)}>
        <div className={styles.icon}></div>
        <div className={styles.text}>{t('Coming soon')}</div>
      </div>
    </div>
  );

  const renderMobileButtons = () => {
    if (showShareButtons) {
      return (
        <div className={styles.mobileShareButtons}>
          <div className={classNames(styles.buttonBoxHeader, styles.xsShow)}>
            {t('Share')}
          </div>
          {sharePopoverContent}
        </div>
      );
    }
    if (showDownloadButtons) {
      return (
        <>
          <div className={classNames(styles.buttonBoxHeader, styles.xsShow)}>
            {t('Download')}
          </div>
          <Button
            className={classNames(styles.button, styles.xsShow)}
            theme="primary"
            size="middle"
            type="ghost"
            disabled={isDisabled || !props.video}
            onClick={() => {
              setShowDownloadButtons(false);
              if (!showDownloadToast) {
                onDownloadVideo();
                return;
              }
              showDownloadDialog();
            }}
            icon={<DownloadIcon className={styles.icon} />}
          >
            {t('Video(MP4)')}
          </Button>
          <Button
            className={classNames(styles.button, styles.xsShow)}
            size="middle"
            type="ghost"
            disabled={isDisabled || storyboard === undefined}
            onClick={async () => {
              setShowDownloadButtons(false);
              if (!storyboard) return;
              setStoryboardDownloading(true);

              try {
                await downloadStoryboard(
                  {
                    scenes: storyboard.scenes || [],
                    title: storyboard.title || '',
                    description: storyboard.description || '',
                    hashtags: storyboard.hashtags || [],
                    prompt: storyboard.promptPolicy?.isIdeaPrompt
                      ? storyboard.prompt
                      : '',
                  },
                  {
                    summary: t('Summary'),
                    hashtags: t('Hashtags'),
                    script: t('Script'),
                    prompt: t('Prompt'),
                  }
                );
              } catch (error) {
                console.error(error);
              }

              setStoryboardDownloading(false);
            }}
            icon={<DownloadIcon className={styles.icon} />}
            isLoading={storyboardDownloading}
          >
            {t('Storyboard')}
          </Button>
          <Button
            className={classNames(styles.button, styles.xsShow)}
            disabled={isDisabled}
            size="middle"
            type="ghost"
            onClick={() => {
              setShowDownloadButtons(false);
              download(thumbnail, props.title);
            }}
            icon={<DownloadIcon className={styles.icon} />}
          >
            {t('Thumbnail(JPG)')}
          </Button>
        </>
      );
    }
    return (
      <>
        <Button
          className={classNames(styles.button, styles.xsShow)}
          size="large"
          disabled={isDisabled}
          icon={<DownloadIcon className={styles.icon} />}
          onClick={() => setShowDownloadButtons(true)}
        >
          {t('Download')}
        </Button>
        <Button
          className={classNames(styles.button, styles.xsShow)}
          size="large"
          disabled={isDisabled}
          icon={<ShareIcon className={styles.icon} />}
          onClick={() => setShowShareButtons(true)}
        >
          {t('Share')}
        </Button>
      </>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <div className={styles['preview-box']}>
          <PreviewBox
            preview={isDisabled ? '' : props.video ?? ''}
            ratio={ratio}
            type={props.video ? 'Video' : 'Image'}
            loading={isDisabled}
            onLoad={onVideoLoaded}
          />
        </div>
        <DetailBox
          {...{...props, isDisabled, storyboard}}
          duration={duration}
        />
      </div>
      <div className={styles['button-box']} ref={buttonBoxRef}>
        <Popover
          className={styles.xsHide}
          visible={showDownloadPopover}
          onVisibleChange={setShowDownloadPopover}
          direction="bottom"
          triggerElement={
            <Button
              className={classNames(styles.button, styles.footerButton)}
              size="large"
              disabled={isDisabled}
              icon={<DownloadIcon className={styles.icon} />}
            >
              {t('Download')}
            </Button>
          }
          popoverClassName={styles.downloadPopover}
          content={
            <>
              <Button
                className={styles.textButton}
                theme="primary"
                size="middle"
                type="ghost"
                disabled={isDisabled || !props.video}
                onClick={() => {
                  setShowDownloadPopover(false);
                  if (!showDownloadToast) {
                    onDownloadVideo();
                    return;
                  }
                  showDownloadDialog();
                }}
                icon={<DownloadIcon className={styles.icon} />}
              >
                {t('Video(MP4)')}
              </Button>
              <Button
                className={styles.textButton}
                disabled={isDisabled}
                size="middle"
                type="ghost"
                onClick={() => {
                  setShowDownloadPopover(false);
                  download(thumbnail, props.title);
                }}
                icon={<DownloadIcon className={styles.icon} />}
              >
                {t('Thumbnail(JPG)')}
              </Button>
              <Button
                className={styles.textButton}
                size="middle"
                type="ghost"
                disabled={isDisabled || storyboard === undefined}
                onClick={async () => {
                  setShowDownloadPopover(false);
                  if (!storyboard) return;
                  setStoryboardDownloading(true);

                  try {
                    await downloadStoryboard(
                      {
                        scenes: storyboard.scenes || [],
                        title: storyboard.title || '',
                        description: storyboard.description || '',
                        hashtags: storyboard.hashtags || [],
                        prompt: storyboard.promptPolicy?.isIdeaPrompt
                          ? storyboard.prompt
                          : '',
                      },
                      {
                        summary: t('Summary'),
                        hashtags: t('Hashtags'),
                        script: t('Script'),
                        prompt: t('Prompt'),
                      }
                    );
                  } catch (error) {
                    console.error(error);
                  }

                  setStoryboardDownloading(false);
                }}
                icon={<DownloadIcon className={styles.icon} />}
                isLoading={storyboardDownloading}
              >
                {t('Storyboard')}
              </Button>
            </>
          }
          distance={17}
        />
        <Popover
          className={styles.xsHide}
          triggerElement={
            <Button
              className={classNames(styles.button, styles.footerButton)}
              size="large"
              disabled={isDisabled}
              icon={<ShareIcon className={styles.icon} />}
            >
              {t('Share')}
            </Button>
          }
          popoverClassName={styles.sharePopover}
          content={sharePopoverContent}
          visible={showSharePopover}
          onVisibleChange={setShowSharePopover}
          direction="bottom"
          align="right"
          distance={12}
        />
        {renderMobileButtons()}
      </div>
      {assetProduct && showDownloadToast && (
        <VideoDownloadToast
          videoId={assetProduct}
          outTradeNo={outTradeNo}
          projectId={projectId}
          type={PaymentCallbackEnum.CompositePage}
          isVisible={downloadToastVisible}
          refreshAssetUrl={refreshAssetUrl}
          onDownload={() => {
            onDownloadVideo();
            onCloseDownloadToast();
          }}
          onCancel={onCloseDownloadToast}
        />
      )}
      {showYouTubeShare && (
        <YouTubeShareDialog
          isRTL={isRtl(getLanguages(), props.languageCode)}
          closeDialog={() => setShowYouTubeShare(false)}
          dialogShowing
          onCloseDialog={() => setShowYouTubeShare(false)}
          video={props.video!}
          ratio={ratio}
          thumbnail={thumbnail}
          title={props.title}
          description={props.summary}
          tags={props.hashtags}
        />
      )}
    </div>
  );
}
