// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as DropdownIcon} from 'assets/svg/3.0/Dropdown.svg';
import classNames from 'classnames';
import {Popover} from 'components/AriaPopover';

import styles from './Select.module.scss';
import {Props} from './Select.types';

export function Select({
  icon,
  children,
  isOpen,
  value,
  onVisibleChange,
  popoverClassName,
}: Props) {
  return (
    <Popover
      triggerElement={
        <div
          className={classNames(
            styles.container,
            isOpen && styles.active,
            'select-item'
          )}
        >
          <span className={styles.select}>
            <span className={styles['value-wrap']}>
              {icon && <span className={styles.icon}>{icon}</span>}
              {value}
            </span>
            <DropdownIcon className={styles.dropdown} />
          </span>
        </div>
      }
      isOpen={isOpen}
      onOpenChange={onVisibleChange}
      className={popoverClassName}
      placement="bottom"
      popupMatchSelectWidth
    >
      {children}
    </Popover>
  );
}
