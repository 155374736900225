// Copyright 2025 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {
  EditorConfig,
  LexicalEditor,
  LexicalNode,
  SerializedTextNode,
  TextNode,
} from 'lexical';

type SerializedErrorTextNode = SerializedTextNode;

export class ErrorTextNode extends TextNode {
  static getType(): string {
    return 'error-text';
  }

  static clone(node: ErrorTextNode): ErrorTextNode {
    return new ErrorTextNode(node.__text);
  }

  constructor(text: string, key?: string) {
    super(text, key);
  }

  createDOM(
    config: EditorConfig,
    _editor?: LexicalEditor | undefined
  ): HTMLElement {
    const span = document.createElement('span');
    const theme = config.theme;
    span.classList.add(theme.errorTextNode.base);
    span.textContent = this.__text;
    return span;
  }

  static importJSON(serializedNode: SerializedErrorTextNode): ErrorTextNode {
    return new ErrorTextNode(serializedNode.text);
  }

  exportJSON(): SerializedErrorTextNode {
    return {
      ...super.exportJSON(),
      type: ErrorTextNode.getType(),
    };
  }
}

export function $createErrorText(text: string): ErrorTextNode {
  return new ErrorTextNode(text);
}

export function $isErrorTextNode(
  node: LexicalNode | null | undefined
): node is ErrorTextNode {
  return node instanceof ErrorTextNode;
}
