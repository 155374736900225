// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as LoadingSvg} from 'assets/svg/3.0/loading-rotate.svg';
import {ReactComponent as RightArrowIcon} from 'assets/svg/3.0/RightArrow3.svg';
import {HistoryPreviewerDialog} from 'components/HistoryPreviewer';
import {Loading} from 'components/Loading';
import {TagGroup} from 'components/TagGroup/TagGroup';
import {noop} from 'lodash';
import {BlankData} from 'pages/components/BlankData';
import {Button} from 'pages/components/Button';
import {LoadingPromptDialog} from 'pages/components/LoadingPrompt';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {generateIdPath, PROJECT_PATH} from 'utils/path';
import {
  HISTORY_ID_QUERY_KEY,
  OUT_TRADE_NO_QUERY_KEY,
  PAYMENT_STATUS_QUERY_KEY,
  PaymentCallbackEnum,
  PaymentStatusEnum,
  PROJECT_ID_QUERY_KEY,
} from 'utils/stripe';

import {GalleryPreviewerDialog} from '../GalleryPreviewer';
import {ProjectItem} from '../ProjectItem';
import {
  isGalleryItemType,
  isProjectItemType,
  ProjectItemType,
} from '../WorkspacePage.types';
import styles from './ProjectOrGallery.module.scss';
import {ProjectOrGalleryHookReturnBaseType} from './ProjectOrGallery.types';

export function ProjectOrGallery({
  projectOrGalleryItemList,
  pageType,
  isLoading,
  currentGalleryId,
  galleryTagList,
  hasMore,
  isGalleryFetchLoading,
  isRefreshFromFirstPage,
  loadMoreRef,
  onClickDuplicateBtn,
  setCurrentGalleryId,
  onclickProjectOrGalleryItem,
  refreshProjectList,
  onUseGallery,
  useGalleryWithGalleryId,
  refreshAfterDelete,
  changeTag,
  activeTag,
}: ProjectOrGalleryHookReturnBaseType) {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const outTradeNo = params.get(OUT_TRADE_NO_QUERY_KEY) ?? undefined;
  const paymentStatus = params.get(PAYMENT_STATUS_QUERY_KEY) ?? undefined;
  const historyId = params.get(HISTORY_ID_QUERY_KEY) ?? undefined;
  const projectId = params.get(PROJECT_ID_QUERY_KEY) ?? undefined;

  const [historyProjectId, setHistoryProjectId] = useState<string | null>(
    projectId ?? null
  );

  const {t} = useTranslation('workspace');
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {pageType === 'gallery' ? t('Gallery') : t('My projects')}
      </h1>
      {pageType === 'gallery' && galleryTagList && (
        <TagGroup
          tags={galleryTagList}
          changeTag={changeTag}
          className={styles['tag-list']}
          currentTag={activeTag}
        />
      )}
      {projectOrGalleryItemList &&
        projectOrGalleryItemList.length === 0 &&
        !isRefreshFromFirstPage && (
          <BlankData
            quotation={t(
              'I DON’T DREAM AT NIGHT, I DREAM AT DAY, I DREAM ALL DAY; I’M DREAMING FOR A LIVING.'
            )}
            quotationAuthor={t('Steven Spielberg')}
            footerMsg={t('You have no projects yet')}
            footerAction={
              <Button
                type="link"
                onClick={() =>
                  navigate(
                    `${generateIdPath(
                      PROJECT_PATH,
                      'new'
                    )}?type=general_story&prompt_type=idea`
                  )
                }
              >
                {t('Create')} <RightArrowIcon />
              </Button>
            }
            className={styles.blankDataSection}
          />
        )}
      {isRefreshFromFirstPage && (
        <div className={styles.projectGrid}>
          <Loading />
        </div>
      )}
      {projectOrGalleryItemList && projectOrGalleryItemList.length > 0 && (
        <div className={styles.projectGrid}>
          {!isRefreshFromFirstPage &&
            projectOrGalleryItemList.map(project => (
              <ProjectItem
                key={
                  (isProjectItemType(project) && project.projectId) ||
                  ((isGalleryItemType(project) && project.galleryId) as string)
                }
                onClickDuplicateBtn={onClickDuplicateBtn}
                project={project}
                pageType={pageType}
                onClickUseBtn={useGalleryWithGalleryId}
                onclickProjectOrGalleryItem={onclickProjectOrGalleryItem}
                onclickHistoryBtn={() => {
                  setHistoryProjectId((project as ProjectItemType).projectId);
                }}
                refreshProjectList={refreshProjectList}
                refreshAfterDelete={refreshAfterDelete}
              />
            ))}

          {hasMore && !isGalleryFetchLoading && (
            <LoadingSvg
              ref={loadMoreRef}
              className={styles.rotateLoading + ' loadMore'}
            />
          )}
        </div>
      )}
      {historyProjectId && (
        <HistoryPreviewerDialog
          projectId={historyProjectId}
          dialogShowing={historyProjectId !== null}
          onCloseDialog={() => setHistoryProjectId(null)}
          type={PaymentCallbackEnum.WorkspacePageHistory}
          onClearOutTradeNo={() => navigate(location.pathname, {replace: true})}
          defaultSelectedHistoryId={historyId}
          outTradeNo={outTradeNo}
          paymentStatus={paymentStatus as PaymentStatusEnum | undefined}
        />
      )}
      <LoadingPromptDialog
        dialogShowing={typeof isLoading === 'boolean' && isLoading}
        type="processing"
        onCloseDialog={noop}
      />
      {currentGalleryId && setCurrentGalleryId && onUseGallery && (
        <GalleryPreviewerDialog
          onCloseDialog={() => setCurrentGalleryId(null)}
          dialogShowing={currentGalleryId !== null}
          galleryId={currentGalleryId}
          onUseGallery={onUseGallery}
        />
      )}
    </div>
  );
}
