// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

export const enum ImageBarActionTypes {
  crop = 'crop',
  rmBg = 'rmBg',
  styleTransfer = 'styleTransfer',
  delete = 'delete',
}
export interface ImageBarProps {
  onAction: (type: ImageBarActionTypes) => void;
  triggerRef: React.RefObject<Element>;
}
