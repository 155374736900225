// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {AssetsLibrary} from 'components/AssetsLibrary';
import {
  CheckEmailForm,
  ConfirmEmailForm,
  DoResetPasswordForm,
  LoginForm,
  ResetPasswordForm,
  SignupForm,
} from 'components/AuthForm';
import {AuthPage} from 'components/AuthPage';
import {ErrorCatcher} from 'components/ErrorCatcher';
import {GeneratingPage} from 'components/GeneratingPage';
import {PreProjectPage} from 'components/PreProjectPage';
import {
  projectLoader,
  ProjectPage,
  ProjectPageLoaderError,
} from 'components/ProjectPage';
import {ProjectLoading} from 'components/ProjectPage/ProjectLoading';
import {TikTokCallback} from 'components/TikTokCallback';
import {useAPI} from 'contexts/APIContext';
import {useResourceManager} from 'contexts/ResourceManager';
import {useUserContext} from 'contexts/UserContext';
import {Firewall} from 'pages/components/Firewall';
import {DiscordCallbackPage} from 'pages/DiscordCallbackPage';
import {ReferralPage} from 'pages/ReferralPage';
import {Workspace} from 'pages/WorkspacePage';
import {Generate} from 'pages/WorkspacePage/Generate';
import {PaymentResult, Plan} from 'pages/WorkspacePage/Plan';
import {Gallery, MyProject} from 'pages/WorkspacePage/ProjectOrGallery';
import {useEffect, useMemo, useRef} from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
} from 'react-router-dom';
import {
  AUTH_PATH,
  CHECK_EMAIL_PATH,
  CONFIRM_EMAIL_PATH,
  DISCORD_CALLBACK_PATH,
  GENERATING_PATH,
  LOGIN_PATH,
  PAYMENT_CANCEL_PATH,
  PAYMENT_SUCCESS_PATH,
  PLAN_PAGE_PATH,
  PROJECT_PATH,
  PROMPT_PROJECT_PATH,
  REFERRAL_PATH,
  RESET_PASSWORD_PATH,
  RESET_PATH,
  ROOT_PATH,
  SIGNUP_PATH,
  TIKTOKCALLBACK_PATH,
  WORKSPACE_PAGE_ASSETS_PATH,
  WORKSPACE_PAGE_GALLERY_PATH,
  WORKSPACE_PAGE_GENERATE_PATH,
  WORKSPACE_PAGE_MYPROJECT_PATH,
  WORKSPACE_PAGE_PATH,
} from 'utils/path';

import {AnalysisProvider} from './contexts/AnalysisContext';
import {ErrorPage} from './pages/ErrorPage';

export function Router() {
  const {backendClient} = useAPI();
  const resourceManager = useResourceManager();
  const {userInfo, checkoutPreference} = useUserContext();
  const resourceManagerRef = useRef(resourceManager);
  const userInfoRef = useRef(userInfo);
  const checkoutPreferenceRef = useRef(checkoutPreference);

  useEffect(() => {
    resourceManagerRef.current = resourceManager;
  }, [resourceManager]);

  useEffect(() => {
    userInfoRef.current = userInfo;
  }, [userInfo]);

  useEffect(() => {
    checkoutPreferenceRef.current = checkoutPreference;
  }, [checkoutPreference]);

  const router = useMemo(
    () =>
      !resourceManager.isResLoaded
        ? null
        : createBrowserRouter([
            {
              path: ROOT_PATH,
              element: (
                <AnalysisProvider>
                  <ErrorCatcher>
                    <Outlet />
                  </ErrorCatcher>
                </AnalysisProvider>
              ),
              errorElement: <ErrorPage />,
              children: [
                {
                  path: ROOT_PATH,
                  element: (
                    <Firewall>
                      <Outlet />
                    </Firewall>
                  ),
                  children: [
                    {
                      index: true,
                      element: <Navigate to={WORKSPACE_PAGE_PATH} />,
                    },
                    {
                      path: WORKSPACE_PAGE_PATH,
                      element: <Workspace />,
                      children: [
                        {
                          index: true,
                          element: (
                            <Navigate to={WORKSPACE_PAGE_GENERATE_PATH} />
                          ),
                        },
                        {
                          path: WORKSPACE_PAGE_GENERATE_PATH,
                          element: <Generate />,
                        },
                        {
                          path: WORKSPACE_PAGE_ASSETS_PATH,
                          element: <AssetsLibrary />,
                        },
                        {
                          path: WORKSPACE_PAGE_MYPROJECT_PATH,
                          element: <MyProject />,
                        },
                        {
                          path: WORKSPACE_PAGE_GALLERY_PATH,
                          element: <Gallery />,
                        },
                        {
                          path: PLAN_PAGE_PATH,
                          element: <Plan />,
                          children: [
                            {
                              path: PAYMENT_SUCCESS_PATH,
                              element: <PaymentResult status="SUCCESS" />,
                            },
                            {
                              path: PAYMENT_CANCEL_PATH,
                              element: <PaymentResult status="FAILED" />,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: PROJECT_PATH,
                      errorElement: <ProjectPageLoaderError />,
                      element: (
                        <ProjectPage suspenseFallback={<ProjectLoading />} />
                      ),
                      loader: args =>
                        projectLoader(
                          args,
                          backendClient,
                          resourceManagerRef.current,
                          userInfoRef.current,
                          checkoutPreferenceRef.current
                        ),
                    },
                  ],
                },
                {
                  path: PROMPT_PROJECT_PATH,
                  element: <PreProjectPage />,
                },
                {
                  path: GENERATING_PATH,
                  element: <GeneratingPage />,
                },
                {
                  path: AUTH_PATH,
                  element: <AuthPage />,
                  children: [
                    {
                      index: true,
                      element: <Navigate to={LOGIN_PATH} />,
                    },
                    {
                      path: LOGIN_PATH,
                      element: <LoginForm />,
                    },
                    {
                      path: SIGNUP_PATH,
                      element: <SignupForm />,
                    },
                    {
                      path: RESET_PASSWORD_PATH,
                      element: <ResetPasswordForm />,
                    },
                    {
                      path: RESET_PATH,
                      element: <DoResetPasswordForm />,
                    },
                    {
                      path: CHECK_EMAIL_PATH,
                      element: <CheckEmailForm />,
                    },
                    {
                      path: CONFIRM_EMAIL_PATH,
                      element: <ConfirmEmailForm />,
                    },
                  ],
                },
                {
                  path: DISCORD_CALLBACK_PATH,
                  element: <DiscordCallbackPage />,
                },
                {
                  path: REFERRAL_PATH,
                  element: <ReferralPage />,
                },
                {
                  path: TIKTOKCALLBACK_PATH,
                  element: <TikTokCallback />,
                },
                {
                  path: '*',
                  element: <Navigate to={WORKSPACE_PAGE_GENERATE_PATH} />,
                },
              ],
            },
          ]),
    [backendClient, resourceManager.isResLoaded]
  );

  if (!router) {
    return null;
  }

  return <RouterProvider router={router} />;
}
