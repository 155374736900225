// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {Effect} from 'api/server';
import {Size, Tone} from 'modules/project/types';
import {Volume} from 'modules/project/utils';
import {Avatar, ThumbnailType} from 'modules/project-config/types';

import {BasePreference} from './BasePreference';

export class GeneralStoryPreference extends BasePreference {
  static fromJSON(json: Record<string, any>): GeneralStoryPreference {
    return new GeneralStoryPreference(
      json.size,
      json.language,
      json.style,
      json.paragraphAsShots,
      json.quickPace,
      json.tone,
      json.titleStyle,
      json.subtitleStyle,
      json.effect,
      json.transition,
      json.thumbnailType,
      json.thumbnailIncludeVideo,
      json.bgm,
      json.voiceover,
      json.voiceoverSpeed,
      json.avatar,
      json.bgmVolume,
      json.voiceoverVolume
    );
  }
  constructor(
    size?: Size,
    language?: string,
    readonly style?: string,
    readonly paragraphAsShots?: boolean,
    readonly quickPace?: boolean,
    readonly tone?: Tone,
    readonly titleStyle?: string,
    readonly subtitleStyle?: string,
    readonly effect?: Effect,
    readonly transition?: string,
    readonly thumbnailType?: ThumbnailType,
    readonly thumbnailIncludeVideo?: boolean,
    readonly bgm?: string,
    readonly voiceover?: string,
    readonly voiceoverSpeed?: 'normal' | 'fast',
    readonly avatar?: Avatar,
    readonly bgmVolume?: Volume,
    readonly voiceoverVolume?: Volume
  ) {
    super(size, language);
  }
  patch(data: Partial<GeneralStoryPreference>): GeneralStoryPreference {
    const {
      size,
      language,
      style,
      paragraphAsShots,
      quickPace,
      tone,
      titleStyle,
      subtitleStyle,
      effect,
      transition,
      thumbnailType,
      thumbnailIncludeVideo,
      bgm,
      voiceover,
      voiceoverSpeed,
      avatar,
      bgmVolume,
      voiceoverVolume,
    } = Object.assign({}, this, data);
    return new GeneralStoryPreference(
      size,
      language,
      style,
      paragraphAsShots,
      quickPace,
      tone,
      titleStyle,
      subtitleStyle,
      effect,
      transition,
      thumbnailType,
      thumbnailIncludeVideo,
      bgm,
      voiceover,
      voiceoverSpeed,
      avatar,
      bgmVolume,
      voiceoverVolume
    );
  }
}
