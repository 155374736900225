// Copyright 2025 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as VolumeIcon} from 'assets/svg/3.0/Volume.svg';
import classNames from 'classnames';
import {Popover} from 'components/PopoverNew';
import {Volume} from 'modules/project/utils';
import {useTranslation} from 'react-i18next';

import styles from './VolumeSelect.module.scss';

export function VolumeSelect({
  value,
  onChange,
}: {
  value?: Volume;
  onChange: (value: Volume) => void;
}) {
  const {t} = useTranslation('project');

  const items = [
    {label: t('Low'), value: Volume.Low},
    {label: t('Medium'), value: Volume.Medium},
    {label: t('High'), value: Volume.High},
  ];

  return (
    <div className={styles.container}>
      <Popover
        trigger={['hover']}
        triggerElement={<VolumeIcon className={styles.icon} />}
        popoverClassName={styles.tooltip}
        content={t('Volume')}
        distance={10}
      />
      <div className={styles.group}>
        {items.map(item => (
          <div
            key={item.value}
            className={classNames(styles.item, {
              [styles.active]: item.value === value,
            })}
            onClick={() => onChange(item.value)}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
}
