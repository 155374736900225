// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {EmailErrorType} from 'modules/auth/types';
import {useTranslation} from 'react-i18next';

import {ErrorMessageProps} from './AuthForm.types';

export function EmailErrorMessage({
  errorType,
  className,
}: ErrorMessageProps<EmailErrorType>) {
  const {t} = useTranslation('auth');

  switch (errorType) {
    case 'USER_NOT_FOUND':
      return (
        <p key="USER_NOT_FOUND" className={className}>
          {t('We could not find an account with the email.')}
        </p>
      );
    case 'EMAIL_HAD_REGISTER_YET':
      return (
        <p key="EMAIL_HAD_REGISTER_YET" className={className}>
          {t('This email is taken, try log in.')}
        </p>
      );
    case 'EMAIL_INVALID':
      return (
        <p key="EMAIL_INVALID" className={className}>
          {t('Please enter a valid email.')}
        </p>
      );
    default:
      return null;
  }
}
