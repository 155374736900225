// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {AxiosResponse} from 'axios';
import {retry} from 'lib/retry';
import {Volume} from 'modules/project/utils';

import {axiosFactory} from './axiosFactory';
export type AllCheckTaskStatusResponse =
  | CheckStoryboardProcessingResponse
  | CheckStoryboardSuccessResponse
  | CheckStoryboardFailedResponse
  | GetTaskStatusOfPoseImageTaskResponse;
export enum FrontErrorEnum {
  UNKNOWN = 10000,
  INTERNAL_ERROR = 10001,
  TIMEOUT = 10002,
  FAILED_TO_SAVE_THE_FILE_TO_S3 = 10003,
  FAILED_TO_SEND_A_SCRIPT_REQUEST = 10101,
  SCRIPT_EXECUTION_FAILURE = 10102,
  THE_SCRIPT_RESULT_FORMAT_IS_INCORRECT = 10103,
  THE_PROMPT_EXCEEDS_THE_MAXIMUM_LENGTH = 10104,
  THE_PROMPT_CONTAINS_VIOLATIVE_CONTENT = 10105,
  FAILED_TO_SEND_A_COMFY_REQUEST = 10201,
  COMFY_EXECUTION_FAILURE = 10202,
  FAILED_TO_SEND_A_MOTION_REQUEST = 10301,
  THE_MOTION_RESULT_FORMAT_IS_INCORRECT = 10302,
  FAILED_TO_SEND_A_TTS_REQUEST = 10401,
  TTS_EXECUTION_FAILURE = 10402,
  FAILED_TO_SEND_A_SYNTHESIS_REQUEST = 10501,
  SYNTHESIS_EXECUTION_FAILURE = 10502,
  STORYBOARD_NOT_ALL_CHARACTERS_ARE_SUCCESS = 10601,
  STORYBOARD_NOT_ANY_SCENE_ARE_SUCCESS = 10602,
  STORYBOARD_EMPTY_IMAGE_PROMPT_IS_NOT_SUPPORTED = 10603,
  SHOOTING_NOT_ALL_SCENES_ARE_SUCCESS = 10701,
}

export function staticCombiner(path: string) {
  return `${process.env.REACT_APP_ASSETS_URL}/${path}`;
}

export const api = axiosFactory({
  baseURL: process.env.REACT_APP_FRONTEND_URL as string,
});

type TaskStatus = 'created' | 'pending' | 'processing' | 'success' | 'failure';

export type Size = [number, number];

interface UserInput {
  prompt: string;
  language: string;
  keep_user_content: boolean;
  paragraph_as_shots: boolean;
  tone: string;
  tone_other?: string;
}

interface Character {
  id: string;
  name: string;
  description: string;
  image: string;
  status: TaskStatus;
  progress: null;
  character_type: number;
}

export interface Progress {
  total: number;
  current: number;
}

export interface Scene {
  id: string;
  type: string;
  prompt: string;
  abstract: null;
  subtitle: string;
  status: TaskStatus;
  progress: Progress;
  video: null;
  shot_type: number;
  image: string;
  characters?: {name: string; prompt?: string}[];
  tts: null;
}

interface CreateStoreLinesParams {
  size: Size;
  style: string;
  user_input: UserInput;
}

interface CheckStoryboardBaseResponse {
  id: string;
  size: Size;
  style: string;
  estimated_remaining_time: number | null;
  script: {
    id: string;
    model: string;
    user_input: UserInput;
    status: TaskStatus;
    progress: Progress;
  };
  status: TaskStatus;
  progress: Progress | null;
}

interface FailureReason {
  code: FrontErrorEnum;
  message: string;
}

export interface CheckStoryboardProcessingResponse
  extends CheckStoryboardBaseResponse {
  status: Exclude<TaskStatus, 'success' | 'failure'>;
  script_full_text: null;
  title: null;
  abstract: null;
  characters: null;
  scenes: Scene[] | null;
  progress: Progress | null;
}

export interface CheckStoryboardSuccessResponse
  extends CheckStoryboardBaseResponse {
  script_full_text: string;
  title: string;
  abstract: string;
  characters: Character[];
  scenes: Scene[];
  status: 'success';
  progress: Progress | null;
  hashtags: string[];
}

export type CheckStoryboardFailedResponse = CheckStoryboardBaseResponse & {
  failure_reason: FailureReason;
} & {status: 'failure'};

export type Margin = [number, number];

interface TextConfig {
  style: {[key: string]: unknown};
}

export type FrontendEffect =
  | undefined
  | string
  | {
      type: string;
      max_zoom_ratio: number;
    };

interface VideoConfig {
  size: Size;
  effect: FrontendEffect;
  title?: TextConfig;
  subtitle?: TextConfig & {line_length?: number};
  use_ending_effect: boolean;
  transition?: {type: string};
  include_thumbnail: boolean;
  bgm?: {
    audio: string;
    volume: Volume;
  };
}

interface VoiceConfig {
  type: string;
  mute?: true;
  agent?: string;
}

interface CreateCompositeVideoTaskParams {
  title: string;
  thumbnail: string;
  scenes: (Pick<Scene, 'type' | 'image'> & {subtitle?: string})[];
  video_config: VideoConfig;
  voice_config: VoiceConfig;
}

export interface Synthesis {
  id: string;
  asset: string;
  status: string;
  progress: Progress;
}

interface CheckCompositeVideoTaskBaseResponse {
  id: string;
  title: string;
  estimated_remaining_time: number | null;
  scenes: Scene[];
  effect: string;
  video_config: VideoConfig;
  voice_config: VoiceConfig;
  synthesis: Synthesis | null;
  progress: Progress | null;
}

interface CheckCompositeVideoTaskProcessingResponse
  extends CheckCompositeVideoTaskBaseResponse {
  status: Exclude<TaskStatus, 'success'>;
}

export type CheckCompositeVideoTaskFailedResponse =
  CheckCompositeVideoTaskBaseResponse & {failure_reason: FailureReason} & {
    status: 'failure';
  };

export interface CheckCompositeVideoTaskSuccessResponse
  extends CheckCompositeVideoTaskBaseResponse {
  status: 'success';
  synthesis: Synthesis & {
    asset: string;
    thumbnail: string;
    create_time: string;
  };
}

interface StoryBoard {
  script: string;
  title: string;
  abstract: string;
  hashtags: string[];
  characters: {
    name: string;
    description: string;
    image: string;
    character_type: string;
  }[];
  scenes: {
    subtitle: string;
    shot_type: string;
    prompt?: string;
    characters?: {name: string; prompt?: string}[];
    prompt_diff_ratio?: number;
  }[];
}

type ScriptActionType =
  | 'generate_shot'
  | 'split_shot'
  | 'merge_shots'
  | 'generate_image';

export type SceneTaskType =
  | 'split_scene'
  | 'merge_prev_scene'
  | 'merge_next_scene'
  | 'regenerate_scene_by_prompt'
  | 'regenerate_scene_by_pose_prompt'
  | 'image_conditioning_video';

const SCENE_ACTION_MAP: Record<SceneTaskType, ScriptActionType> = {
  split_scene: 'split_shot',
  merge_prev_scene: 'merge_shots',
  merge_next_scene: 'merge_shots',
  regenerate_scene_by_prompt: 'generate_image',
  regenerate_scene_by_pose_prompt: 'generate_image',
  image_conditioning_video: 'generate_image',
};

const PROJECT_ID_KEY = 'X-ST-PROJECT-ID';

export const retryFrontend = retry((_, error) => {
  const axiosResponseError = error as AxiosResponse;

  // 状态码500及以上 & 网络错误重试
  // 状态码500以上包括了服务端错误未知错误，服务端超时，服务端内部错误及服务器没有准备好处理请求
  if (
    (axiosResponseError &&
      axiosResponseError.status &&
      axiosResponseError.status >= 500 &&
      axiosResponseError.status < 600) ||
    (error as Error).message === 'Network Error'
  ) {
    return;
  }
  throw error;
});

// 返回Task Id
export function createStoryboardTask(
  params: CreateStoreLinesParams,
  projectId: string
): Promise<string> {
  return api.post('/storyboards', params, {
    headers: {
      [PROJECT_ID_KEY]: projectId,
    },
  });
}

export function checkStoryboardTask(
  taskId: string
): Promise<
  | CheckStoryboardProcessingResponse
  | CheckStoryboardSuccessResponse
  | CheckStoryboardFailedResponse
> {
  return api.get(`/storyboards/${taskId}`);
}

// 返回Task Id
export function createCompositeVideoTask(
  params: CreateCompositeVideoTaskParams,
  projectId: string
): Promise<string> {
  return api.post('/shootings', params, {
    headers: {
      [PROJECT_ID_KEY]: projectId,
    },
  });
}

export function checkCompositeVideoTask(
  taskId: string
): Promise<
  | CheckCompositeVideoTaskProcessingResponse
  | CheckCompositeVideoTaskSuccessResponse
  | CheckCompositeVideoTaskFailedResponse
> {
  return api.get(`/shootings/${taskId}`);
}

export function changeScene(
  type: SceneTaskType,
  storyboard: StoryBoard,
  params: CreateStoreLinesParams,
  projectId: string
): Promise<string> {
  return api.post(
    `/storyboards?action=${SCENE_ACTION_MAP[type]}`,
    {...params, storyboard},
    {
      headers: {
        [PROJECT_ID_KEY]: projectId,
      },
    }
  );
}
export type PoseImagePostTaskParam = {
  size: [number, number];
  style: string;
  prompt: string;
  ref_images: {
    mask: string;
    depth: string;
    open_pose: string;
    characters: string[];
  };
  pose_description: string;
  shot_type: number;
};
export async function getTaskIdOfPoseImageTask(
  data: PoseImagePostTaskParam,
  projectId: string
): Promise<string> {
  return api.post('/tools/pose-scene-image', data, {
    headers: {
      [PROJECT_ID_KEY]: projectId,
    },
  });
}
export type GetTaskStatusOfPoseImageTaskResponse = {
  id: string;
  create_time: string;
  status: TaskStatus;
  type: string;
  style: string;
  prompt: string;
  size: [number, number];
  ref_images: {
    mask: string;
    depth: string;
    open_pose: string;
    characters: string[];
  };
  pose_description: string;
  asset?: string;
};
export async function getTaskStatusOfPoseImageTask(
  taskId: string
): Promise<GetTaskStatusOfPoseImageTaskResponse> {
  return api.get(`/tools/pose-scene-image/${taskId}`);
}
export function isGetTaskStatusOfPoseImageTaskResponse(
  response: AllCheckTaskStatusResponse
): response is GetTaskStatusOfPoseImageTaskResponse {
  return (
    (response as GetTaskStatusOfPoseImageTaskResponse).type ===
    'pose_scene_image'
  );
}
export function isCheckStoryboardFailedResponse(
  response: AllCheckTaskStatusResponse
): response is CheckStoryboardFailedResponse {
  return (response as CheckStoryboardFailedResponse).status === 'failure';
}
