// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {GoogleOAuthProvider} from '@react-oauth/google';
import {Response, ResponseCode} from 'api/BackendClient';
import {AxiosResponse} from 'axios';
import classNames from 'classnames';
import {DiscordButton} from 'components/DiscordButton';
import {GoogleButton} from 'components/GoogleButton';
import {useAPI} from 'contexts/APIContext';
import {useUserContext} from 'contexts/UserContext';
import {EMAIL_REG} from 'lib/auth';
import {getQueryStringParameters} from 'lib/common';
import {usePasswordValidator} from 'modules/auth/services';
import {EmailErrorType, UseErrorToastReturnType} from 'modules/auth/types';
import {Button} from 'pages/components/Button';
import {GlassInputBox} from 'pages/components/GlassInputBox/GlassInputBox';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate, useOutletContext} from 'react-router-dom';
import {
  CONFIRM_EMAIL_PATH,
  RESET_PASSWORD_PATH,
  ROOT_PATH,
  SIGNUP_PATH,
} from 'utils/path';

import styles from './AuthForm.module.scss';
import {EmailErrorMessage} from './EmailErrorMessage';
import {PasswordErrorMessage} from './PasswordErrorMessage';

export function LoginForm() {
  const {t} = useTranslation('auth');
  const {updateUserLogin} = useUserContext();

  const [googleLoading, setGoogleLoading] = useState(false);
  const {setErrorToastType, showErrorToast, setErrorToastVisible} =
    useOutletContext<UseErrorToastReturnType>();

  const [email, setEmail] = useState(() => {
    const params = getQueryStringParameters(location.href);
    return params.email || '';
  });
  const {
    password,
    confirmPassword,
    passwordErrorType,
    validPassword,
    changePassword,
    handleSetPasswordErrorType,
  } = usePasswordValidator();
  const [emailErrorType, setEmailErrorType] = useState<EmailErrorType | null>(
    null
  );
  const changeEmail = useCallback(
    (value: string) => {
      setEmailErrorType(null);
      if (passwordErrorType === 'PASSCODE_NOT_MATCH') {
        handleSetPasswordErrorType(null);
      }
      setEmail(value);
    },
    [handleSetPasswordErrorType, passwordErrorType]
  );
  const [isVerifying, setIsVerifying] = useState(false);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const focusPasswordInput = () => {
    const passwordInput = passwordInputRef.current;
    if (!passwordInput) return;
    passwordInput.focus();
  };

  const navigate = useNavigate();

  const onForgotPassword = useCallback(() => {
    navigate(RESET_PASSWORD_PATH);
  }, [navigate]);

  const validEmail = useCallback(
    async (callbackFun?: () => void) => {
      const isValid = EMAIL_REG.test(email);

      if (isValid && emailErrorType === 'EMAIL_INVALID') {
        setEmailErrorType(null);
      } else if (!isValid) {
        setEmailErrorType('EMAIL_INVALID');
        return false;
      }

      if (isValid && callbackFun) {
        callbackFun();
      }
      return isValid;
    },
    [email, emailErrorType]
  );

  const verifyAllInput = useCallback(async () => {
    const passwordPass = validPassword();
    const confirmPasswordPass = true;
    if (!passwordPass) {
      handleSetPasswordErrorType('PASSCODE_NOT_MATCH');
    }
    if (!passwordPass || !confirmPasswordPass) return false;
    const validEmailRes = await validEmail();
    return validEmailRes && passwordPass;
  }, [handleSetPasswordErrorType, validEmail, validPassword]);

  const {backendClient} = useAPI();

  const login = useCallback(
    () =>
      backendClient
        .login({
          email,
          password,
        })
        .then(res => {
          updateUserLogin(
            email,
            res.data.userName,
            res.data.userId,
            res.data.portrait,
            res.data.token,
            res.data.emailConfirmStatus
          );
          navigate(ROOT_PATH);
          return true;
        })
        .catch((err: AxiosResponse<Response>) => {
          const {error} = err.data || {};
          switch (error) {
            case ResponseCode.PASSCODE_NOT_MATCH:
              handleSetPasswordErrorType('PASSCODE_NOT_MATCH');
              break;
            case ResponseCode.USER_NOT_FOUND:
              setEmailErrorType('USER_NOT_FOUND');
              break;
            case ResponseCode.USER_EMAIL_NOT_CONFIRM_YET:
              navigate(CONFIRM_EMAIL_PATH, {state: {email}, replace: true});
              break;
          }
          return err;
        }),
    [
      backendClient,
      email,
      password,
      updateUserLogin,
      navigate,
      handleSetPasswordErrorType,
    ]
  );

  const onLogin = useCallback(async () => {
    setIsVerifying(true);
    const isPass = await verifyAllInput();
    if (isPass) await login();
    setIsVerifying(false);
  }, [login, verifyAllInput]);

  useEffect(() => {
    setIsVerifying(false);
  }, [email, password, confirmPassword]);
  return (
    <>
      <div className={styles.title}>{t('Log in to Mootion')}</div>
      <div className={styles['content-container']}>
        <div className={styles.formContainer}>
          {process.env.REACT_APP_GOOGLE_CLIENT_ID && (
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            >
              <GoogleButton
                googleLoading={googleLoading}
                setGoogleLoading={setGoogleLoading}
                styles={styles}
                setErrorToastType={setErrorToastType}
                showErrorToast={showErrorToast}
                setErrorToastVisible={setErrorToastVisible}
                buttonText={t('Log in with Google')}
              />
            </GoogleOAuthProvider>
          )}
          <DiscordButton
            disabled={googleLoading}
            text={t('Log in with Discord')}
            styles={styles}
          />
          <div className={styles.splitLine}>
            <span className={styles.line} />
            <span>{t('or')}</span>
            <span className={styles.line} />
          </div>
          <div className={styles.auth_form_container}>
            <GlassInputBox
              disabled={googleLoading}
              className={classNames(styles.email_input, styles.input, 'body-m')}
              placeholder="example@email.com"
              onKeyUp={e => e.key === 'Enter' && focusPasswordInput()}
              value={email}
              onChange={e => changeEmail(e.target.value)}
            />

            <GlassInputBox
              disabled={googleLoading}
              className={classNames(
                styles.password_input,
                styles.input,
                'body-m'
              )}
              placeholder={t('password (at least 8 characters)')}
              ref={passwordInputRef}
              onKeyUp={e => e.key === 'Enter' && onLogin()}
              value={password}
              onChange={e => changePassword(e.target.value)}
              type="password"
            />
            {emailErrorType !== null && (
              <EmailErrorMessage
                errorType={emailErrorType}
                className={styles.error_message}
              />
            )}
            {emailErrorType === null && passwordErrorType !== null && (
              <PasswordErrorMessage
                errorType={passwordErrorType}
                className={styles.error_message}
              />
            )}

            <div className={classNames(styles.forgotPassword)}>
              <Button
                size="small"
                type="text"
                onClick={onForgotPassword}
                disabled={googleLoading}
              >
                {t('Forgot password?')}
              </Button>
            </div>

            {googleLoading && (
              <div className={classNames(styles.loginBox)}>
                <Button
                  type="button"
                  size="large"
                  onClick={() => setGoogleLoading(false)}
                >
                  {t('Cancel')}
                </Button>
              </div>
            )}

            {!googleLoading && (
              <div className={classNames(styles.loginBox)}>
                <Button
                  type="button"
                  size="large"
                  isLoading={isVerifying}
                  icon={<></>}
                  onClick={onLogin}
                  disabled={
                    emailErrorType !== null ||
                    passwordErrorType !== null ||
                    isVerifying
                  }
                >
                  {t('Log in')}
                </Button>
              </div>
            )}
          </div>
        </div>
        <p className={styles.to_signup}>
          <span>{t("Don't have an account? ")}</span>
          {googleLoading ? (
            <span className={styles.disabled}>{t('Sign up for free')}</span>
          ) : (
            <Link to={SIGNUP_PATH} className={styles.link}>
              {t('Sign up for free')}
            </Link>
          )}
        </p>
      </div>
    </>
  );
}
