// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import * as frontend from 'api/frontend';
import {ResourceContext} from 'contexts/ResourceManager.type';
import JSZip from 'jszip';
import {cleanFilename} from 'lib/download';
import {SessionStorageKey} from 'lib/hooks';
import {patch as patchConstraint} from 'modules/constraint/models/Constraint';
import {getConstraintByProjectAndType} from 'modules/constraint/utils';
import {PlanType} from 'modules/payment/types';
import {BilingualDialoguePreference} from 'modules/preference/models/BilingualDialoguePreference';
import {BilingualStoryPreference} from 'modules/preference/models/BilingualStoryPreference';
import {GeneralStoryPreference} from 'modules/preference/models/GeneralStoryPreference';
import {Preference} from 'modules/preference/models/Preference';
import {ShortVideoPreference} from 'modules/preference/models/ShortVideoPreference';
import {
  preferenceHasAvatar,
  preferenceHasCardDesign,
  preferenceHasEffect,
  preferenceHasFigureStyle,
  preferenceHasHoliday,
  preferenceHasNativeLanguage,
  preferenceHasOverlayEffect,
  preferenceHasParagraphAsShots,
  preferenceHasProficiencyLevel,
  preferenceHasQuickPace,
  preferenceHasStyle,
  preferenceHasSubtitleStyle,
  preferenceHasThumbnail,
  preferenceHasTitleStyle,
  preferenceHasTone,
  preferenceHasTransition,
  preferenceHasVoiceover,
  preferenceHasVoiceoverSpeed,
} from 'modules/preference/utils';
import {BilingualDialogueProjectConfig} from 'modules/project-config/models/BilingualDialogueProjectConfig';
import {BilingualStoryProjectConfig} from 'modules/project-config/models/BilingualStoryProjectConfig';
import {GeneralStoryProjectConfig} from 'modules/project-config/models/GeneralStoryProjectConfig';
import {HolidayGreetingProjectConfig} from 'modules/project-config/models/HolidayGreetingProjectConfig';
import {ShortVideoProjectConfig} from 'modules/project-config/models/ShortVideoProjectConfig';
import {Avatar, ThumbnailType} from 'modules/project-config/types';
import {HistoryJSON} from 'modules/project-history/types';
import {BilingualDialogueScene} from 'modules/scene/models/BilingualDialogueScene';
import {BilingualStoryScene} from 'modules/scene/models/BilingualStoryScene';
import {GeneralStoryScene} from 'modules/scene/models/GeneralStoryScene';
import {HolidayGreetingScene} from 'modules/scene/models/HolidayGreetingScene';
import {Scene} from 'modules/scene/models/Scene';
import {ShortVideoScene} from 'modules/scene/models/ShortVideoScene';
import {ClosedScene} from 'modules/scene/types';
import {getScriptCopyContent} from 'modules/scene/utils';
import {BilingualDialogueStoryboard} from 'modules/storyboard/models/BilingualDialogueStoryboard';
import {BilingualStoryStoryboard} from 'modules/storyboard/models/BilingualStoryStoryboard';
import {GeneralStoryStoryboard} from 'modules/storyboard/models/GeneralStoryStoryboard';
import {HolidayGreetingStoryboard} from 'modules/storyboard/models/HolidayGreetingStoryboard';
import {ShortVideoStoryboard} from 'modules/storyboard/models/ShortVideoStoryboard';
import {Storyboard} from 'modules/storyboard/models/Storyboard';
import {nanoid} from 'nanoid';

import {BilingualDialogueProject} from './models/BilingualDialogueProject';
import {BilingualStoryProject} from './models/BilingualStoryProject';
import {GeneralStoryProject} from './models/GeneralStoryProject';
import {HolidayGreetingProject} from './models/HolidayGreetingProject';
import {patch as patchProject, Project} from './models/Project';
import {
  BilingualDialogueIdeaPromptPolicy,
  BilingualStoryIdeaPromptPolicy,
  GeneralStoryIdeaPromptPolicy,
  HolidayGreetingIdeaPromptPolicy,
  PromptPolicy,
  ScriptPromptPolicy,
  ShortVideoIdeaPromptPolicy,
} from './models/PromptPolicy';
import {ShortVideoProject} from './models/ShortVideoProject';
import {
  BilingualDialogueProjectJSON,
  BilingualStoryProjectJSON,
  GeneralStoryProjectJSON,
  Holiday,
  HolidayGreetingProjectJSON,
  Option,
  ProficiencyLevel,
  ProjectJSON,
  ProjectType,
  PromptType,
  ShortVideoProjectJSON,
  Tone,
} from './types';
import {getProjectVersionManagerInstance, isLastVersion} from './version';

export const MAX_HISTORY_QUANTITY = 10;

export const PROFICIENCY_LEVEL_OPTIONS: Option<ProficiencyLevel>[] = [
  {label: 'Neutral', value: "normal language learners'"},
  {label: 'A1 - Beginner', value: 'CEFR Level A1 or Beginner'},
  {label: 'A2 - Elementary', value: 'CEFR Level A2 or Elementary'},
  {label: 'B1 - Intermediate', value: 'CEFR Level B1 or Intermediate'},
  {
    label: 'B2 - Upper-intermediate',
    value: 'CEFR Level B2 or Upper-intermediate',
  },
  {label: 'C1 - Advanced', value: 'CEFR Level C1 or Advanced'},
  {label: 'C2 - Proficient', value: 'CEFR Level C2 or Proficient'},
  {label: 'Other', value: {type: 'Other', value: ''}},
];

export const TONE_OPTIONS: Option<Tone>[] = [
  {label: 'Neutral', value: 'Neutral'},
  {label: 'Humor and satire', value: 'Humor and Satire'},
  {label: "Children's", value: "Children's"},
  {label: 'Business', value: 'Business'},
  {label: 'Academic', value: 'Academic'},
  {label: 'Other', value: {type: 'Other', value: ''}},
];

export const HOLIDAY_OPTIONS: (Option<Holiday> & {
  split?: boolean;
})[] = [
  {label: "New Year's Day", value: "New Year's Day"},
  {label: 'Lunar New Year', value: 'Lunar New Year'},
  {label: "Valentine's Day", value: "Valentine's Day"},
  {label: "April Fools' Day", value: "April Fools' Day"},
  {label: 'Easter', value: 'Easter'},
  {label: 'Halloween', value: 'Halloween'},
  {label: 'Diwali', value: 'Diwali'},
  {label: 'Thanksgiving Day', value: 'Thanksgiving Day'},
  {label: 'Christmas', value: 'Christmas', split: true},
  {label: 'Birthday', value: 'Birthday'},
  {label: 'Weddings & Anniversaries', value: 'Weddings & Anniversaries'},
  {label: 'Graduation', value: 'Graduation'},
  {label: 'Other', value: {type: 'Other', value: ''}},
];

export const ProjectCacheSessionStorageMap: Record<ProjectType, string> = {
  ['general_story']: SessionStorageKey.ProjectCache,
  ['bilingual_story']: SessionStorageKey.ProjectCache_Bilingual_Story,
  ['bilingual_dialogue']: SessionStorageKey.ProjectCache_Bilingual_Dialogue,
  ['short_video']: SessionStorageKey.ProjectCache_ShortVideo,
  ['holiday_greeting']: SessionStorageKey.ProjectCache_Holiday_Greeting,
};

export enum Volume {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

const VOICEOVER_VOLUME_MAP = {
  [Volume.Low]: 0.75,
  [Volume.Medium]: 1,
  [Volume.High]: 2,
};

const BGM_VOLUME_MAP = {
  [Volume.Low]: 0.5,
  [Volume.Medium]: 1,
  [Volume.High]: 2,
};

export function getVoiceoverVolume(volume: Volume = Volume.Medium) {
  return VOICEOVER_VOLUME_MAP[volume] ?? VOICEOVER_VOLUME_MAP[Volume.Medium];
}

export function getBgmVolume(volume: Volume = Volume.Medium) {
  return BGM_VOLUME_MAP[volume] ?? BGM_VOLUME_MAP[Volume.Medium];
}

export function getSceneImage(
  scene: Scene<ProjectType> | ClosedScene<ProjectType>
) {
  if (
    scene instanceof GeneralStoryScene ||
    scene instanceof HolidayGreetingScene ||
    scene instanceof BilingualStoryScene ||
    scene instanceof BilingualDialogueScene ||
    scene instanceof ShortVideoScene
  ) {
    return scene.currentImage || '';
  } else {
    return scene?.image || '';
  }
}

export type ConvertResult = Omit<ProjectJSON<ProjectType>, 'histories'> & {
  histories?: (HistoryJSON<ProjectType> | string)[];
};
export function convertProjectVersion(
  projectJSON: ProjectJSON<ProjectType>
): ConvertResult {
  const projectVersionManager = getProjectVersionManagerInstance(
    reviewProjectType(projectJSON.type)
  );
  return projectVersionManager.convert(projectJSON) as ConvertResult;
}

export function reviewProjectType(type: string): ProjectType {
  if (['Idea', 'Script', 'Prompt', 'Content'].includes(type)) {
    return 'general_story';
  } else if (
    [
      'general_story',
      'holiday_greeting',
      'short_video',
      'bilingual_story',
      'bilingual_dialogue',
    ].includes(type)
  ) {
    return type as ProjectType;
  } else {
    throw new Error('unknown project type: ' + type);
  }
}
//修正project中的错误数据：包括错误的历史数据、不同类型共用同一个字段且在存在了preference中导致的错误数据
export function reviewProject<T extends ProjectType>(
  project: Project<T>,
  resourceManager: ResourceContext,
  plan: PlanType
): Project<T> {
  const {
    titleStylesOf,
    subtitleStylesOf,
    voiceoversOf,
    getStyles,
    bgmsOf,
    getTransitions,
    getLanguages,
    thumbnailTypesOf,
    getTargetLanguages,
    getNativeLanguages,
    getBilingualStoryVoiceover,
    getTitleBilingualCombinationsByLanguage,
    getSubtitleBilingualCombinationsByLanguage,
    getAvatarCharacters,
    getAvatarLayouts,
    getHolidayCards,
    getOverlayEffects,
    getFigureStyles,
  } = resourceManager;

  //  Verify language
  const OLD_LANGUAGE_MAP: Record<string, string> = {
    'zh-cn': 'zh-CN',
    en: 'en-US',
  };
  const languages = getLanguages();
  let newLanguage = 'en-US';

  if (Object.keys(OLD_LANGUAGE_MAP).includes(project.language)) {
    newLanguage = OLD_LANGUAGE_MAP[project.language];
  } else {
    const languageCode = languages.find(
      ({code}) => code === project.language
    )?.code;
    if (languageCode) {
      newLanguage = languageCode;
    }
  }

  //  Verify NativeLanguage
  let newNativeLanguage;
  if (
    project instanceof BilingualStoryProject ||
    project instanceof BilingualDialogueProject
  ) {
    //如果是双语故事。language得是en-US、en-GB中的一种
    const targetLanguages = getTargetLanguages();
    newLanguage = targetLanguages.find(
      language => language.code === newLanguage
    )
      ? newLanguage
      : targetLanguages[0].code;
    const nativeLanguages = getNativeLanguages(newLanguage);
    newNativeLanguage = nativeLanguages[0].code;
    const languageCode = nativeLanguages.find(
      ({code}) => code === project.nativeLanguage
    )?.code;
    if (languageCode) {
      newNativeLanguage = languageCode;
    }
  }

  // Verify Style
  let newStyle;
  if (projectHasStyle(project)) {
    const styles = getStyles(['project']);
    newStyle =
      styles.find(
        ({name, supported_plans}) =>
          name === project.style && supported_plans.includes(plan)
      )?.name ?? styles[0]?.name;
  }

  // Verify proficiency level
  let newPromptPolicy = project.promptPolicy;
  if (promptPolicyHasProficiencyLevel(newPromptPolicy)) {
    let newProficiencyLevel = undefined;

    const proficiencyLevels = PROFICIENCY_LEVEL_OPTIONS.map(({value}) => value);

    const defaultProficiencyLevel = newPromptPolicy.proficiencyLevel;
    if (typeof defaultProficiencyLevel === 'string') {
      newProficiencyLevel = proficiencyLevels.includes(defaultProficiencyLevel)
        ? defaultProficiencyLevel
        : proficiencyLevels[0];
    } else {
      newProficiencyLevel = {
        type: 'Other',
        value: defaultProficiencyLevel.value ?? '',
      } as ProficiencyLevel;
    }
    newPromptPolicy = newPromptPolicy.patch({
      proficiencyLevel: newProficiencyLevel,
    });
  }

  //  Verify cardDesign
  let cardDesign: string | null | undefined = undefined;
  if (project instanceof HolidayGreetingProject) {
    cardDesign = (project.config as HolidayGreetingProjectConfig).cardDesign;
    if (
      cardDesign &&
      !getHolidayCards().find(item => item.name === cardDesign)
    ) {
      cardDesign = getHolidayCards().filter(item => item.name !== 'NA')[0].name;
    }
  }
  const titleStyles = titleStylesOf(newLanguage);
  const subtitleStyles = subtitleStylesOf(newLanguage);
  const voiceovers = voiceoversOf(newLanguage);
  const bgms = (() => {
    const bgms = bgmsOf(
      project.config instanceof BilingualStoryProjectConfig ||
        project.config instanceof BilingualDialogueProjectConfig
        ? 'BILINGUAL'
        : 'ALL'
    );
    return project.config instanceof ShortVideoProjectConfig
      ? bgms.filter(bgm => bgm.flow !== 'Slow')
      : bgms;
  })();

  const transitions =
    project instanceof ShortVideoProject
      ? getTransitions().filter(transition => transition.flow !== 'Slow')
      : getTransitions();
  const thumbnailTypes = thumbnailTypesOf(newLanguage);

  let voiceover = null;
  if (
    project instanceof GeneralStoryProject ||
    project instanceof HolidayGreetingProject ||
    project instanceof ShortVideoProject
  ) {
    const tempVoiceover = (
      project.config as
        | GeneralStoryProjectConfig
        | HolidayGreetingProjectConfig
        | ShortVideoProjectConfig
    ).voiceover;
    if (tempVoiceover !== null) {
      voiceover =
        voiceovers.find(
          ({name, supported_plans}) =>
            name === tempVoiceover && supported_plans.includes(plan)
        )?.name ?? voiceovers[0].name;
    }
  } else if (project instanceof BilingualStoryProject) {
    const voiceoverList = getBilingualStoryVoiceover();
    voiceover =
      voiceoverList.find(
        ({name}) =>
          name === (project.config as BilingualStoryProjectConfig).voiceover
      )?.name ?? voiceover;
  }
  let newVoiceovers = undefined;
  if (project instanceof BilingualDialogueProject) {
    const voiceoverList = getBilingualStoryVoiceover();
    newVoiceovers = project.config.voiceovers.map(
      voiceoverName =>
        voiceoverList.find(item => item.name === voiceoverName)?.name ||
        voiceoverList[0].name
    ) as [string, string, string];
  }
  //  Verify project.config.avatar ：character如果不存在，avatar设置为undefined
  let avatar: Avatar | undefined = undefined;
  if (
    project instanceof GeneralStoryProject ||
    project instanceof ShortVideoProject ||
    project instanceof BilingualStoryProject
  ) {
    const characters = getAvatarCharacters();
    const layouts = getAvatarLayouts();
    if (project.config.avatar) {
      const character = characters.find(
        ({name}) => name === project.config.avatar!.character
      );
      if (character) {
        const layout = layouts.find(
          ({name}) => name === project.config.avatar!.layout
        );
        avatar = {
          character: character.name,
          layout: layout?.name ?? layouts[0].name,
        };
      }
    }
  }
  const voiceoverSpeed =
    project.config instanceof GeneralStoryProjectConfig ||
    project.config instanceof HolidayGreetingProjectConfig ||
    project.config instanceof ShortVideoProjectConfig
      ? ['normal', 'fast'].includes(project.config.voiceoverSpeed)
        ? project.config.voiceoverSpeed
        : project.type === 'short_video'
        ? 'fast'
        : 'normal'
      : undefined;

  let titleStyle = null;
  if (
    project instanceof GeneralStoryProject ||
    project instanceof ShortVideoProject
  ) {
    if (project.config.titleStyle !== null) {
      titleStyle =
        titleStyles.find(
          ({name, supported_plans}) =>
            name === project.config.titleStyle && supported_plans.includes(plan)
        )?.name ?? titleStyles[0].name;
    }
  } else if (
    project instanceof BilingualStoryProject ||
    project instanceof BilingualDialogueProject
  ) {
    const titleStyleCombination = getTitleBilingualCombinationsByLanguage(
      newLanguage,
      newNativeLanguage as string
    );
    titleStyle =
      titleStyleCombination.find(({name}) => name === project.config.titleStyle)
        ?.name ?? titleStyleCombination[0].name;
  }

  let subtitleStyle;
  if (
    project instanceof GeneralStoryProject ||
    project instanceof ShortVideoProject
  ) {
    if (project.config.subtitleStyle !== null) {
      subtitleStyle =
        subtitleStyles.find(
          ({name, supported_plans}) =>
            name === project.config.subtitleStyle &&
            supported_plans.includes(plan)
        )?.name ?? subtitleStyles[0].name;
    }
  } else if (
    project instanceof BilingualStoryProject ||
    project instanceof BilingualDialogueProject
  ) {
    const subtitleStyleCombination = getSubtitleBilingualCombinationsByLanguage(
      newLanguage,
      newNativeLanguage as string
    );
    subtitleStyle =
      subtitleStyleCombination.find(
        ({name}) => name === project.config.subtitleStyle
      )?.name ?? subtitleStyleCombination[0].name;
  } else if (project instanceof HolidayGreetingProject) {
    //cardDesign和language共同决定了subtitleStyle
    //先判断cardDesign 是否为null，为null时subtitle应该是name=='NA'的cardDesign对应的subtitle，否则为当前cardDesign对应的subtitle
    const subtitleStyleOfLanguageMap = getHolidayCards().find(
      item => item.name === (cardDesign ?? 'NA')
    )?.subtitle_styles;
    if (subtitleStyleOfLanguageMap) {
      subtitleStyle = subtitleStyleOfLanguageMap[newLanguage];
    }
  }
  //  Verify holiday
  let newHoliday = null;
  if (project instanceof HolidayGreetingProject) {
    const holidayValues = HOLIDAY_OPTIONS.map(({value}) => value);
    const defaultHoliday = project.holiday;
    if (typeof defaultHoliday === 'string') {
      newHoliday = holidayValues.includes(defaultHoliday)
        ? defaultHoliday
        : holidayValues[0];
    } else {
      newHoliday = {
        type: 'Other',
        value: defaultHoliday.value ?? '',
      } as Holiday;
    }
  }

  //  Verify figureStyle
  let newFigureStyle = null;
  if (project instanceof HolidayGreetingProject) {
    const figureStyles = getFigureStyles();
    newFigureStyle = (
      figureStyles.find(({name}) => name === project.figureStyle) ??
      figureStyles[0]
    ).name;
  }

  let voiceoverOrder = undefined;
  if (
    project instanceof BilingualStoryProject ||
    project instanceof BilingualDialogueProject
  ) {
    if (typeof project.config.voiceoverOrder === 'number') {
      voiceoverOrder = project.config.voiceoverOrder;
    } else {
      voiceoverOrder = 1;
    }
  }

  let transition = null;
  if (
    project instanceof GeneralStoryProject ||
    project instanceof BilingualStoryProject ||
    project instanceof BilingualDialogueProject ||
    project instanceof ShortVideoProject
  ) {
    if (project.config.transition !== null) {
      transition =
        transitions.find(
          transition => transition.value === project.config.transition
        )?.value ?? transitions[0].value;
    }
  }

  let thumbnailType, thumbnailIncludeVideo;
  if (
    project instanceof GeneralStoryProject ||
    project instanceof BilingualStoryProject ||
    project instanceof BilingualDialogueProject
  ) {
    thumbnailType = (thumbnailTypes.find(
      ({name}) => name === project.config.thumbnailType
    )?.name ?? thumbnailTypes[0].name) as ThumbnailType;
    thumbnailIncludeVideo =
      typeof project.config.thumbnailIncludeVideo === 'boolean'
        ? project.config.thumbnailIncludeVideo
        : false;
  }

  let bgm = null;
  if (project.config.bgm !== null) {
    bgm =
      bgms.find(
        ({name, supported_plans}) =>
          name === project.config.bgm && supported_plans.includes(plan)
      )?.name ?? bgms[0].name;
  }

  let effect = null;
  if (
    project instanceof GeneralStoryProject ||
    project instanceof ShortVideoProject ||
    project instanceof BilingualStoryProject ||
    project instanceof BilingualDialogueProject
  ) {
    if (
      typeof project.config.effect === 'string' &&
      ['ken_burns', 'camera_shake'].includes(project.config.effect)
    ) {
      effect = project.config.effect;
    } else if (project.config.effect !== null) {
      effect = {
        type: 'ken_burns',
        ratio: 0.9,
      };
    }
  }

  let overlayEffect = null;
  if (project instanceof HolidayGreetingProject) {
    if (project.config.overlayEffect !== null) {
      overlayEffect =
        getOverlayEffects().find(
          overlayEffect => overlayEffect.name === project.config.overlayEffect
        )?.name ?? getOverlayEffects()[0].name;
    }
  }

  return patchProject(project, {
    constraint: patchConstraint(
      project.constraint,
      getConstraintByProjectAndType(
        project.type,
        project.promptPolicy.keepUserContent === false ? 'idea' : 'content',
        plan
      )
    ),
    language: newLanguage,
    promptPolicy: newPromptPolicy,
    ...(project instanceof HolidayGreetingProject && newFigureStyle
      ? {figureStyle: newFigureStyle}
      : {}),
    ...(projectHasNativeLanguage(project) && newNativeLanguage
      ? {nativeLanguage: newNativeLanguage}
      : {}),
    ...(projectHasHoliday(project) && newHoliday ? {holiday: newHoliday} : {}),
    ...(projectHasStyle(project) && newStyle ? {style: newStyle} : {}),
    config: project.config.patch({
      voiceover,
      voiceoverSpeed,
      voiceoverOrder,
      titleStyle,
      subtitleStyle,
      transition,
      thumbnailType,
      thumbnailIncludeVideo,
      bgm,
      effect,
      voiceovers: newVoiceovers,
      avatar,
      bgmVolume: project.config.bgmVolume ?? Volume.Medium,
      voiceoverVolume: project.config.voiceoverVolume ?? Volume.Medium,
      cardDesign,
      overlayEffect,
    }),
  } as Partial<Project<T>>);
}

export function createProjectByUserPreference<T extends ProjectType>(
  initProject: ProjectJSON<T>,
  preference: Preference<T>
): ProjectJSON<T> {
  return {
    ...initProject,
    language: preference.language ?? initProject.language,
    native_language:
      projectJSONHasNativeLanguage(initProject) &&
      preferenceHasNativeLanguage(preference)
        ? preference.nativeLanguage || initProject.native_language
        : undefined,
    size: preference.size || initProject.size,
    style:
      projectJSONHasStyle(initProject) && preferenceHasStyle(preference)
        ? preference.style || initProject.style
        : undefined,

    holiday:
      projectJSONHasHoliday(initProject) && preferenceHasHoliday(preference)
        ? (preference.holiday &&
            (typeof preference.holiday === 'string'
              ? preference.holiday
              : {type: 'Other', value: ''})) ||
          initProject.holiday
        : undefined,
    figure_style:
      projectJSONHasFigureStyle(initProject) &&
      preferenceHasFigureStyle(preference)
        ? preference.figureStyle || initProject.figure_style
        : undefined,
    ...((initProject.type === 'bilingual_dialogue' && {
      characters:
        (preference as BilingualDialoguePreference).characterOption === '2'
          ? [{name: undefined}, {name: undefined}]
          : null,
    }) ||
      {}),
    config: {
      ...initProject.config,
      card_design:
        projectJSONHasCardDesign(initProject) &&
        preferenceHasCardDesign(preference)
          ? preference.cardDesign ?? initProject.config.card_design
          : undefined,
      overlay_effect:
        projectJSONHasOverlayEffect(initProject) &&
        preferenceHasOverlayEffect(preference)
          ? preference.overlayEffect ?? initProject.config.overlay_effect
          : undefined,
      voiceover:
        projectJSONHasVoiceover(initProject) &&
        preferenceHasVoiceover(preference)
          ? preference.voiceover === undefined
            ? initProject.config.voiceover
            : preference.voiceover
          : undefined,
      effect:
        projectJSONHasEffect(initProject) && preferenceHasEffect(preference)
          ? preference.effect === undefined
            ? initProject.config.effect
            : preference.effect
          : undefined,
      title_style:
        projectJSONHasTitleStyle(initProject) &&
        preferenceHasTitleStyle(preference)
          ? preference.titleStyle === undefined
            ? initProject.config.title_style
            : preference.titleStyle
          : undefined,
      subtitle_style:
        projectJSONHasSubtitleStyle(initProject) &&
        preferenceHasSubtitleStyle(preference)
          ? preference.subtitleStyle === undefined
            ? initProject.config.subtitle_style
            : preference.subtitleStyle
          : undefined,
      transition:
        projectJSONHasTransition(initProject) &&
        preferenceHasTransition(preference)
          ? preference.transition === undefined
            ? initProject.config.transition
            : preference.transition
          : undefined,
      thumbnail_type:
        projectJSONHasThumbnail(initProject) &&
        preferenceHasThumbnail(preference)
          ? preference.thumbnailType ?? initProject.config.thumbnail_type
          : undefined,
      thumbnail_include_video:
        projectJSONHasThumbnail(initProject) &&
        preferenceHasThumbnail(preference)
          ? preference.thumbnailIncludeVideo ??
            initProject.config.thumbnail_include_video
          : undefined,
      bgm:
        preference.bgm === undefined ? initProject.config.bgm : preference.bgm,
      voiceover_speed:
        projectJSONHasVoiceoverSpeed(initProject) &&
        preferenceHasVoiceoverSpeed(preference)
          ? preference.voiceoverSpeed ?? initProject.config.voiceover_speed
          : undefined,
      //先判断project type类型，类型符合再赋值，否则直接undefined
      avatar:
        projectJSONHasAvatar(initProject) && preferenceHasAvatar(preference)
          ? preference.avatar ?? initProject.config.avatar
          : undefined,
      bgm_volume: preference.bgmVolume ?? initProject.config.bgm_volume,
      voiceover_volume:
        preference.voiceoverVolume ?? initProject.config.voiceover_volume,
    },
    prompt_policy: {
      ...initProject.prompt_policy,
      tone:
        projectJSONHasTone(initProject) &&
        preferenceHasTone(preference) &&
        initProject.prompt_policy.keep_user_content === false
          ? preference.tone ?? initProject.prompt_policy.tone
          : undefined,
      quick_pace:
        projectJSONHasQuickPace(initProject) &&
        preferenceHasQuickPace(preference) &&
        initProject.prompt_policy.keep_user_content === false
          ? preference.quickPace ?? initProject.prompt_policy.quick_pace
          : undefined,
      paragraph_as_shots:
        projectJSONHasParagraphAsShots(initProject) &&
        preferenceHasParagraphAsShots(preference) &&
        initProject.prompt_policy.keep_user_content === true
          ? preference.paragraphAsShots ??
            initProject.prompt_policy.paragraph_as_shots
          : undefined,
      proficiency_level:
        projectJSONHasProficiencyLevel(initProject) &&
        preferenceHasProficiencyLevel(preference) &&
        initProject.prompt_policy.keep_user_content === false
          ? preference.proficiencyLevel ??
            initProject.prompt_policy.proficiency_level
          : undefined,
    },
  };
}
export async function downloadStoryboard(
  storyboard: Pick<
    Storyboard<ProjectType>,
    'title' | 'description' | 'hashtags' | 'prompt'
  > & {
    scenes: ClosedScene<ProjectType>[];
  },
  titles: {
    summary: string;
    hashtags: string;
    script: string;
    prompt: string;
  }
) {
  const {
    title = '',
    scenes = [],
    description = '',
    hashtags = [],
    prompt = '',
  } = storyboard;
  const zip = new JSZip();
  const fileName = cleanFilename(title);

  const folder = zip.folder(fileName);
  if (!folder) return;

  let txtContent = `${title}\n`;

  if (description) {
    txtContent += `\n[${titles.summary}]\n`;
    txtContent += `\n${description}\n`;
  }

  if (hashtags && hashtags.length > 0) {
    txtContent += `\n[${titles.hashtags}]\n`;
    txtContent += `\n#${hashtags.join(' #')}\n`;
  }

  txtContent += `\n[${titles.script}]\n`;

  txtContent += `${getScriptCopyContent(scenes)}`;

  if (prompt) {
    txtContent += `\n[${titles.prompt}]\n`;
    txtContent += `\n${prompt}\n`;
  }

  folder.file(`${fileName}.txt`, txtContent);

  const images = scenes
    .map(
      scene =>
        (getSceneImage(scene) &&
          frontend.staticCombiner(getSceneImage(scene))) ||
        undefined
    )
    .filter(Boolean) as string[];

  const blobs = await Promise.all(
    images.map(async image => {
      const response = await fetch(image);
      return response.blob();
    })
  );
  blobs.forEach((blob, index) =>
    folder.file(`Scene ${index + 1}.${images[index].split('.').pop()}`, blob, {
      binary: true,
    })
  );

  zip.generateAsync({type: 'blob'}).then(content => {
    const link = document.createElement('a');
    link.href = URL.createObjectURL(content);
    link.download = `${fileName}.zip`;
    link.click();
  });
}
export function duplicateGallery(
  project: ProjectJSON<ProjectType>
): ProjectJSON<ProjectType> {
  return {
    ...project,
    id: '',
    storyboard: undefined,
    composition: undefined,
    histories: [],
  };
}

export function trimNewline(str: string, languageCode: string) {
  if (languageCode === 'zh-CN' || languageCode === 'ja-JP') {
    return str.replace(/\r?\n|\r/g, '');
  }
  return str;
}
export function thumbnailCombiner(path: string) {
  return `${process.env.REACT_APP_THUMBNAIL_URL}/${path}`;
}
export function getCachedProject(project: string | null) {
  try {
    const cache = JSON.parse(project ?? '');
    if (!isLastVersion(cache)) {
      return null;
    }
    return {...cache, id: ''};
  } catch {
    return null;
  }
}
export function makeBilingualDialogueStoryboardCustomizedCharacters(
  characters: Project<'bilingual_dialogue'>['characters']
): [{name: string}, {name: string}] | undefined {
  if (characters === null) return undefined;

  return characters && characters.some(c => !c.name)
    ? undefined
    : (characters as [{name: string}, {name: string}]);
}
export function makeStoryboard<T extends ProjectType>(
  project: Project<T>
): Storyboard<T> {
  if (project instanceof GeneralStoryProject) {
    return new GeneralStoryStoryboard(
      nanoid(),
      project.size,
      project.language,
      project.style,
      project.promptPolicy,
      project.prompt!
    ) as GeneralStoryStoryboard as Storyboard<T>;
  } else if (project instanceof ShortVideoProject) {
    return new ShortVideoStoryboard(
      nanoid(),
      project.size,
      project.language,
      project.style,
      project.promptPolicy,
      project.prompt!
    ) as ShortVideoStoryboard as Storyboard<T>;
  } else if (project instanceof BilingualStoryProject) {
    return new BilingualStoryStoryboard(
      nanoid(),
      project.size,
      project.language,
      project.nativeLanguage,
      (project.vocabulary || []).filter(Boolean) as string[],
      project.style,
      project.promptPolicy,
      project.prompt!
    ) as BilingualStoryStoryboard as Storyboard<T>;
  } else if (project instanceof BilingualDialogueProject) {
    return new BilingualDialogueStoryboard(
      nanoid(),
      project.size,
      project.language,
      project.nativeLanguage,
      (project.vocabulary || []).filter(Boolean) as string[],
      project.style,
      project.promptPolicy,
      project.prompt!,
      makeBilingualDialogueStoryboardCustomizedCharacters(project.characters)
    ) as BilingualDialogueStoryboard as Storyboard<T>;
  } else if (project instanceof HolidayGreetingProject) {
    return new HolidayGreetingStoryboard(
      nanoid(),
      project.size,
      project.language,
      project.promptPolicy,
      project.prompt!,
      project.holiday,
      project.images,
      project.figureStyle
    ) as HolidayGreetingStoryboard as Storyboard<T>;
  } else {
    throw new Error(`Unknown project type: ${project}`);
  }
}

export function getPromptPolicyByProjectAndPromptType<P extends ProjectType>(
  project: Project<P>,
  promptType: 'idea' | 'content',
  preference: Preference<P>
): PromptPolicy<P> {
  if (promptType === 'content') {
    if (project instanceof BilingualDialogueProject) {
      // 不匹配的 promptType 和 preference
      throw new Error(
        `Could not match the promptType:${project} with preference:${preference}`
      );
    }
    return new ScriptPromptPolicy(
      project instanceof HolidayGreetingProject
        ? true
        : (
            preference as
              | GeneralStoryPreference
              | BilingualStoryPreference
              | ShortVideoPreference
          ).paragraphAsShots ?? false
    );
  } else {
    if (project instanceof GeneralStoryProject) {
      return new GeneralStoryIdeaPromptPolicy(
        (preference as GeneralStoryPreference).tone ?? 'Neutral',
        (preference as GeneralStoryPreference).quickPace ?? false
      ) as PromptPolicy<P>;
    } else if (project instanceof HolidayGreetingProject) {
      return new HolidayGreetingIdeaPromptPolicy() as PromptPolicy<P>;
    } else if (project instanceof ShortVideoProject) {
      return new ShortVideoIdeaPromptPolicy(
        (preference as ShortVideoPreference).tone ?? 'Neutral'
      ) as PromptPolicy<P>;
    } else if (project instanceof BilingualStoryProject) {
      return new BilingualStoryIdeaPromptPolicy(
        (preference as BilingualStoryPreference).proficiencyLevel ??
          PROFICIENCY_LEVEL_OPTIONS[0].value
      ) as PromptPolicy<P>;
    } else {
      throw new Error(`Unknown project type: ${project}`);
    }
  }
}

export function checkoutPromptType(
  promptPolicy: PromptPolicy<ProjectType>
): PromptType {
  return promptPolicy instanceof ScriptPromptPolicy ? 'script' : 'idea';
}

export function parsePrompt(
  promptType: PromptType,
  prompt?: string | Record<PromptType, string>
): Record<PromptType, string> {
  return (
    typeof prompt === 'string' ? {[promptType]: prompt} : prompt ?? {}
  ) as Record<PromptType, string>;
}

export function mergePrompt(
  prompt: Record<PromptType, string>,
  promptType: PromptType,
  data: Partial<{prompt: string}>
) {
  return 'prompt' in data
    ? {...prompt, [promptType]: data.prompt ?? ''}
    : prompt;
}

//initProject.type是'general_story'、'holiday_greeting'、'short_video'、'bilingual_story'的有Voiceover
function projectJSONHasVoiceover(
  initProject: ProjectJSON<ProjectType>
): initProject is
  | GeneralStoryProjectJSON
  | HolidayGreetingProjectJSON
  | ShortVideoProjectJSON
  | BilingualStoryProjectJSON {
  return (
    initProject.type === 'general_story' ||
    initProject.type === 'holiday_greeting' ||
    initProject.type === 'short_video' ||
    initProject.type === 'bilingual_story'
  );
}

//initProject.type是'general_story'、'short_video'、'bilingual_story'、'bilingual_dialogue'的有Effect
function projectJSONHasEffect(
  initProject: ProjectJSON<ProjectType>
): initProject is
  | GeneralStoryProjectJSON
  | ShortVideoProjectJSON
  | BilingualStoryProjectJSON
  | BilingualDialogueProjectJSON {
  return (
    initProject.type === 'general_story' ||
    initProject.type === 'short_video' ||
    initProject.type === 'bilingual_story' ||
    initProject.type === 'bilingual_dialogue'
  );
}

//initProject.type是general_story、short_video、bilingual_story、bilingual_dialogue的有TitleStyle
function projectJSONHasTitleStyle(
  initProject: ProjectJSON<ProjectType>
): initProject is
  | GeneralStoryProjectJSON
  | ShortVideoProjectJSON
  | BilingualStoryProjectJSON
  | BilingualDialogueProjectJSON {
  return (
    initProject.type === 'general_story' ||
    initProject.type === 'short_video' ||
    initProject.type === 'bilingual_story' ||
    initProject.type === 'bilingual_dialogue'
  );
}

//initProject.type是'general_story'、'short_video'、'bilingual_story'、'bilingual_dialogue'的有SubtitleStyle
function projectJSONHasSubtitleStyle(
  initProject: ProjectJSON<ProjectType>
): initProject is
  | GeneralStoryProjectJSON
  | ShortVideoProjectJSON
  | BilingualStoryProjectJSON
  | BilingualDialogueProjectJSON {
  return (
    initProject.type === 'general_story' ||
    initProject.type === 'short_video' ||
    initProject.type === 'bilingual_story' ||
    initProject.type === 'bilingual_dialogue'
  );
}

//initProject.type是'general_story'、'holiday_greeting'、'bilingual_story'、'bilingual_dialogue'的有Transition
function projectJSONHasTransition(
  initProject: ProjectJSON<ProjectType>
): initProject is
  | GeneralStoryProjectJSON
  | ShortVideoProjectJSON
  | BilingualStoryProjectJSON
  | BilingualDialogueProjectJSON {
  return (
    initProject.type === 'general_story' ||
    initProject.type === 'short_video' ||
    initProject.type === 'bilingual_story' ||
    initProject.type === 'bilingual_dialogue'
  );
}
//initProject.type是'general_story'、'holiday_greeting'、'bilingual_story'、'bilingual_dialogue'的有Thumbnail
function projectJSONHasThumbnail(
  projectJSON: ProjectJSON<ProjectType>
): projectJSON is
  | GeneralStoryProjectJSON
  | BilingualStoryProjectJSON
  | BilingualDialogueProjectJSON {
  const projectType = reviewProjectType(projectJSON.type);
  return (
    projectType === 'general_story' ||
    projectType === 'bilingual_story' ||
    projectType === 'bilingual_dialogue'
  );
}

//initProject.type是'general_story'、'holiday_greeting'、'short_video'的有VoiceoverSpeed
function projectJSONHasVoiceoverSpeed(
  initProject: ProjectJSON<ProjectType>
): initProject is
  | GeneralStoryProjectJSON
  | ShortVideoProjectJSON
  | HolidayGreetingProjectJSON {
  return (
    initProject.type === 'general_story' ||
    initProject.type === 'holiday_greeting' ||
    initProject.type === 'short_video'
  );
}
//initProject.type是'general_story', 'short_video'的有Tone
function projectJSONHasTone(
  initProject: ProjectJSON<ProjectType>
): initProject is GeneralStoryProjectJSON | ShortVideoProjectJSON {
  return (
    initProject.type === 'general_story' || initProject.type === 'short_video'
  );
}

//initProject是general_story的有quick_pace
function projectJSONHasQuickPace(
  initProject: ProjectJSON<ProjectType>
): initProject is GeneralStoryProjectJSON {
  return initProject.type === 'general_story';
}

//initProject是'bilingual_story', 'bilingual_dialogue'的有proficiency_level
function projectJSONHasProficiencyLevel(
  initProject: ProjectJSON<ProjectType>
): initProject is BilingualStoryProjectJSON | BilingualDialogueProjectJSON {
  return (
    initProject.type === 'bilingual_story' ||
    initProject.type === 'bilingual_dialogue'
  );
}

//initProject.type是'general_story'、'holiday_greeting'、'short_video'、'bilingual_story'的有paragraph_as_shots
function projectJSONHasParagraphAsShots(
  initProject: ProjectJSON<ProjectType>
): initProject is
  | GeneralStoryProjectJSON
  | ShortVideoProjectJSON
  | BilingualStoryProjectJSON
  | HolidayGreetingProjectJSON {
  return (
    initProject.type === 'general_story' ||
    initProject.type === 'holiday_greeting' ||
    initProject.type === 'short_video' ||
    initProject.type === 'bilingual_story'
  );
}

//initProject.type是'general_story'、'short_video'、'bilingual_story'、'bilingual_dialogue'的有style
function projectJSONHasStyle(
  initProject: ProjectJSON<ProjectType>
): initProject is
  | GeneralStoryProjectJSON
  | ShortVideoProjectJSON
  | BilingualStoryProjectJSON
  | BilingualDialogueProjectJSON {
  return (
    initProject.type === 'general_story' ||
    initProject.type === 'short_video' ||
    initProject.type === 'bilingual_story' ||
    initProject.type === 'bilingual_dialogue'
  );
}
//project是GeneralStoryProject、ShortVideoProject、BilingualStoryProject、BilingualDialogueProject的有style
export function projectHasStyle(
  project: Project<ProjectType>
): project is
  | GeneralStoryProject
  | ShortVideoProject
  | BilingualStoryProject
  | BilingualDialogueProject {
  return (
    project instanceof GeneralStoryProject ||
    project instanceof ShortVideoProject ||
    project instanceof BilingualStoryProject ||
    project instanceof BilingualDialogueProject
  );
}

//initProject.type是"holiday_greeting"的有holiday
function projectJSONHasHoliday(
  initProject: ProjectJSON<ProjectType>
): initProject is HolidayGreetingProjectJSON {
  return initProject.type === 'holiday_greeting';
}
//initProject.type是"holiday_greeting"的有figureStyle
function projectJSONHasFigureStyle(
  initProject: ProjectJSON<ProjectType>
): initProject is HolidayGreetingProjectJSON {
  return initProject.type === 'holiday_greeting';
}
//project是HolidayGreetingProject的有holiday
function projectHasHoliday(
  project: Project<ProjectType>
): project is HolidayGreetingProject {
  return project instanceof HolidayGreetingProject;
}

//initProject.type是'bilingual_story', 'bilingual_dialogue'的有native_language
function projectJSONHasNativeLanguage(
  initProject: ProjectJSON<ProjectType>
): initProject is BilingualStoryProjectJSON | BilingualDialogueProjectJSON {
  return (
    initProject.type === 'bilingual_story' ||
    initProject.type === 'bilingual_dialogue'
  );
}
//project是BilingualStoryProject、BilingualDialogueProject的有nativeLanguage
function projectHasNativeLanguage(
  project: Project<ProjectType>
): project is BilingualStoryProject | BilingualDialogueProject {
  return (
    project instanceof BilingualStoryProject ||
    project instanceof BilingualDialogueProject
  );
}

//initProject.type是'bilingual_story'、'general_story'、'short_video'的有avatar
function projectJSONHasAvatar(
  initProject: ProjectJSON<ProjectType>
): initProject is
  | BilingualStoryProjectJSON
  | GeneralStoryProjectJSON
  | ShortVideoProjectJSON {
  return (
    initProject.type === 'bilingual_story' ||
    initProject.type === 'general_story' ||
    initProject.type === 'short_video'
  );
}

//initProject.type是'holiday_greeting'的有CardDesign
function projectJSONHasCardDesign(
  initProject: ProjectJSON<ProjectType>
): initProject is HolidayGreetingProjectJSON {
  return initProject.type === 'holiday_greeting';
}

//initProject.type是'holiday_greeting'的有OverlayEffect
function projectJSONHasOverlayEffect(
  initProject: ProjectJSON<ProjectType>
): initProject is HolidayGreetingProjectJSON {
  return initProject.type === 'holiday_greeting';
}

//PromptPolicy是ScriptPromptPolicy、BilingualStoryIdeaPromptPolicy、 BilingualDialogueIdeaPromptPolicy  有proficiencyLevel
function promptPolicyHasProficiencyLevel(
  promptPolicy: PromptPolicy<ProjectType>
): promptPolicy is
  | BilingualStoryIdeaPromptPolicy
  | BilingualDialogueIdeaPromptPolicy {
  return (
    promptPolicy instanceof BilingualStoryIdeaPromptPolicy ||
    promptPolicy instanceof BilingualDialogueIdeaPromptPolicy
  );
}
