// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import axios, {AxiosInstance, AxiosRequestConfig, Method} from 'axios';
import {debounce} from 'lodash';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {DraggableData, DraggableEvent} from 'react-draggable';
import {ResizeCallbackData} from 'react-resizable';

export function useFixedRef<T>(creator: () => T) {
  const ref = useRef<T>();
  if (!ref.current) {
    ref.current = creator();
  }
  return ref.current;
}

//=================SessionStorage start=================
export enum SessionStorageKey {
  BlankTemplate = 'bt',
  CurrentSceneId = 'csi',
  CurrentToolType = 'ctp',
  File = 'f',
  FromShare = 'fs',
  IsFileDirty = 'ifd',
  ShowSceneListPanel = 'sslp',
  UserInfo = 'ui',
  ProjectCache = 'pc',
  ProjectCache_Bilingual_Story = 'pc_bilingual_story',
  ProjectCache_Bilingual_Dialogue = 'pc_bilingual_dialogue',
  ProjectCache_ShortVideo = 'pc_short_video',
  ProjectCache_Holiday_Greeting = 'pc_holiday_greeting',
}

export function useSessionStorage<T>(
  key: SessionStorageKey,
  initialValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = window.sessionStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  useEffect(() => {
    window.sessionStorage.setItem(key, JSON.stringify(storedValue));
  }, [key, storedValue]);

  return [storedValue, setStoredValue];
}

//=================SessionStorage end=================

//=================useFetch start=================
// 定义useFetch返回的数据类型
type UseFetchResponse<T> = {
  data?: T | null;
  error?: string;
  loading: boolean;
};
export function useFetch<T = unknown>(
  axiosInstance: AxiosInstance, // 添加这个参数
  url: string,
  method: Method = 'get',
  config: AxiosRequestConfig = {}
): UseFetchResponse<T> {
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance({url, method, ...config}); // 使用传入的axios实例
        setData(response.data);
        setError('');
      } catch (err: unknown) {
        if (axios.isAxiosError(err)) {
          setError(err.response?.data.message || err.message);
        } else {
          setError('An unexpected error occurred');
        }
        setData(null);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // 确保effect正确触发
  }, [axiosInstance, url, method, config]);

  return {data, error, loading};
}

export function useVisible<T>(
  initValue?: boolean
): [boolean, () => void, (errorType?: T) => void, T | undefined] {
  const [visible, setVisible] = useState<boolean>(initValue || false);
  const [errorType, setErrorType] = useState<T>();

  const hide = useCallback(() => {
    setVisible(false);
  }, []);
  const show = useCallback((errorType?: T) => {
    setVisible(true);
    setErrorType(errorType);
  }, []);

  return [visible, hide, show, errorType];
}

interface UseResizableProps {
  initialWidth: number;
  initialHeight: number;
  maintainAspectRatio?: boolean;
  aspectRatio?: number; // 宽高比，仅在maintainAspectRatio为true时使用
  onResizeCallback?: (
    e: React.SyntheticEvent,
    data: ResizeCallbackData
  ) => void;
}

interface UseResizableReturns {
  width: number;
  height: number;
  onResize: (e: React.SyntheticEvent, data: ResizeCallbackData) => void;
  setSize: React.Dispatch<
    React.SetStateAction<{width: number; height: number}>
  >;
  onResizeStart: (e: React.SyntheticEvent, data: ResizeCallbackData) => void;
}

export function useResizable({
  initialWidth,
  initialHeight,
  maintainAspectRatio = false,
  aspectRatio = 1,
  onResizeCallback,
}: UseResizableProps): UseResizableReturns {
  const _aspectRatio = +Number(aspectRatio).toFixed(5);
  const [size, setSize] = useState({
    width: initialWidth,
    height: initialHeight,
  });

  const onResizeStart = useCallback(
    (e: React.SyntheticEvent, {node, size}: ResizeCallbackData) => {
      node.dataset.initWidth = size.width.toString();
      node.dataset.initHeight = size.height.toString();
      const {x, y} = getTranslateInfo(node.parentElement as HTMLDivElement);
      node.dataset.initTranslateX = x.toString();
      node.dataset.initTranslateY = y.toString();
    },
    []
  );
  // 's' - 南（下边）
  // 'w' - 西（左边）
  // 'e' - 东（右边）
  // 'n' - 北（上边）
  //'sw' - 西南角
  // 'nw' - 西北角
  // 'se' - 东南角
  // 'ne' - 东北角
  const onResize = useCallback(
    // eslint-disable-next-line unused-imports/no-unused-vars
    (e: React.SyntheticEvent, {node, size, handle}: ResizeCallbackData) => {
      if (maintainAspectRatio && _aspectRatio) {
        const newSize = {...size};
        // 根据高度调整宽度以维持宽高比
        newSize.width = newSize.height * _aspectRatio;
        // 根据宽度调整高度以维持宽高比
        //  newSize.height = newSize.width / aspectRatio;
        const {
          initHeight = 0,
          initWidth = 0,
          initTranslateX = 0,
          initTranslateY = 0,
        } = node.dataset;
        const newTranslateY = +initTranslateY + (+initHeight - newSize.height);
        const newTranslateX = +initTranslateX + (+initWidth - newSize.width);
        if (node.parentElement) {
          if (handle === 'ne') {
            node.parentElement.style.transform = `translate(${initTranslateX}px,${newTranslateY}px)`;
          } else if (handle === 'sw') {
            node.parentElement.style.transform = `translate(${newTranslateX}px,${initTranslateY}px)`;
          } else if (handle === 'nw') {
            node.parentElement.style.transform = `translate(${newTranslateX}px,${newTranslateY}px)`;
          }
        }
        node.parentElement &&
          (node.parentElement.style.width = `${newSize.width}px`);
        node.parentElement &&
          (node.parentElement.style.height = `${newSize.height}px`);

        setSize(newSize);
      } else {
        setSize(size);
      }
      onResizeCallback && onResizeCallback(e, {node, size, handle});
    },
    [maintainAspectRatio, _aspectRatio, onResizeCallback]
  );

  return {
    width: size.width,
    height: size.height,
    onResize,
    setSize,
    onResizeStart,
  };
}

export function getTranslateInfo(element: HTMLElement): {x: number; y: number} {
  let translateY = 0,
    translateX = 0;
  const match = element.style.transform.match(
    /translate\((-?\d*\.?\d+px),\s*(-?\d*\.?\d+px)\)/
  );
  if (match) {
    translateX = parseFloat(match[1]);
    translateY = parseFloat(match[2]);
  }
  return {x: translateX, y: translateY};
}

export function useDraggable({
  onDragging,
}: {onDragging?: (x: number, y: number) => void} = {}) {
  const [position, setPosition] = useState({x: 0, y: 0});

  // 处理拖动过程中的事件
  const handleDrag = (e: DraggableEvent, data: DraggableData) => {
    onDragging?.(data.x, data.y);
  };

  // 处理拖动停止时的事件
  const handleStop = (e: DraggableEvent, data: DraggableData) => {
    setPosition({x: data.x, y: data.y});
  };
  return {position, handleDrag, handleStop, setPosition};
}

// 定义一个 Hook 用于获取视口的宽度和高度
export const useViewport = () => {
  // 使用 useState 来保存视口的宽度和高度
  const [viewport, setViewport] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // 定义一个函数用于更新视口的宽度和高度
  const handleResize = () => {
    setViewport({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  // 使用 useEffect 来添加和清除 resize 事件监听器
  useEffect(() => {
    // 添加 resize 事件监听器
    window.addEventListener('resize', handleResize);

    // 在组件卸载时移除事件监听器
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // 返回视口的宽度和高度
  return viewport;
};

interface UseLazyLoadProps<T> {
  fetchData: (page: number, pageSize: number) => Promise<T[]>;
  pageSize: number;
}

export function useLazyLoad<T>({fetchData, pageSize}: UseLazyLoadProps<T>) {
  const [data, setData] = useState<T[] | null>(null);
  const pageRef = useRef(0);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const isInitializedRef = useRef(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const allPromiseRef = useRef<Promise<T[]>[]>([]);
  const loadMoreRef = useRef<SVGSVGElement>(null);

  const loadMore = useCallback(() => {
    if (!hasMore) return;
    setIsLoading(true);
    pageRef.current = pageRef.current + 1;
    const currentPage = pageRef.current;
    allPromiseRef.current.push(fetchData(pageRef.current, pageSize));
    Promise.all(allPromiseRef.current)
      .then(arrs => {
        if (currentPage === pageRef.current) {
          setData(prevData => {
            return arrs.reduce((left, right) => {
              left = left.concat(right);
              return left;
            }, prevData || []);
          });
          setHasMore(arrs[arrs.length - 1].length === pageSize);
        }
      })
      .finally(() => {
        if (currentPage === pageRef.current) {
          allPromiseRef.current.length = 0;
          setIsLoading(false);
        }
      });
  }, [fetchData, pageSize, hasMore]);

  const refresh = useCallback(
    (refreshAssetListCallback?: () => void) => {
      setIsLoading(true);
      setData([]);
      pageRef.current = 1;
      fetchData(1, pageSize)
        .then(newData => {
          setData(newData);
          setHasMore(newData.length === pageSize);
          refreshAssetListCallback && refreshAssetListCallback();
        })
        .finally(() => setIsLoading(false));
    },
    [fetchData, pageSize]
  );
  const refreshAfterDelete = useCallback(
    (id: string, attr: keyof T) => {
      const deleteItemIdx = data?.findIndex(item => item[attr] === id);
      if (data && deleteItemIdx !== undefined && deleteItemIdx !== -1) {
        const newData = data
          .slice(0, deleteItemIdx)
          .concat(data.slice(deleteItemIdx + 1));
        //删除当前元素
        setData(newData);
        //处理特殊情况
        //如果后面还有数据，删除元素后，需要补全最后一页的数据
        setIsLoading(true);
        fetchData(pageRef.current, pageSize)
          .then(_data => {
            setData(
              newData.slice(0, (pageRef.current - 1) * pageSize).concat(_data)
            );
            setHasMore(_data.length === pageSize);
          })
          .finally(() => setIsLoading(false));
      }
    },
    [data, fetchData, pageSize]
  );
  useEffect(() => {
    if (!isInitializedRef.current) {
      loadMore();
      isInitializedRef.current = true;
    }
  }, [loadMore]);

  const setLoadMoreTrigger = useCallback(
    (node: SVGSVGElement | null) => {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver(
        entries => {
          if (entries[0].isIntersecting) {
            loadMore();
          }
        },
        {threshold: 0.01}
      );

      if (node) {
        observer.current.observe(node);
      }
    },
    [loadMore]
  );
  return {
    data,
    hasMore,
    isLoading,
    loadMoreRef,
    loadMore,
    refresh,
    setLoadMoreTrigger,
    refreshAfterDelete,
  };
}

export function useWhyDidYouRender(name: string, props: any) {
  const previousProps = useRef(props);

  useEffect(() => {
    if (previousProps.current !== props) {
      for (const key in props) {
        if (props[key] !== previousProps.current[key]) {
          console.log(`  ${key}:`, previousProps.current[key], '→', props[key]);
        }
      }
    }

    previousProps.current = props;
  }, [name, props]);
}

export const useAutosizeTextArea = (value: string) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const textArea = textAreaRef.current;
    if (textArea) {
      // 重置高度，使scrollHeight能够正确计算
      textArea.style.height = 'auto';
      setTimeout(() => {
        // 设置高度为内容的scrollHeight
        textArea.style.height = `${textArea.scrollHeight + 6}px`;
      }, 200);
    }
  }, [value]); // 每次value变化时都重新计算高度

  return textAreaRef;
};

export function useDebouncedAsyncFunction<T extends (...args: any[]) => any>(
  asyncFunction: T,
  delay: number
) {
  const debouncedFunction = useRef(debounce(asyncFunction, delay));

  useEffect(() => {
    // 取消之前的防抖函数
    debouncedFunction.current?.cancel && debouncedFunction.current.cancel();
    // 更新防抖函数
    debouncedFunction.current = debounce(asyncFunction, delay);
    // 清理函数，组件卸载时取消防抖
    return () => {
      debouncedFunction.current?.cancel && debouncedFunction.current.cancel();
    };
  }, [asyncFunction, delay]);

  return debouncedFunction.current;
}
export function useScrollTop(container: HTMLElement | null) {
  const [isScrollTop, setIsScrollTop] = useState(true);
  useEffect(() => {
    function onScroll() {
      if (!container) return;
      setIsScrollTop(container.scrollTop === 0);
    }
    container?.addEventListener('scroll', onScroll);
    return () => {
      container?.removeEventListener('scroll', onScroll);
    };
  }, [container]);
  return [isScrollTop];
}
