// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {useAwsFileUpload} from 'components/AssetsLibrary/AssetsLibrary.container';
import {combine} from 'components/Combine';
import {getTranslateInfo, useDraggable, useResizable} from 'lib/hooks';
import {noop} from 'lodash';
import {useErrorToast} from 'modules/auth/services';
import {useCallback, useEffect, useRef, useState} from 'react';
import {ResizeCallbackData} from 'react-resizable';

import {UseImageModal} from './UseImageModal';
import {
  getActualImageMetricsReturnType,
  UseImageModalHookReturn,
} from './UseImageModal.type';

type UseHookParamsType = {
  handleOk: (imgUrl: string) => void;
  sourceImgUrl: string;
  hideImageModal: () => void;
  handleStartUpload: () => void;
  ratio?: string;
};
export function useHook({
  handleOk,
  sourceImgUrl,
  hideImageModal,
  handleStartUpload,
  ratio,
}: UseHookParamsType): UseImageModalHookReturn {
  const [bgActionType, setBgActionType] = useState<string>('keep');
  const [imageAspectRatio, setImageAspectRatio] = useState<number>(0);
  const [isFirstImageLoaded, setFirstImageLoaded] = useState(false);
  const [mySourceImgUrl, setMySourceImgUrl] = useState(sourceImgUrl);
  const [isSecondImageLoaded, setSecondImageLoaded] = useState(false);
  const [color, setColor] = useState('#000000');
  const [draggablebounds, setDraggablebounds] = useState({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  });
  const {errorToastVisible, hideErrorToast} = useErrorToast();
  const sourceImgRef = useRef<HTMLImageElement>(null);
  const dragContainerRef = useRef<HTMLImageElement>(null);
  const dragImgRef = useRef<HTMLImageElement>(null);
  const dragImageWrapRef = useRef<HTMLImageElement>(null);
  const leftPanelRef = useRef<HTMLDivElement>(null);
  const leftPanelOverlayRef = useRef<HTMLDivElement>(null);
  const sourceImgSizeRef = useRef({width: 0, height: 0});
  const dragDomdistanceToSourcePointRef = useRef({
    leftToSource: 0,
    topToSource: 0,
    width: 0,
    height: 0,
  });

  const {uploadFile} = useAwsFileUpload(objectKey => {
    handleOk(objectKey as string);
  }, noop);

  const changeMaskStyle = useCallback(() => {
    //获取dragContainerRef.current元素在视口中的位置
    const dragContainerBoundingClientRect =
      dragContainerRef.current?.getBoundingClientRect();
    //获取dragImageWrapRef.current元素在视口中的位置
    const dragImageWrapBoundingClientRect =
      dragImageWrapRef.current?.getBoundingClientRect();
    if (
      dragImageWrapBoundingClientRect &&
      dragContainerBoundingClientRect &&
      dragImageWrapRef.current &&
      dragImageWrapRef.current.parentElement
    ) {
      const {top, right, bottom, left} = dragContainerBoundingClientRect;
      const {
        top: imageTop,
        right: imageRight,
        bottom: imageBottom,
        left: imageLeft,
      } = dragImageWrapBoundingClientRect;
      //超出上边界
      const topMask = dragImageWrapRef.current.querySelector(
        '.top'
      ) as HTMLElement;
      const rightMask = dragImageWrapRef.current.querySelector(
        '.right'
      ) as HTMLElement;
      const bottomMask = dragImageWrapRef.current.querySelector(
        '.bottom'
      ) as HTMLElement;
      const leftMask = dragImageWrapRef.current.querySelector(
        '.left'
      ) as HTMLElement;
      let topMaskheight = 0,
        bottomMaskheight = 0;
      if (imageTop < top) {
        //超出上边界
        if (topMask) {
          topMaskheight = top - imageTop;
          topMask.style.opacity = '1';
          topMask.style.left = '0px';
          topMask.style.bottom = imageBottom - top + 'px';
        }
      } else {
        //超出上边界
        topMask.style.opacity = '0';
      }

      if (imageBottom > bottom) {
        //超出下边界
        if (bottomMask) {
          bottomMaskheight = imageBottom - bottom;
          bottomMask.style.opacity = '1';
          bottomMask.style.left = '0px';
          bottomMask.style.top = bottom - imageTop + 'px';
        }
      } else {
        bottomMask.style.opacity = '0';
        bottomMask.style.left = '0px';
      }
      if (imageLeft < left) {
        //超出左边界
        if (leftMask) {
          leftMask.style.opacity = '1';
          leftMask.style.top = topMaskheight + 'px';
          leftMask.style.right = imageRight - left + 'px';
          if (bottomMaskheight > 0 || topMaskheight > 0) {
            leftMask.style.height =
              imageBottom - imageTop - topMaskheight - bottomMaskheight + 'px';
          } else {
            leftMask.style.height = '500px';
          }
        }
      } else {
        leftMask.style.opacity = '0';
        leftMask.style.top = topMaskheight + 'px';
      }
      if (imageRight > right) {
        //超出右边界
        if (rightMask) {
          rightMask.style.opacity = '1';
          rightMask.style.top = topMaskheight + 'px';
          rightMask.style.left = right - imageLeft - 1 + 'px';
          if (bottomMaskheight > 0 || topMaskheight > 0) {
            rightMask.style.height =
              imageBottom - imageTop - topMaskheight - bottomMaskheight + 'px';
          } else {
            rightMask.style.height = '500px';
          }
        }
      } else {
        rightMask.style.top = topMaskheight + 'px';
        rightMask.style.opacity = '0';
      }
    }
  }, []);
  const {position, handleDrag, handleStop, setPosition} = useDraggable({
    onDragging: changeMaskStyle,
  });
  const {width, height, onResize, setSize, onResizeStart} = useResizable({
    initialHeight: dragImgRef.current?.width || 100,
    initialWidth: dragImgRef.current?.height || 100,
    maintainAspectRatio: true,
    aspectRatio: imageAspectRatio,
    onResizeCallback: changeMaskStyle,
  });
  const onResizeStop = useCallback(
    (e: React.SyntheticEvent, {node}: ResizeCallbackData) => {
      //拿到node.parentElement的transform属性
      const {x, y} = getTranslateInfo(node.parentElement as HTMLDivElement);
      setPosition({x, y});
    },
    [setPosition]
  );
  const onChangeRadio = (_bgActionType: string) => {
    setBgActionType(_bgActionType);
    if (_bgActionType === 'fill') {
      setColor('#000000');
    }
  };
  const handleModalOk = () => {
    if (
      !dragImageWrapRef.current ||
      !dragImageWrapRef.current.parentElement ||
      !sourceImgRef.current
    )
      return;
    const dragItem = dragImageWrapRef.current.parentElement;
    const style = window.getComputedStyle(dragItem);
    const dragItemTransformStr = dragItem.style.transform,
      dragItemStyleLeft = parseInt(dragItem.style.left, 10) + 2,
      dragItemStyleTop = parseInt(dragItem.style.top, 10) + 2;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = sourceImgRef.current.naturalWidth;
    canvas.height = sourceImgRef.current.naturalHeight;
    //横向缩放比例
    const scaleX =
      sourceImgRef.current.naturalWidth / sourceImgSizeRef.current.width;
    //纵向缩放比例
    const scaleY =
      sourceImgRef.current.naturalHeight / sourceImgSizeRef.current.height;
    if (!ctx || !dragImageWrapRef.current || !sourceImgRef.current) return;

    const drawBackground = () => {
      if (bgActionType === 'fill') {
        ctx.fillStyle = color;
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        drawDraggedImage();
      } else if (sourceImgRef.current) {
        // 确保图片加载完成
        if (
          sourceImgRef.current.complete &&
          sourceImgRef.current.naturalHeight !== 0
        ) {
          sourceImgRef.current.crossOrigin = 'anonymous';
          ctx.drawImage(
            sourceImgRef.current,
            0,
            0,
            canvas.width,
            canvas.height
          );
          drawDraggedImage(); // 继续处理拖动的图片
        } else {
          // 如果图片未加载完成，添加加载事件监听
          sourceImgRef.current.onload = () => {
            if (!sourceImgRef.current) {
              return;
            }
            sourceImgRef.current.crossOrigin = 'anonymous';
            ctx.drawImage(
              sourceImgRef.current,
              0,
              0,
              canvas.width,
              canvas.height
            );
            drawDraggedImage(); // 继续处理拖动的图片
          };
        }
      }
    };
    const drawDraggedImage = () => {
      if (
        dragImageWrapRef.current &&
        dragImgRef.current &&
        dragImageWrapRef.current.parentElement
      ) {
        if (
          dragImgRef.current.complete &&
          dragImgRef.current.naturalHeight !== 0
        ) {
          drawImage(dragImgRef.current);
        } else {
          dragImgRef.current.onload = event => {
            drawImage(event.target as HTMLImageElement);
          };
        }
      }
    };

    const drawImage = (dragImage: HTMLImageElement) => {
      handleStartUpload && handleStartUpload();
      if (!dragImage) {
        return;
      }
      const width = parseInt(style.width, 10);
      const height = parseInt(style.height, 10);
      const match = /(-?\d+\.?\d*px),\s*(-?\d+\.?\d*px)/.exec(
        dragItemTransformStr
      );

      let left = 0,
        top = 0;
      if (match) {
        left = parseInt(match[1], 10) + dragItemStyleLeft;
        top = parseInt(match[2], 10) + dragItemStyleTop;
      }
      ctx.drawImage(
        dragImage,
        left * scaleX,
        top * scaleY,
        width * scaleX,
        height * scaleY
      );

      canvas.toBlob(blob => {
        if (!blob) return;
        const file = new File([blob], 'conbine.jpg', {type: 'image/jpeg'});
        uploadFile({file}, 'cdn-st', 'scene_image');
      }, 'image/jpeg');
    };
    drawBackground(); // 开始绘制背景
  };
  useEffect(() => {
    if ((sourceImgUrl === undefined || sourceImgUrl === '') && ratio) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (ratio === '16:9') {
        canvas.width = 1920;
        canvas.height = 1080;
      } else if (ratio === '9:16') {
        canvas.width = 1080;
        canvas.height = 1920;
      } else if (ratio === '1:1') {
        canvas.width = 1080;
        canvas.height = 1080;
      }
      if (ctx) {
        ctx.fillStyle = '#000000';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        // 将Canvas转换为图像URL
        const imgUrl = canvas.toDataURL('image/png');
        setMySourceImgUrl(imgUrl);
      }
    }
  }, [ratio, sourceImgUrl]);
  useEffect(() => {
    if (
      isFirstImageLoaded &&
      isSecondImageLoaded &&
      dragContainerRef.current &&
      sourceImgRef.current &&
      sourceImgRef.current.complete &&
      dragImgRef.current &&
      dragImgRef.current.complete &&
      dragImageWrapRef.current
    ) {
      const [top, left, width, height] =
        getActualImageMetrics(
          sourceImgRef.current,
          undefined,
          undefined,
          30,
          48
        ) || [];
      sourceImgSizeRef.current = {width: width || 0, height: height || 0};
      const [
        dragImgRefTop,
        dragImgRefLeft,
        dragImgRefWidth,
        dragImgRefHeight,
        dragImageAspectRatio,
      ] = getActualImageMetrics(dragImgRef.current, width, height, 0, 0) || [];

      setSize({
        width: dragImgRefWidth || 0,
        height: dragImgRefHeight || 0,
      });
      dragContainerRef.current.style.top = `${Math.floor(top || 0)}px`;
      dragContainerRef.current.style.left = `${Math.floor(left || 0)}px`;
      dragContainerRef.current.style.width = `${width}px`;
      dragContainerRef.current.style.height = `${height}px`;
      dragContainerRef.current.style.opacity = '1';
      if (dragImageWrapRef.current.parentElement) {
        dragImageWrapRef.current.parentElement.style.position = 'relative';
        dragImageWrapRef.current.parentElement.style.top = `${Math.floor(
          (dragImgRefTop && dragImgRefTop - 2) || -2
        )}px`;
        dragImageWrapRef.current.parentElement.style.left = `${Math.floor(
          (dragImgRefLeft && dragImgRefLeft - 2) || -2
        )}px`;
        dragDomdistanceToSourcePointRef.current = {
          leftToSource: (dragImgRefLeft || 0) + Math.floor(left || 0),
          topToSource: Math.floor(dragImgRefTop || 0) + Math.floor(top || 0),
          width: dragImgRefWidth || 0,
          height: dragImgRefHeight || 0,
        };
      }
      setImageAspectRatio(dragImageAspectRatio || 1);
    }
  }, [isFirstImageLoaded, isSecondImageLoaded, setSize]);

  useEffect(() => {
    if (
      dragImageWrapRef.current &&
      leftPanelRef.current &&
      dragContainerRef.current
    ) {
      if (leftPanelRef.current) {
        const {
          leftToSource,
          topToSource,
          width: initialWidth,
          height: initialHeight,
        } = dragDomdistanceToSourcePointRef.current;
        const xDistance = width + leftToSource,
          yDistance = height + topToSource,
          changeXDistance = width - initialWidth,
          changeYDistance = height - initialHeight;
        setDraggablebounds({
          left: 40 - xDistance,
          top: 40 - yDistance,
          right: xDistance - 40 - changeXDistance,
          bottom: yDistance - 40 - changeYDistance,
        });
      }
    }
  }, [width, height]);
  const onloadSourceImg = () => {
    setFirstImageLoaded(true);
  };
  const onloadDragImg = () => {
    setSecondImageLoaded(true);
  };
  const handleChangeComplete: UseImageModalHookReturn['handleChangeComplete'] =
    color => {
      setColor(color.hex);
    };

  return {
    onChangeRadio,
    bgActionType,
    handleModalOk,
    sourceImgRef,
    dragContainerRef,
    dragImageWrapRef,
    leftPanelOverlayRef,
    leftPanelRef,
    draggablebounds,
    onloadSourceImg,
    dragImgRef,
    onloadDragImg,
    resizableWidth: width,
    resizableHeight: height,
    onResize,
    onResizeStart,
    onResizeStop,
    sourceImgUrl: mySourceImgUrl,
    color,
    handleChangeComplete,
    hideImageModal,
    errorToastVisible,
    hideErrorToast,
    position,
    handleDrag,
    handleStop,
  };
}

export const UseImageModalContainer = combine(useHook, [
  'handleOk',
  'sourceImgUrl',
  'hideImageModal',
  'handleStartUpload',
  'ratio',
])(UseImageModal);
//图片基于父元素的宽高，计算图片的实际渲染宽高和位置
function getActualImageMetrics(
  imageElement: HTMLImageElement,
  parentRectWidth = 0,
  parentRectHeight = 0,
  heightPadding = 0,
  widthPadding = 0
): getActualImageMetricsReturnType {
  const naturalWidth = imageElement.naturalWidth;
  const naturalHeight = imageElement.naturalHeight;
  const parentRect = imageElement.parentElement?.getBoundingClientRect();
  if (!parentRect) {
    return;
  }
  const _parentRectWidth = parentRectWidth || parentRect.width || 0;
  const _parentRectHeight = parentRectHeight || parentRect.height || 0;
  // 图片和容器的宽高比
  const imageAspectRatio = naturalWidth / naturalHeight;
  const containerAspectRatio = _parentRectWidth / _parentRectHeight;

  let renderedWidth, renderedHeight;
  if (containerAspectRatio > imageAspectRatio) {
    // 容器比图片更宽，图片高度填满容器，宽度按比例缩放
    renderedHeight = _parentRectHeight - heightPadding;
    renderedWidth = naturalWidth * (renderedHeight / naturalHeight);
  } else {
    // 容器比图片更窄，图片宽度填满容器，高度按比例缩放
    renderedWidth = _parentRectWidth - widthPadding;
    renderedHeight = naturalHeight * (renderedWidth / naturalWidth);
  }

  // 计算居中位置
  const top = (_parentRectHeight - renderedHeight) / 2;
  const left = (_parentRectWidth - renderedWidth) / 2;

  return [top, left, renderedWidth, renderedHeight, imageAspectRatio];
}
