// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {ReactComponent as NoneIcon} from 'assets/svg/3.0/Disable.svg';
import classNames from 'classnames';
import {Slider} from 'components/Slider';
import {useResourceManager} from 'contexts/ResourceManager';
import React, {useCallback} from 'react';

import styles from './AvatarSelector.module.scss';
import {AvatarOption, Props} from './AvatarSelector.types';

export const AvatarSelector: React.FC<Props> = ({
  avatars,
  value,
  onChange,
  className,
}) => {
  const {resUrl} = useResourceManager();

  const renderAvatar = useCallback(
    (avatar: AvatarOption | null) => (
      <div
        key={avatar?.name ?? ''}
        className={classNames(
          styles.avatarWrapper,
          className,
          avatar === null && styles.none,
          value === (avatar === null ? avatar : avatar!.name) && styles.selected
        )}
        onClick={() => onChange(avatar && avatar.name)}
      >
        {avatar === null && <NoneIcon className={styles.icon} />}
        {avatar?.image && (
          <img
            src={`${resUrl}${avatar?.image}`}
            className={`${styles.avatarImage} `}
            draggable={false}
          />
        )}
      </div>
    ),
    [className, onChange, resUrl, value]
  );
  const list = [...[null, ...avatars].map(renderAvatar)];
  return (
    <Slider
      className={className}
      list={list}
      sliderGap={12}
      sliderItemWidth={68}
      scrollToIdx={avatars.findIndex(avatar => avatar?.name === value)}
      rightSpace={30}
    />
  );
};
