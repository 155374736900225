// Copyright 2024 The SeedV Lab (Beijing SeedV Technology Co., Ltd.)
// All Rights Reserved.

import {GoogleOAuthProvider} from '@react-oauth/google';
import {Response, ResponseCode, SUCCESS_STATUS_CODE} from 'api/BackendClient';
import {AxiosResponse} from 'axios';
import classNames from 'classnames';
import {DiscordButton} from 'components/DiscordButton';
import {GoogleButton} from 'components/GoogleButton';
import {TermsAndPolicy} from 'components/TermsAndPolicy';
import {TurnstileBox} from 'components/TurnstileBox';
import {useAnalysis} from 'contexts/AnalysisContext';
import {useAPI} from 'contexts/APIContext';
import {useUserContext} from 'contexts/UserContext';
import {EMAIL_REG} from 'lib/auth';
import {getQueryStringParameters} from 'lib/common';
import {usePasswordValidator} from 'modules/auth/services';
import {EmailErrorType, UseErrorToastReturnType} from 'modules/auth/types';
import {Button} from 'pages/components/Button';
import {GlassInputBox} from 'pages/components/GlassInputBox/GlassInputBox';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Link, useNavigate, useOutletContext} from 'react-router-dom';
import {CONFIRM_EMAIL_PATH, LOGIN_PATH, ROOT_PATH} from 'utils/path';

import styles from './AuthForm.module.scss';
import {EmailErrorMessage} from './EmailErrorMessage';
import {PasswordErrorMessage} from './PasswordErrorMessage';

export function SignupForm() {
  const {t} = useTranslation('auth');
  const {recordEvent} = useAnalysis();
  const navigate = useNavigate();
  const {updateUserLogin} = useUserContext();
  const [googleLoading, setGoogleLoading] = useState(false);
  const {setErrorToastType, showErrorToast, setErrorToastVisible} =
    useOutletContext<UseErrorToastReturnType>();

  const [isCaptchaShowing, setIsCaptchaShowing] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const passwordInputRef = useRef<HTMLInputElement>(null);
  const confirmPasswordInputRef = useRef<HTMLInputElement>(null);
  const focusPasswordInput = () => {
    const passwordInput = passwordInputRef.current;
    if (!passwordInput) return;
    passwordInput.focus();
  };
  const focusConfirmPasswordInput = () => {
    const confirmPasswordInput = confirmPasswordInputRef.current;
    if (!confirmPasswordInput) return;
    confirmPasswordInput.focus();
  };

  const [email, setEmail] = useState(() => {
    const params = getQueryStringParameters(location.href);
    return params.email || '';
  });

  const {
    password,
    confirmPassword,
    passwordErrorType,
    validPassword,
    validConfirmPassword,
    changePassword,
    changeConfirmPassword,
    handleSetPasswordErrorType,
  } = usePasswordValidator();

  const [emailErrorType, setEmailErrorType] = useState<EmailErrorType | null>(
    null
  );

  const {backendClient} = useAPI();

  const loginWithEmail = useCallback(
    (email: string, password: string) =>
      backendClient
        .login({
          email,
          password,
        })
        .then(res => {
          updateUserLogin(
            email,
            res.data.userName,
            res.data.userId,
            res.data.portrait,
            res.data.token,
            res.data.emailConfirmStatus
          );
          navigate(ROOT_PATH);
          return true;
        })
        .catch((err: AxiosResponse<Response>) => {
          const {error} = err.data || {};
          switch (error) {
            case ResponseCode.PASSCODE_NOT_MATCH:
              handleSetPasswordErrorType('PASSCODE_NOT_MATCH');
              break;
            case ResponseCode.USER_NOT_FOUND:
              setEmailErrorType('USER_NOT_FOUND');
              break;
            case ResponseCode.USER_EMAIL_NOT_CONFIRM_YET:
              navigate(CONFIRM_EMAIL_PATH, {state: {email}, replace: true});
              break;
          }
          return err;
        }),
    [backendClient, updateUserLogin, navigate, handleSetPasswordErrorType]
  );

  const signup = useCallback(
    (captchaToken: string) => {
      return backendClient
        .signUp(
          {
            email,
            password,
            confirmPassword: confirmPassword,
          },
          captchaToken as string
        )
        .then(res => {
          // Google signup为发送邮件埋点
          recordEvent('Email-Signup');
          if (res.data && res.data.accountMergeStatus === true) {
            //合并账户有异常
            setErrorToastType('mergeAccountError');
            showErrorToast(true, email, () => {
              navigate(CONFIRM_EMAIL_PATH, {state: {email}, replace: true});
            });
            return;
          }
          navigate(CONFIRM_EMAIL_PATH, {state: {email}, replace: true});
        })
        .catch((err: AxiosResponse<Response>) => {
          const {error} = err.data || {};
          switch (error) {
            case SUCCESS_STATUS_CODE:
              loginWithEmail(email, password);
              break;
            case ResponseCode.EMAIL_HAD_REGISTER_YET:
              setEmailErrorType('EMAIL_HAD_REGISTER_YET');
              break;
          }
        });
    },
    [
      backendClient,
      email,
      password,
      confirmPassword,
      recordEvent,
      navigate,
      setErrorToastType,
      showErrorToast,
      loginWithEmail,
    ]
  );

  const checkEmailExist = useCallback(async () => {
    const canUse = await backendClient.isUserCanSignup(email);
    if (!canUse) {
      setEmailErrorType('EMAIL_HAD_REGISTER_YET');
      return false;
    }
    return true;
  }, [backendClient, email]);

  const validEmail = useCallback(
    async (callbackFun?: () => void) => {
      let isValid = EMAIL_REG.test(email);

      if (isValid && emailErrorType === 'EMAIL_INVALID') {
        setEmailErrorType(null);
      } else if (!isValid) {
        setEmailErrorType('EMAIL_INVALID');
        return false;
      }
      isValid = await checkEmailExist();
      if (isValid && callbackFun) {
        callbackFun();
      }
      return isValid;
    },
    [checkEmailExist, email, emailErrorType]
  );

  const verifyAllInput = useCallback(async () => {
    const passwordPass = validPassword();
    const confirmPasswordPass = passwordPass ? validConfirmPassword() : false;
    if (!passwordPass || !confirmPasswordPass) return false;
    const validEmailRes = await validEmail();
    return validEmailRes && passwordPass && confirmPasswordPass;
  }, [validConfirmPassword, validEmail, validPassword]);

  const onSignup = useCallback(async () => {
    setIsVerifying(true);
    const isPass = await verifyAllInput();
    if (isPass) setIsCaptchaShowing(true);
    setIsVerifying(false);
  }, [verifyAllInput]);

  useEffect(() => {
    setIsCaptchaShowing(false);
    setIsVerifying(false);
  }, [email, password, confirmPassword]);

  const onConfirm = useCallback(
    async (captchaToken?: string) => {
      await signup(captchaToken as string);
    },
    [signup]
  );

  const changeEmail = useCallback(
    (value: string) => {
      setEmailErrorType(null);
      if (passwordErrorType === 'PASSCODE_NOT_MATCH') {
        handleSetPasswordErrorType(null);
      }
      setEmail(value);
    },
    [handleSetPasswordErrorType, passwordErrorType]
  );

  return (
    <>
      <div className={styles.title}>{t('Sign up to Mootion')}</div>
      <div className={styles['content-container']}>
        <div className={styles.formContainer}>
          {process.env.REACT_APP_GOOGLE_CLIENT_ID && (
            <GoogleOAuthProvider
              clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
            >
              <GoogleButton
                googleLoading={googleLoading}
                setGoogleLoading={setGoogleLoading}
                styles={styles}
                setErrorToastType={setErrorToastType}
                showErrorToast={showErrorToast}
                setErrorToastVisible={setErrorToastVisible}
                buttonText={t('Sign up with Google')}
              />
            </GoogleOAuthProvider>
          )}
          <DiscordButton
            disabled={googleLoading}
            text={t('Sign up with Discord')}
            styles={styles}
          />
          <div className={styles.splitLine}>
            <span className={styles.line} />
            <span>{t('or')}</span>
            <span className={styles.line} />
          </div>
        </div>
        <div className={styles.auth_form_container}>
          <GlassInputBox
            disabled={googleLoading}
            className={classNames(styles.email_input, styles.input, 'body-m')}
            placeholder="example@email.com"
            onKeyUp={e => e.key === 'Enter' && focusPasswordInput()}
            value={email}
            onChange={e => changeEmail(e.target.value)}
          />

          <GlassInputBox
            disabled={googleLoading}
            className={classNames(
              styles.password_input,
              styles.input,
              'body-m'
            )}
            placeholder={t('password (at least 8 characters)')}
            ref={passwordInputRef}
            onKeyUp={e => e.key === 'Enter' && focusConfirmPasswordInput()}
            value={password}
            onChange={e => changePassword(e.target.value)}
            type="password"
          />

          <GlassInputBox
            disabled={googleLoading}
            className={classNames(
              styles.password_input,
              styles.input,
              'body-m'
            )}
            placeholder={t('confirm password')}
            ref={confirmPasswordInputRef}
            onKeyUp={e => e.key === 'Enter' && onSignup()}
            value={confirmPassword}
            onChange={e => changeConfirmPassword(e.target.value)}
            type="password"
          />

          {emailErrorType !== null && (
            <EmailErrorMessage
              errorType={emailErrorType}
              className={styles.error_message}
            />
          )}

          {emailErrorType === null && passwordErrorType !== null && (
            <PasswordErrorMessage
              errorType={passwordErrorType}
              className={styles.error_message}
            />
          )}

          <div className={styles.agree}>
            <div>{t("By signing up,  I agree with Mootion's")}&nbsp;</div>
            <TermsAndPolicy linkString="|" />
          </div>

          {isCaptchaShowing && <TurnstileBox onVerify={onConfirm} />}

          {googleLoading && (
            <div className={classNames(styles.signupBox)}>
              <Button
                type="button"
                size="large"
                onClick={() => setGoogleLoading(false)}
              >
                {t('Cancel')}
              </Button>
            </div>
          )}

          {!googleLoading && !isCaptchaShowing && (
            <div className={classNames(styles.signupBox)}>
              <Button
                type="button"
                size="large"
                isLoading={isVerifying}
                icon={<></>}
                onClick={onSignup}
                disabled={
                  emailErrorType !== null ||
                  passwordErrorType !== null ||
                  isVerifying
                }
              >
                {t('Sign up')}
              </Button>
            </div>
          )}
        </div>
        <p className={styles.to_login}>
          <span>{t('Already have an account?')}</span>
          {googleLoading ? (
            <span className={styles.disabled}>{t('Log in')}</span>
          ) : (
            <Link to={LOGIN_PATH} className={styles.link}>
              {t('Log in')}
            </Link>
          )}
        </p>
      </div>
    </>
  );
}
